@mixin _section() {
	display: inline-block;
	width: 100%;
}

@mixin _bs-container() {
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: $gutter-width* 0.5;
	padding-right: $gutter-width* 0.5;

	@include _breakpoint--md {
		max-width: $container-desktop;
	}

	@include _breakpoint--lg {
		max-width: $mq__lg-container;
	}
}

@mixin _bs-container--fluid() {
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: $gutter-width* 0.5;
	padding-right: $gutter-width* 0.5;
}

@mixin _odd-grid() {
	.row {
		margin-bottom: _space();

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.col-xs-12 {
		.composition {
			width: 100%;

			&__shape {
				margin-left: _space();
			}
		}

		&:first-of-type {
			p {
				float: right;
				text-align: right;
			}

			h2 {
				text-align: right;
			}

			.composition__shape {
				float: right;
				margin: {
					right: _space();
					left: 0;
				}
			}
		}
	}
	p {
		width: 400px;
		max-width: 100%;
	}

	h2 {
		margin-top: _double-space();
	}
}

.row--hidden {
	display: none;
}
