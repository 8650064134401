.card {
	display: block;
	position: relative;
	background-color: var(--color-seal-1);
	overflow: hidden;
	@include _shadow();
	transition: box-shadow 0.25s ease-in-out;

	&:hover {
		box-shadow: 0 16px 27px #557c9926;

		.card__title {
			color: var(--color-tang-5);
		}

		.card__image {
			&::after {
				opacity: 0.5;
			}
		}
	}

	.card__content {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		border-radius: 4px;
		padding: 12px 24px;
		background-color: var(--color-seal-1);
	}

	.card__title {
		color: var(--color-shark-1);
		font-size: _font-scale("big");
		line-height: 1.2;
		margin: 0;
		transition: color 0.25s ease-in-out;
	}

	.card__image {
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: 320px;
		flex: 1 0 auto;

		/**
         *  Blog images
         */
		&--blog-01 {
			background-image: image_url("blog-candidate.png", "featured-posts");
		}

		&--blog-02 {
			background-image: image_url(
				"blog-best-practises-of-remote-work.png",
				"featured-posts"
			);
		}

		&--blog-03 {
			background-image: image_url(
				"blog-collaboration.png",
				"featured-posts"
			);
		}

		&--blog-04 {
			background-image: image_url(
				"blog-feedback-culture.png",
				"featured-posts"
			);
		}
		&--blog-05 {
			background-image: image_url(
				"blog-meeting-madness.png",
				"featured-posts"
			);
		}
		&--blog-06 {
			background-image: image_url(
				"blog-do-it-better.jpeg",
				"featured-posts"
			);
		}

		/**
         *  Press images
         */
		&--press {
			background-size: contain;
			background-color: var(--color-seal-1);
			border-right: 2px solid var(--color-seal-2);
		}
		&--press-nofluffjobs {
			background-image: image_url("nofluffjobs.svg", "featured-posts");
			background-size: auto;
		}
		&--press-gptw {
			background-image: image_url("gptw.svg", "featured-posts");
			background-size: auto;
		}

		&--press-inhire {
			background-image: image_url("inhire.png", "featured-posts");
			background-size: 80%;
		}

		&--press-justgeekit {
			background-image: image_url("justgeekit.svg", "featured-posts");
			background-size: 90%;
		}
		&--press-websiteplanet {
			background-image: image_url("websiteplanet.png", "featured-posts");
			background-size: auto;
		}
	}
}

/**
 *  Card horizontal
 */
.card--horizontal {
	display: flex;
	align-items: center;

	.card__image {
		height: 100px;
		width: 100px;
		z-index: 1;
	}

	.card__content {
		position: static;
		display: flex;
		align-items: center;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 5%;
			background-color: var(--color-tang-5);
			transform: translateX(-99%);
			transition: transform 0.25s ease-in-out;
		}
	}

	.card__title {
		isolation: isolate;
		font-size: _font-scale(medium);
	}

	&:hover {
		text-decoration: none;

		.card__content {
			&::before {
				transform: translate(0);
			}
		}
	}
}
