// this file is generated by gulp

	.c-sprite-icon--case-addiction-recovery-mono {
		--c-sprite-icon-width:  calc(7.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-addiction-recovery {
		--c-sprite-icon-width:  calc(7.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-agreemint-mono {
		--c-sprite-icon-width:  calc(6.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-agreemint {
		--c-sprite-icon-width:  calc(6.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-blooksy-mono {
		--c-sprite-icon-width:  calc(12.1875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-blooksy {
		--c-sprite-icon-width:  calc(12.1875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-dtu-mono {
		--c-sprite-icon-width:  calc(2.75 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-dtu {
		--c-sprite-icon-width:  calc(2.75 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-flexum-mono {
		--c-sprite-icon-width:  calc(13.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-flexum {
		--c-sprite-icon-width:  calc(13.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-foraus-mono {
		--c-sprite-icon-width:  calc(13.6875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-foraus {
		--c-sprite-icon-width:  calc(13.6875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-kanbanize-mono {
		--c-sprite-icon-width:  calc(16 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-kanbanize {
		--c-sprite-icon-width:  calc(16 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-leadoo-mono {
		--c-sprite-icon-width:  calc(11.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-leadoo {
		--c-sprite-icon-width:  calc(11.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-mailchain-mono {
		--c-sprite-icon-width:  calc(10.875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-mailchain {
		--c-sprite-icon-width:  calc(10.875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-neos-mono {
		--c-sprite-icon-width:  calc(3.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-neos {
		--c-sprite-icon-width:  calc(3.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-placement-mono {
		--c-sprite-icon-width:  calc(14.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-placement {
		--c-sprite-icon-width:  calc(14.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-plutio-mono {
		--c-sprite-icon-width:  calc(9.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-plutio {
		--c-sprite-icon-width:  calc(9.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-rocketlane-mono {
		--c-sprite-icon-width:  calc(14.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-rocketlane {
		--c-sprite-icon-width:  calc(14.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-spotlight-mono {
		--c-sprite-icon-width:  calc(11.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-spotlight {
		--c-sprite-icon-width:  calc(11.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-steeplechasers-mono {
		--c-sprite-icon-width:  calc(3 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-steeplechasers {
		--c-sprite-icon-width:  calc(3 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-sulu-mono {
		--c-sprite-icon-width:  calc(16.5625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-sulu {
		--c-sprite-icon-width:  calc(16.5625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-tablo-mono {
		--c-sprite-icon-width:  calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-tablo {
		--c-sprite-icon-width:  calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-testmo-mono {
		--c-sprite-icon-width:  calc(12.375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--case-testmo {
		--c-sprite-icon-width:  calc(12.375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}
