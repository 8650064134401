.press {
	background-color: var(--color-seal-1);
	position: relative;
	margin-top: _space();
	padding-top: _space();
	overflow: hidden;

	@include _breakpoint--sm {
		margin-top: _double-space();
		padding-top: _double-space();
	}

	.blog-item {
		margin: 0;
		width: 100%;

		&:not(:last-of-type) {
			margin-bottom: 2 * $blog-margin-x;
		}

		@include _breakpoint--sm {
			padding-left: $blog-margin-x;
			padding-right: $blog-margin-x;
			width: 50%;
		}

		@include _breakpoint--md {
			margin-bottom: initial;
		}
	}
}
