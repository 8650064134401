$form-grid__gutter: 10px;

.contact {
	&-destination {
		.section-container {
			@include _breakpoint--sm-only {
				width: $tablet-middle-container-width--wide;
				max-width: 90%;
				margin: 0 auto;
			}
		}

		.section-header {
			padding: {
				left: $form-grid__gutter * 0.5;
				right: $form-grid__gutter * 0.5;
			}
			margin-bottom: $form-grid__gutter;
		}

		.dropdown--huge {
			margin-top: _space();
			@include _shadow;

			@include _breakpoint--sm {
				margin-top: _double-space();
			}
		}
	}
}

.sent-success {
	display: none;
	margin-top: _space();
	padding: _space();
	opacity: 0;
	background-color: var(--color-seal-1);

	&--active {
		display: block;
		opacity: 1;
		@include fadeInDown($duration: 600ms, $function: ease, $fill: both);
	}

	.section-container {
		@include _breakpoint--sm-only {
			width: $tablet-middle-container-width--wide;
			max-width: 90%;
			margin: 0 auto;
		}
	}
}

.form-wrapper {
	max-width: 700px;
	margin-inline: auto;
}

.big-form {
	margin-top: 0;

	label {
		margin: 0;
	}

	.form--hide {
		display: none;
		@include fadeOutDown(
			$duration: 300ms,
			$function: ease-in-out,
			$fill: both
		);
	}

	.section-container {
		@include _breakpoint--sm-only {
			width: $tablet-middle-container-width--wide;
			max-width: 90%;
			margin: 0 auto;
		}
	}

	h2 {
		padding-bottom: _space();
		text-align: center;

		&:after {
			display: none;
		}
	}

	.col-xs-12 {
		padding: {
			left: $form-grid__gutter * 0.5;
			right: $form-grid__gutter * 0.5;
		}
	}

	&__required-fields {
		position: absolute;
		font-size: _font-scale(medium);
		padding-left: _space() * 0.5;

		&--red {
			color: var(--color-tang-5);
		}
	}

	&__superscript {
		position: absolute;
		top: -3px;
		right: 0;
		font-size: 11px;
		color: #b08f8f;
	}

	.big-form__group {
		width: 100%;
		padding: _space() * 0.5;
		background-color: var(--color-seal-1);

		@include _breakpoint--sm {
			padding: _space() * 0.25;
			margin-bottom: $form-grid__gutter;
			@include _shadow;
		}

		&--flex {
			height: auto;
		}

		label {
			padding-left: _space() * 0.5;
			font-size: _font-scale(medium);

			@include _breakpoint--mo {
				padding: 0;
			}
		}

		label.cv-label {
			position: relative;
			padding-right: 36px;
		}

		input[type="text"],
		input[type="file"],
		input[type="tel"],
		input[type="email"],
		textarea {
			width: 100%;
			border: 0;
			padding: _space() * 0.25 _space() * 0.5;
			background-color: color-mix(
				in srgb,
				var(--color-tang-5) 5%,
				var(--color-mix-base, transparent)
			);
			font-size: $big-form__font-size;

			&:focus {
				outline: 1px solid
					color-mix(
						in srgb,
						var(--color-tang-5) 0.5,
						var(--color-mix-base, transparent)
					);
			}

			@include _breakpoint--mo {
				padding: 1rem;
				font-size: $base-font-size--rem;
				border-bottom: 2px solid var(--color-tang-5);
			}
		}

		input[type="file"] {
			font-size: _font-scale(medium);

			@include _breakpoint--mo {
				padding: 10px;
				border: 2px solid var(--color-tang-5);
				margin-top: 4px;
			}
		}

		textarea {
			height: 150px;
			resize: vertical;

			@include _breakpoint--mo {
				height: 130px;
				border: 2px solid var(--color-tang-5);
			}
		}

		&--disabled {
			background-color: var(--color-shark-5);
			cursor: not-allowed;

			input[type="text"],
			input[type="file"],
			input[type="tel"],
			input[type="email"],
			textarea {
				background-color: transparent;
				cursor: not-allowed;
				border-color: color-mix(
					in srgb,
					var(--color-tang-5) 0.3,
					var(--color-mix-base, transparent)
				);
			}
		}

		.btn {
			display: block;
			margin: _space() auto;

			@include _breakpoint--mo {
				width: 100%;
				margin-top: _double-space();
			}
		}
	}
}

.swal2-title {
	&:after {
		display: none;
	}
}
