$banner-background-color: #ffffff;
$banner-accent-color: var(--color-tang-5);

.banner {
	position: fixed;
	top: 20%;
	left: 50%;
	z-index: 10;
	transform: translateX(-50%);
	min-width: 320px;
	opacity: 0;
	visibility: hidden;

	.banner__content {
		overflow: hidden;
		position: relative;
		background-color: $banner-background-color;
		padding: 56px 32px;

		@include _breakpoint--sm {
			min-width: 540px;
		}

		&::before,
		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			background-color: $banner-accent-color;
			opacity: 0.6;
			width: 24px;
			height: 24px;

			@include _breakpoint--sm {
				width: 40px;
				height: 40px;
			}
		}

		&::before {
			left: 24px;

			@include _breakpoint--sm {
				left: 40px;
			}
		}

		&::after {
			bottom: 24px;

			@include _breakpoint--sm {
				bottom: 40px;
			}
		}
	}

	.banner__header {
		text-align: center;
		font-size: _font-scale(xlarge);
		line-height: 1;
		font-weight: bold;
		margin-bottom: 12px;

		&::after {
			display: none;
		}
	}

	.banner__text {
		text-align: center;
		margin: 0;
	}

	.banner__close-btn {
		border: none;
		position: absolute;
		top: 16px;
		right: 16px;
		width: 40px;
		height: 40px;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: $banner-accent-color;
			width: 100%;
			height: 6px;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}

.banner--active {
	opacity: 1;
	visibility: visible;
	@include _zindex(on-cover);
}

.banner-thankyou {
	background-color: $banner-background-color;
	padding: 10px;
	@include _shadow();

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		background-color: $banner-accent-color;
		opacity: 0.6;
	}
}

.banner-quiz {
	border: 4px solid #0086ccd4;
	width: calc(100% - 10px);
	transform: translate(-50%, -10%);
	opacity: 0;
	transition: 0.25s ease-in-out transform, 0.25s ease-in-out opacity;
	min-width: auto;
	border-radius: 4px;
	top: 5px;
	visibility: visible;
	z-index: -1;

	.banner__content {
		padding: 12px;
		background-color: #e7f1fa;

		&::before,
		&::after {
			display: none;
		}
	}

	.banner__header {
		font-size: 2.4rem;
		margin-bottom: initial;
	}

	&.banner--active {
		z-index: 1;
		opacity: 1;
		transform: translate(-50%, 0);
		transition: 0.25s ease-in-out transform, 0.25s ease-in-out opacity;
	}
}
