.section-employees {
	margin-top: 24px;
	margin-bottom: 24px;

	@include _breakpoint--sm {
		margin-top: 42px;
		margin-bottom: 42px;
	}
}

.employees-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 32px;
	overflow: hidden;
	padding-bottom: 56px;
}

.employee-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 650px;
	margin-inline: auto;

	.employee-name {
		margin: 0;
		font-size: 28px;
		line-height: 1;
		font-weight: 500;
	}

	.employee-content {
		box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
		position: relative;
		padding: 42px 24px 24px;

		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 24px;
			right: 24px;
			width: 135px;
			height: 109px;
			background-image: image_url("quote.svg", "decorators");
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.employee-position {
		font-weight: 300;
		font-size: 16px;
		line-height: 1.5;
	}

	.employee-meta {
		margin-top: auto;
		text-align: right;
	}

	.employee-text {
		margin: 0;
		margin-bottom: 40px;
		font-size: 18px;
		line-height: 1.5;
	}

	.employee-image {
		max-width: 180px;
		filter: drop-shadow(0px 16px 40px rgba(112, 144, 176, 0.2));
		margin-bottom: -32px;
	}
}

// Slider options
.employees-wrapper {
	&::before,
	&::after {
		@include _breakpoint--sm {
			content: "";
			z-index: 2;
			position: absolute;
			height: 100%;
			width: 8vw;
		}
	}

	&::before {
		@include _breakpoint--sm {
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(0, 212, 255, 0) 100%
			);
		}
	}

	&::after {
		@include _breakpoint--sm {
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			right: 0;
		}
	}

	.swiper-slide {
		padding: 24px 16px 48px;
		@include _breakpoint--sm {
			padding: 48px 16px 48px;
		}
	}
}

button.module-employees__prev,
button.module-employees__next {
	position: absolute;
	top: calc(100% - 50px);
	cursor: pointer;
	border: none;
	opacity: 0.3;
	@include _zindex(air);
	width: 24px;
	height: 36px;
	padding: var(--space-s);
	box-sizing: content-box;
	left: 50%;

	&:focus,
	&:hover {
		outline: none;
		opacity: 1;
	}

	&:after {
		clip-path: polygon(
			50% 10%,
			70% 10%,
			40% 50%,
			70% 90%,
			50% 90%,
			20% 50%
		);
		position: relative;
		display: block;
		background-color: var(--color-tang-6);
		background-image: none;
		width: 100%;
		height: 100%;
		content: "";
	}
}

button.swiper-button-disabled {
	opacity: 0.1;
	&:focus,
	&:hover {
		cursor: auto;
		opacity: 0.1;
	}
}

.module-employees__prev {
	transform: translateX(calc(-50% - 24px));
}

.module-employees__next {
	transform: translateX(calc(-50% + 24px)) rotate(180deg);
}
