%svg-common {
    background: url("../images/vector.svg") no-repeat;
}

.svg-ico__api {
    @extend %svg-common;
    background-position: 71.54696132596685% 26.747720364741642%;
    width: 84px;
    height: 84px;
}

.svg-ico__arrow-left {
    @extend %svg-common;
    background-position: 33.333333333333336% 73.87640449438203%;
    width: 38px;
    height: 57px;
}

.svg-ico__arrow-left-blue {
    @extend %svg-common;
    background-position: 42.64705882352941% 73.87640449438203%;
    width: 38px;
    height: 57px;
}

.svg-ico__arrow-right {
    @extend %svg-common;
    background-position: 0 100%;
    width: 34px;
    height: 44px;
}

.svg-ico__arrow-right-36 {
    @extend %svg-common;
    background-position: 61.27450980392157% 73.66946778711484%;
    width: 38px;
    height: 56px;
}

.svg-ico__arrow-right-blue {
    @extend %svg-common;
    background-position: 51.96078431372549% 73.87640449438203%;
    width: 38px;
    height: 57px;
}

.svg-ico__arrow-right-mo {
    @extend %svg-common;
    background-position: 17.83132530120482% 98.66310160427807%;
    width: 31px;
    height: 39px;
}

.svg-ico__check {
    @extend %svg-common;
    background-position: 70.44334975369458% 87.33509234828496%;
    width: 40px;
    height: 34px;
}

.svg-ico__close-white {
    @extend %svg-common;
    background-position: 25.735294117647058% 98.4%;
    width: 38px;
    height: 38px;
}

.svg-ico__dot {
    @extend %svg-common;
    background-position: 8.374384236453203% 98.92761394101876%;
    width: 40px;
    height: 40px;
}

.svg-ico__examples {
    @extend %svg-common;
    background-position: 0 54.40729483282675%;
    width: 84px;
    height: 84px;
}

.svg-ico__facebook-circle {
    @extend %svg-common;
    background-position: 87.7237851662404% 15.363128491620111%;
    width: 55px;
    height: 55px;
}

.svg-ico__faq {
    @extend %svg-common;
    background-position: 23.204419889502763% 54.40729483282675%;
    width: 84px;
    height: 84px;
}

.svg-ico__fb {
    @extend %svg-common;
    background-position: 100% 0;
    width: 47px;
    height: 45px;
}

.svg-ico__fb-black {
    @extend %svg-common;
    background-position: 100% 12.228260869565217%;
    width: 47px;
    height: 45px;
}

.svg-ico__fb-float {
    @extend %svg-common;
    background-position: 0 76.23188405797102%;
    width: 68px;
    height: 68px;
}

.svg-ico__fb-float-white {
    @extend %svg-common;
    background-position: 17.98941798941799% 76.23188405797102%;
    width: 68px;
    height: 68px;
}

.svg-ico__flat-github {
    @extend %svg-common;
    background-position: 34.8780487804878% 97.87798408488064%;
    width: 36px;
    height: 36px;
}

.svg-ico__flat-linkedin {
    @extend %svg-common;
    background-position: 43.65853658536585% 97.87798408488064%;
    width: 36px;
    height: 36px;
}

.svg-ico__flat-twitter {
    @extend %svg-common;
    background-position: 52.4390243902439% 97.87798408488064%;
    width: 36px;
    height: 36px;
}

.svg-ico__g-plus {
    @extend %svg-common;
    background-position: 0 88.26666666666667%;
    width: 49px;
    height: 38px;
}

.svg-ico__g-plus-black {
    @extend %svg-common;
    background-position: 12.34256926952141% 88.26666666666667%;
    width: 49px;
    height: 38px;
}

.svg-ico__g-plus-float {
    @extend %svg-common;
    background-position: 24.68513853904282% 88.26666666666667%;
    width: 49px;
    height: 38px;
}

.svg-ico__g-plus-float-white {
    @extend %svg-common;
    background-position: 37.02770780856423% 88.26666666666667%;
    width: 49px;
    height: 38px;
}

.svg-ico__github {
    @extend %svg-common;
    background-position: 25.138121546961326% 0;
    width: 84px;
    height: 90px;
}

.svg-ico__github-circle {
    @extend %svg-common;
    background-position: 87.94871794871794% 0;
    width: 56px;
    height: 55px;
}

.svg-ico__gitter {
    @extend %svg-common;
    background-position: 46.408839779005525% 54.40729483282675%;
    width: 84px;
    height: 84px;
}

.svg-ico__google-circle {
    @extend %svg-common;
    background-position: 87.7237851662404% 30.726256983240223%;
    width: 55px;
    height: 55px;
}

.svg-ico__guides {
    @extend %svg-common;
    background-position: 0 28%;
    width: 84px;
    height: 88px;
}

.svg-ico__guildes {
    @extend %svg-common;
    background-position: 23.204419889502763% 28%;
    width: 84px;
    height: 88px;
}

.svg-ico__hamburger {
    @extend %svg-common;
    background-position: 79.90196078431373% 86.8766404199475%;
    width: 38px;
    height: 32px;
}

.svg-ico__ideas {
    @extend %svg-common;
    background-position: 0 0;
    width: 91px;
    height: 91px;
}

.svg-ico__instagram {
    @extend %svg-common;
    background-position: 100% 24.52316076294278%;
    width: 47px;
    height: 46px;
}

.svg-ico__instagram-black {
    @extend %svg-common;
    background-position: 100% 37.05722070844686%;
    width: 47px;
    height: 46px;
}

.svg-ico__instagram-circle {
    @extend %svg-common;
    background-position: 87.27735368956743% 46.089385474860336%;
    width: 53px;
    height: 55px;
}

.svg-ico__issue-tracker {
    @extend %svg-common;
    background-position: 46.408839779005525% 28%;
    width: 84px;
    height: 88px;
}

.svg-ico__linkedin-circle {
    @extend %svg-common;
    background-position: 87.7237851662404% 61.452513966480446%;
    width: 55px;
    height: 55px;
}

.svg-ico__localization {
    @extend %svg-common;
    background-position: 48.34254143646409% 0;
    width: 84px;
    height: 90px;
}

.svg-ico__menu {
    @extend %svg-common;
    background-position: 48.87780548628429% 88.03191489361703%;
    width: 45px;
    height: 37px;
}

.svg-ico__menu-dark {
    @extend %svg-common;
    background-position: 60.099750623441395% 88.03191489361703%;
    width: 45px;
    height: 37px;
}

.svg-ico__stackoverflow {
    @extend %svg-common;
    background-position: 71.54696132596685% 0;
    width: 84px;
    height: 88px;
}

.svg-ico__tutorials {
    @extend %svg-common;
    background-position: 69.61325966850829% 54.40729483282675%;
    width: 84px;
    height: 84px;
}

.svg-ico__twitter {
    @extend %svg-common;
    background-position: 100% 49.056603773584904%;
    width: 47px;
    height: 42px;
}

.svg-ico__twitter-black {
    @extend %svg-common;
    background-position: 100% 60.37735849056604%;
    width: 47px;
    height: 42px;
}

.svg-ico__twitter-circle {
    @extend %svg-common;
    background-position: 87.7237851662404% 76.81564245810056%;
    width: 55px;
    height: 55px;
}

.svg-ico__twitter-float {
    @extend %svg-common;
    background-position: 100% 71.69811320754717%;
    width: 47px;
    height: 42px;
}

.svg-ico__twitter-float-white {
    @extend %svg-common;
    background-position: 100% 83.01886792452831%;
    width: 47px;
    height: 42px;
}

.svg-ico__vertical-arrows {
    @extend %svg-common;
    background-position: 71.82044887780549% 73.46368715083798%;
    width: 45px;
    height: 55px;
}

