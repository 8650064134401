.dropdown {
	display: inline-block;
	position: relative;

	&__list {
		display: none !important;
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 100%;
		background-color: var(--color-seal-1);
		@include _shadow;

		@include _breakpoint--mo {
			max-width: 100%;
			width: 100%;
		}

		li {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			a {
				color: var(--color-tang-5);
				padding: 1.5rem;
			}
		}
	}

	&--dark {
		.dropdown__list {
			background-color: color-mix(
				in srgb,
				var(--color-shark-1) 90%,
				var(--color-mix-base, transparent)
			);

			li a {
				color: var(--color-seal-2);
			}
		}
	}

	&[data-open="true"] {
		ul {
			display: block !important;
			@include fadeInDown(
				$duration: 300ms,
				$function: ease-in-out,
				$fill: both
			);
		}
	}

	button[data-toggle="dropdown"] {
		position: relative;
		padding-right: 3rem;
		border-radius: 0;

		&:after {
			content: "\25BC";
			position: absolute;
			right: _space() * 0.5;
			top: 55%;
			font-size: 1.1rem;
			transform: translateY(-50%);
		}
	}

	&--hide {
		display: none;
		@include fadeOutUp(
			$duration: 300ms,
			$function: ease-in-out,
			$fill: both
		);
	}

	&--huge {
		width: 100%;

		button[data-toggle="dropdown"] {
			width: 100%;
			padding: _space();
			background-color: var(--color-seal-1);
			color: var(--color-shark-1);
			font-size: $big-form__font-size;
			text-align: left;

			@include _breakpoint--sm {
				padding: _space();
				font-size: $big-form__font-size;
			}

			&:after {
				content: "";
				right: _space();
				top: 50%;
				// @extend .svg-ico__vertical-arrows;

				@include _breakpoint--mo {
					right: 0.5rem;
					transform: translateY(-50%) scale(0.7);
				}
				background-color: var(--color-tang-5);
				background-image: none;
				clip-path: polygon(
					0% 44%,
					50% 12%,
					100% 44%,
					100% 33%,
					50% 0%,
					0% 32%,
					0% 59%,
					50% 88%,
					100% 56%,
					100% 68%,
					50% 100%,
					0% 71%
				);
				--size: 28px;
				width: var(--size);
				height: calc(var(--size) * 1.2);
			}
		}

		&[data-open="true"] {
			button {
				&:focus,
				&:active {
					outline: none;
				}
			}
		}

		.dropdown__list {
			@include _list-reset;
			@include _zindex(air);

			li {
				&:first-of-type {
					@include _border(top);
				}

				a {
					display: block;
					padding: _space();

					@include _breakpoint--sm {
						font-size: _font-scale(big);
						padding: _space();
					}

					&:hover {
						color: var(--color-tang-5);
						text-decoration: none;
					}
				}
			}
		}
	}
}
