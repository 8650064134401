$mq__xsm: 360px;
$mq__sm: 768px;
$mq__sm-plus: 1024px;
$mq__md: 1280px;
$mq__lg: 1680px;
$mq__lg-container: 1440px;
$mq__hd: 1920px;

/* mobile first breakpoint create helper */
@mixin breakpoint( $mq01: 0, $mq2: false, $maxmin: min-width ) {
	@if ( $mq2 == false ) {
		@media ( $maxmin: $mq01 ) {
			@content;
		}
	}
	@else {
		@media ( min-width: $mq01 ) and ( max-width: $mq2 ) {
			@content;
		}
	}
}

/* Sizes are defined in ../settings/variables */
@mixin _breakpoint--mo-small {
	@include breakpoint( 0, 359px ) {
		@content;
	}
}

@mixin _breakpoint--mo {
	@include breakpoint( 0, $mq__sm - 1 ) {
		@content;
	}
}

@mixin _breakpoint--xsm {
	@include breakpoint( $mq__xsm ) {
		@content;
	}
}

@mixin _breakpoint--sm {
	@include breakpoint( $mq__sm ) {
		@content;
	}
}

@mixin _breakpoint--sm-only {
	@include breakpoint( $mq__sm, $mq__md - 1 ) {
		@content;
	}
}

@mixin _breakpoint--sm-plus {
	@include breakpoint( $mq__sm-plus ) {
		@content;
	}
}

@mixin _breakpoint--sm-plus-only {
	@include breakpoint( $mq__sm-plus, $mq__md - 1 ) {
		@content;
	}
}

@mixin _breakpoint--tablet {
	@include breakpoint( $mq__sm, $mq__sm-plus ) {
		@content;
	}
}

@mixin _breakpoint--tablet-big() {
	@include breakpoint( $mq__sm , $mq__sm-plus) {
		@content;
	}
}

@mixin _breakpoint--desktop-small() {
	@include breakpoint( $mq__sm-plus + 1px , $mq__lg-container - 1px ) {
		@content;
	}
}

@mixin _breakpoint--md {
	@include breakpoint( $mq__md ) {
		@content;
	}
}

@mixin _breakpoint--md-small {
	@include breakpoint( $mq__md, $mq__lg-container - 1 ) {
		@content;
	}
}

@mixin _breakpoint--md-only {
	@include breakpoint( $mq__md, $mq__lg - 1 ) {
		@content;
	}
}

@mixin _breakpoint--lg {
	@include breakpoint( $mq__lg ) {
		@content;
	}
}

@mixin _breakpoint--hd {
	@include breakpoint( $mq__hd + 1px ) {
		@content;
	}
}

@mixin _hdpi {
	@media only screen and ( min-device-pixel-ratio: 2 ),
		only screen and ( min-resolution: 192dpi ),
		only screen and ( min-resolution: 2dppx ) {

		@content;
	}
}
