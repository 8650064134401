$section__describe-width: 400px;

.row--flex {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	> div {
		display: inline-flex;
	}
}

.section {
	&-describe {
		@include _breakpoint--sm {
			@include _center(v);
			max-width: 100%;

			&--left {
				right: 0;
				text-align: right;

				p,
				.btn {
					float: right;
					clear: both;
				}
			}

			&--right {
				left: 0;
			}

			&__wrapper {
				position: relative;

				p {
					width: $section__describe-width;
					max-width: 100%;
				}
			}
		}
	}

	&-wrapper {
		@include _breakpoint--sm {
			display: flex;
		}

		.thumb-wrapper {
			overflow: hidden;

			&--left {
				@include _breakpoint--sm {
					.composition {
						float: right;
					}
				}
			}

			.composition {
				margin: _space() 0;
			}
		}
	}
}

.site-content {
	background-color: var(--color-seal-2);
	overflow: hidden;

	&--home {
		@include _breakpoint--sm-plus {
			background: {
				// image: image_url("home-main-shadow.svg", "bg");
				repeat: no-repeat;
				position: -196px 0;
				size: 592px auto;
			}
		}

		@include _breakpoint--md {
			background-position: 0 0;
		}
	}
	&--no-hero {
		padding-top: 90px;
	}

	&--blue-bg {
		background: {
			image: url(../images/bg-home.png);
			repeat: no-repeat;
			position: center;
			size: 100% auto;
		}

		&.site-content--ckeditor-5-download {
			background: none;
		}
	}

	&--about-us {
		background-color: var(--color-seal-1);
	}

	&--about-us,
	&--careers {
		overflow: visible;
	}

	&--konkurs,
	&--konkurs-meetjs,
	&--konkurs-devjs {
		background-color: var(--color-seal-1);
		border-top: 60px solid var(--color-shark-2);

		@include _breakpoint--sm {
			border-top-width: 120px;
		}

		& > section {
			margin-top: initial;
			margin-bottom: _space() * 3;
		}

		img {
			margin-left: 32px;
		}
	}

	.odd-grid {
		@include _odd-grid;
	}
}

.composition {
	display: inline-block;
	position: relative;
	margin: _space() 0;
	overflow: hidden;

	&__image {
		position: absolute;
		background-size: cover;
	}

	&__shape {
		max-width: 100%;
		width: 90%;
		margin: 0 auto;

		@include _breakpoint--sm {
			max-width: none;
		}
	}
}

.introduce {
	display: inline-block;
	width: 100%;
	max-width: 100%;
	text-align: center;
	padding: 2.5rem 0 0;

	p {
		width: 770px;
		margin-left: auto;
		margin-right: auto;
	}

	&--thin {
		p {
			width: 400px;
		}
	}
}

.hidden {
	display: none !important;
}
