.l-group {
	display: flex;
	flex-wrap: wrap;
	gap: var(--l-group-gap, var(--space-xs));
}

.l-group--xl {
	--l-group-gap: var(--space-xl);
}

.l-group--m {
	--l-group-gap: var(--space-m);
}

.l-group--2xs {
	--l-group-gap: var(--space-2xs);
}

.l-group--4xs {
	--l-group-gap: var(--space-4xs);
}

.l-group--center {
	align-items: center;
}

.l-group-space-between {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: var(--l-group-gap, var(--space-xs));
}
