// this file is generated by gulp

	.c-sprite-icon--lib-a11y {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-accept {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-ai {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-api {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-arrow-up {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-article {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-blog {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-book {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-brackets {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-builder {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-calendar {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-case {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-checklist {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-ckbox {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-clock {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-cloud {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-collaboration {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-comment {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-company {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-cookie {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-customize {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-demo {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-dice {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-doc-check {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-doc-outline {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-doc-toc {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-doc-word {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-doc {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-docs {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-download {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-drupal {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-easy-integration {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-editor {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-experience {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-export {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-flow {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-folder {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-font {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-get {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-global {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-glove {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-grammar {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-hands {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-headphones {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-healthcare {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-html {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-image {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-import-word {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-industry {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-link {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-list {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-maintenance {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-management {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-markdown {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-math {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-multilevel {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-office {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-pagination {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-painter {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-paragraph {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-pin {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-pricing {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-private {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-productivity-pack {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-productivity {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-proof {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-puzzle {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-quality {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-quote {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-result {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-revision-auto {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-revision-history {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-revision {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-rocket {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-search {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-server {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-shield {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-slash {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-solution {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-spell {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-success {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-support {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-table {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-talk {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-templates {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-theme {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-ticket {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-tour {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-typescript {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--lib-word {
		--c-sprite-icon-width:  calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-arrow-curve-point-left {
		--c-sprite-icon-width:  calc(2.6875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2.0625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-arrow-down-fill {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-arrow-down-outline {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-arrow-point-left {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-arrow-point-right {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-bulb {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-bullet {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-check-fill {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-check-outline {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-check {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-chevrons-left {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-chevrons-right {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-controllers {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-error {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-home {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-info {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-magnify {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-minus {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-n-a {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-new-tab {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-plus {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-quote-slim {
		--c-sprite-icon-width:  calc(1.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-star {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-video-player {
		--c-sprite-icon-width:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--ui-x {
		--c-sprite-icon-width:  calc(1 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--deco-large-hex {
		--c-sprite-icon-width:  calc(58.375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(67.375 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--deco-multi-hex {
		--c-sprite-icon-width:  calc(102 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(42.1875 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-chat {
		--c-sprite-icon-width:  calc(4.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4.5 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-envelope {
		--c-sprite-icon-width:  calc(16.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(16.4375 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-logo-symbol {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-logo {
		--c-sprite-icon-width:  calc(8.1875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-soc {
		--c-sprite-icon-width:  calc(5.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(5.625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--misc-youtube {
		--c-sprite-icon-width:  calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2.1875 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--social-li {
		--c-sprite-icon-width:  calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--social-mail {
		--c-sprite-icon-width:  calc(4 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--social-x {
		--c-sprite-icon-width:  calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-align-left {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-certificate {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-code {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-count {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-embed {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-error {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-image {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-import-word {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-link {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-list {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-lock {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-page-break {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-page {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-phones {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-resize {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-save {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-share {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-shortcut {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-table {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-todo {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-transform {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-underline {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-uno {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-word {
		--c-sprite-icon-width:  calc(2 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--cke-zip {
		--c-sprite-icon-width:  calc(2.25 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(2.25 * var(--c-sprite-icon-unit, 1rem));
	}
