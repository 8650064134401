.our-products {
	padding-top: _double-space();
}

.section-life {
	margin-top: 42px;
}

.section-products {
	margin-top: 24px;

	@include _breakpoint--sm {
		margin-top: 48px;
	}
}

.site-content--about-us {
	.header {
		picture {
			img {
				@include _breakpoint--md {
					width: 100%;
				}
			}
		}
	}

	.cta {
		.module-trustedby__wrapper {
			margin-top: 0;
		}
	}

	.cta__yellow-part {
		h2 {
			color: var(--color-seal-1);
		}

		&::after {
			background-color: var(--color-tang-6);
		}
		h2 {
			color: var(--color-seal-1);

			&::after {
				background-color: var(--color-seal-1);
			}
		}
	}
	.cta__yellow-part-inner {
		background-color: var(--color-tang-6);
	}

	.cta__yellow-part .btn {
		color: var(--color-shark-1);
		background-color: var(--color-comet-5);
	}

	.cta__orange-part {
		&::before {
			background-color: var(--color-tang-5);
		}
	}

	.cta__orange-part-inner {
		background-color: var(--color-tang-6);
	}

	.cta__orange-part .btn {
		color: var(--color-shark-1);
		background-color: var(--color-comet-5);

		&:hover {
			color: var(--color-tang-5);
			background-color: var(--color-seal-1);
		}
	}
}
