@use "../tools/media";

// font weight
.u-fw-regular {
	font-weight: 400;
}

.u-fw-s-bold {
	font-weight: 600;
}

.u-fw-bold {
	font-weight: 700;
}

.u-fw-e-bold {
	font-weight: 800;
}

.u-u {
	text-decoration: underline;
}

// font size
.u-fs-1 {
	font-size: var(--step-4);
}

.u-fs-2 {
	font-size: var(--step-3);
}

.u-fs-3 {
	font-size: var(--step-2);
}

.u-fs-4 {
	font-size: var(--step-1);
}

.u-fs-1,
.u-fs-2,
.u-fs-3 {
	line-height: var(--headline-line-height);
}

.u-fs-0-5 {
	font-size: var(--step-0-5);
}

.u-fs-0 {
	font-size: var(--step-0);
}

.u-fs--0-5 {
	font-size: var(--step--0-5);
}

.u-fs--1 {
	font-size: var(--step--1);
}

// text align
.u-ta-center {
	text-align: center;
}

.u-ta-right {
	text-align: right;
}

.u-tw-balance {
	text-wrap: balance;
}

// color
.u-color-tang-5 {
	color: var(--color-tang-5);
}

.u-color-comet-5 {
	color: var(--color-comet-5);
}

.u-color-shark-1 {
	color: var(--color-shark-1);
}

.u-color-shark-2 {
	color: var(--color-shark-2);
}

.u-color-shark-3 {
	color: var(--color-shark-3);
}

.u-color-seal-1 {
	color: var(--color-seal-1);
}

.u-color-tropical-4 {
	color: var(--color-tropical-4);
}

// background-color
.u-bg-seal-1 {
	background-color: var(--color-seal-1);
}

.u-bg-seal-2 {
	background-color: var(--color-seal-2);
}

.u-bg-comet-5 {
	background-color: var(--color-comet-5);
}

@for $i from 1 through 6 {
	.u-bg-reef-#{$i} {
		background-color: var(--color-reef-#{$i});
	}
}

// content flow
.u-flow-xl > * + * {
	margin-top: var(--space-xl);
}

.u-flow-l > * + * {
	margin-top: var(--space-l);
}

.u-flow-m > * + * {
	margin-top: var(--space-m);
}

.u-flow-s > * + * {
	margin-top: var(--space-s);
}

.u-flow-xs > * + * {
	margin-top: var(--space-xs);
}

.u-flow > * + * {
	margin-top: var(--space-3xs);
}

.u-flow-4xs > * + * {
	margin-top: var(--space-4xs);
}

// flow content layout justification
.u-justify-center {
	display: flex;
	justify-content: center;
}

// sr only
.u-sr-only {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute;
	clip-path: inset(50%);
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

// button reset
.u-button-reset,
.u-button-reset-inline {
	appearance: none;
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 0;
	color: currentColor;
	text-align: left;
}

.u-button-reset {
	width: 100%;
}

// visibility hidden
.u-hidden {
	visibility: hidden;
}

// removing element
.u-gone {
	display: none;
}

// center everything
.u-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

// full height
.u-full-height {
	height: 100%;
}

// full width
.u-full-width {
	width: 100%;
}

// full width
.u-full-max-width {
	max-width: 100%;
}

// sticky
.u-sticky {
	position: sticky;
	top: var(--scroll-top);
}

// line breaks
[class*="u-br-"] br {
	display: none;
}

@include media.small {
	.u-br-sm br {
		display: block;
	}
}

@include media.medium {
	.u-br-md br {
		display: block;
	}
}

@include media.large {
	.u-br-lg br {
		display: block;
	}
}

// assign it to .png
.u-filter-shadow {
	filter: drop-shadow(var(--dropshadow-1));
}

// Skip link
.u-skip-link {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	top: 1.3em;
	left: 0.75rem;

	&:focus-visible {
		z-index: var(--z-index-skip-link);
		width: auto;
		height: auto;
		clip: auto;
	}
}
