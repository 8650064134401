.c-sprite-icon--em {
	--c-sprite-icon-unit: 1em;
}

.c-sprite-icon {
	filter: blur(var(--c-sprite-icon-blur, 0));
	width: var(--c-sprite-icon-width, var(--space-l));
	max-width: none;
	height: var(--c-sprite-icon-height, var(--space-l));
}

[class*='c-sprite-icon--ratio-'] {
	width: 100%;
	height: auto;
}

.c-sprite-icon--ratio-4\/3 {
	aspect-ratio: 4/3;
}

.c-sprite-icon--ratio-16\/9 {
	aspect-ratio: 4/3;
}

// https://tympanus.net/codrops/2015/07/16/styling-svg-use-content-css/
// https://fvsch.com/svg-gradient-fill
.c-sprite-icon__use {
	--c-sprite-primary-4: var(--color-tang-4);
	--c-sprite-primary-5: var(--c-sprite-icon-color, var(--color-tang-5));
	--c-sprite-primary-6: var(--color-tang-6);
	--c-sprite-secondary-3: var(--color-comet-3);
	--c-sprite-secondary-5: var(--color-comet-5);
	--c-sprite-secondary-6: var(--color-comet-6);
	--c-sprite-contrast-1: var(--color-seal-1);
	--c-sprite-contrast-2: var(--color-shark-1);
	--c-sprite-contrast-3: var(--color-shark-3);
}

// Safari doesn't properly supports blur filters inside a SVG
// therefore we need to blur the whole thing
// tried feGaussianBlur and stuff
// "SVG filters have good support, but not all browsers support them being applied to regular DOM elements, notably Safari."
.c-sprite-icon--deco-aurora {
	--c-sprite-icon-blur: 13px;
}
