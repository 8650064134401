.b-cookies {
	--l-wrapper-max: 90rem;
	visibility: hidden;
	opacity: 0;
	transition: opacity var(--transition-time-1);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: var(--color-seal-1);
	z-index: var(--z-index-cookies);
	border-top: 1px solid var(--color-tang-4);
}

.b-cookies--active {
	visibility: visible;
	opacity: 1;
	display: block;
}

.b-cookies__wrapper {
	--max-ch: none;
	display: flex;
	flex-wrap: wrap;
	gap: var(--space-xs);
	align-items: center;

	@include _breakpoint--sm {
		flex-wrap: nowrap;
	}

	.b-cookies__icon {
		width: 48px;
		height: 48px;
		flex-shrink: 0;
		background-image: image_url("lib-cookie.svg", "icon");
		background-size: contain;
	}

	p {
		font-size: 1.5rem;
		flex-grow: 1;
		margin: 0;
	}

	.c-cta {
		font-size: 1.5rem;
		line-height: 1.3;
		box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) var(--color-shark-1),
			0 0 0 var(--space-4xs) var(--color-seal-1);
	}

	.c-cta--fill {
		--c-cta-background-color: var(--color-tang-6);

		&:hover {
			--c-cta-background-color: var(--color-tang-7);
		}
	}

	.c-cta--outline {
		--c-cta-border-color: var(--color-tang-6);
		--c-cta-color: var(--color-tang-6);

		&:hover {
			--c-cta-color: var(--color-seal-1);
			--c-cta-background-color: var(--color-comet-5);
		}
	}

	.l-group {
		display: flex;
		gap: 12px;
		flex-shrink: 0;
		width: auto;
		padding: 0;
	}
}
