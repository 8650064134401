@use "../tools/media";

.team {
	.section-container {
		width: $tablet-middle-container-width--wide;
		margin: 0 auto _space();
		max-width: 100%;
		padding: 0 _space();

		@include _breakpoint--md {
			width: $tablet-middle-container-width--wide-md;
			padding: 0 0.5rem;
			justify-content: flex-start;
		}

		@include _breakpoint--lg {
			width: $tablet-middle-container-width--wide-lg;
		}
	}

	h2 {
		@include _breakpoint--sm {
			margin-bottom: _double-space();
		}
	}

	&-member {
		margin: 0.5rem;
		width: $team__member-size * 0.5;
		color: var(--color-seal-1);
		overflow: hidden;
		background-color: var(--color-seal-1);
		@include _shadow(default);
		@include _transition;

		@include _breakpoint--sm-plus {
			width: $team__member-size;
		}

		@include _breakpoint--lg {
			width: $team__member-size - 15px;
		}

		&--hidden {
			display: none;
		}

		p {
			font-size: inherit;
			margin: 0;
		}

		@include _breakpoint--md {
			h3 {
				font-size: 2.6rem;
			}

			h4 {
				font-size: 2rem;
			}
		}

		&__outer {
			padding: 0.5rem;
		}

		&__image {
			position: absolute;
			left: 0;
			top: 0;

			img {
				max-width: 100%;
			}
		}

		&:hover,
		&:focus,
		&--selected {
			&:after {
				background-color: transparent;
			}

			.team-member__inner {
				opacity: 1;
			}

			@include _breakpoint--sm-plus {
				.team-member__description {
					opacity: 1;
				}
			}

			.team-member__link {
				opacity: 1;

				&:nth-of-type(1) {
					transition: opacity 300ms ease-in-out 250ms,
						transform 200ms ease;
				}

				&:nth-of-type(2) {
					transition: opacity 300ms ease-in-out 350ms,
						transform 200ms ease;
				}

				&:nth-of-type(3) {
					transition: opacity 300ms ease-in-out 450ms,
						transform 200ms ease;
				}

				&:nth-of-type(4) {
					transition: opacity 300ms ease-in-out 550ms,
						transform 200ms ease;
				}

				&:nth-of-type(5) {
					transition: opacity 300ms ease-in-out 650ms,
						transform 200ms ease;
				}
			}

			&--selected {
				.team-member__link {
					opacity: 1;

					&:nth-of-type(1),
					&:nth-of-type(2),
					&:nth-of-type(3),
					&:nth-of-type(4),
					&:nth-of-type(5) {
						transition: transform 200ms ease;
					}
				}
			}
		}

		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: $team__member-size * 0.5;
			height: $team__member-size * 0.5;
			background-color: color-mix(
				in srgb,
				var(--color-tang-7) 5%,
				var(--color-mix-base, transparent)
			);
			@include _transition(background-color);
			@include _zindex(float);

			@include _breakpoint--sm-plus {
				width: $team__member-size;
				height: $team__member-size;
			}
		}

		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			width: $team__member-size * 0.5;
			height: $team__member-size * 0.5;
			bottom: 0;
			padding: 1em;
			background-color: color-mix(
				in srgb,
				var(--color-tang-6) 80%,
				var(--color-mix-base, transparent)
			);
			text-align: center;
			color: var(--color-seal-1);
			opacity: 0;
			@include _transition(opacity, 300ms);
			@include _zindex(air);

			@include _breakpoint--sm-plus {
				width: $team__member-size;
				height: $team__member-size;
			}
		}

		&__description {
			position: absolute;
			display: none;
			bottom: _double-space();
			left: 0;
			padding: _space();
			text-align: center;
			width: 100%;
			text-transform: none;
			line-height: 2rem;
			font: {
				weight: 600;
				size: _font-scale(small);
			}
			opacity: 0;
			@include _transition(opacity, 200ms, ease, 300ms);

			@include _breakpoint--sm-plus {
				display: block;
			}
		}

		&__introduce {
			padding-bottom: 1rem;
			background-color: var(--color-seal-1);
			color: var(--color-shark-1);
			text-align: center;
			line-height: 1.2;
			padding: var(--space-2xs);

			h3,
			h4 {
				letter-spacing: 0;
			}

			h3 {
				display: inline-block;
				margin: 0;
				font: {
					size: var(--step-1);
					weight: 600;
				}
				line-height: 2.2;
			}

			h4 {
				margin: {
					bottom: 0;
				}
				font: {
					size: 1rem;
					weight: 400;
				}
				line-height: 1;
			}

			@include _breakpoint--sm-plus {
				padding: _space() * 0.5;

				h3 {
					font-size: 2rem;
				}

				h4 {
					font-size: 1.7rem;
					line-height: inherit;
				}
			}
		}

		&__link {
			display: inline-block;
			opacity: 0;
			pointer-events: none;

			&--active {
				pointer-events: auto;
			}

			@include _breakpoint--sm {
				margin-bottom: 0;
			}

			@include _breakpoint--sm-plus {
				margin: 0;
			}

			@include _breakpoint--mo {
				width: 50%;
				margin: 5px 0;
			}

			&:last-of-type {
				margin-right: 0;
			}

			&:hover,
			&:focus {
				transform: scale(1.1);
				outline: none;
			}

			&-wrapper {
				position: relative;
				display: block;
				top: 50%;
				left: 0;
				height: auto;
				transform: translateY(-50%);
				text-align: center;
				@include _zindex(air);

				@include _breakpoint--sm-plus {
					position: absolute;
					display: flex;
					justify-content: center;
					align-content: center;
					left: 50%;
					bottom: 10px;
					top: auto;
					transform: translateX(-50%);
				}
			}

			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3),
			&:nth-of-type(4),
			&:nth-of-type(5) {
				transition: transform 200ms ease;
			}

			&-ico {
				@extend .ico;

				&--github {
					@extend .svg-ico__github-circle;
				}

				&--g-plus {
					@extend .svg-ico__google-circle;
				}

				&--twitter {
					@extend .svg-ico__twitter-circle;
				}

				&--linkedin {
					@extend .svg-ico__linkedin-circle;
				}
			}
		}
	}
}
