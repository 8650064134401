.flag {
	width: 18px;
	height: 13px;
	background-repeat: no-repeat;
	background-size: contain;
	filter: drop-shadow(1px 1px 13px #00000096);
	border-width: 0 1px 1px 0;
	border-style: solid;
	border-color: #00000057;
}

.flag--position {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.flag--pl {
	background-image: image_url("pl.png", "flags");
}

.flag--en {
	background-image: image_url("gb.png", "flags");
}
