@use "../../../src/sass/tools/media";

$menu__offset: 1rem;
$menu__products-menu--width: 740px;
$menu__products-icon--size: 65px;

$max-menu-height: 465px;
$menu-covering-bar-height: 8px;
$menu-covering-bar-margin: 16px;

.menu {
	position: absolute;
	top: _space();
	width: 100%;
	background-color: transparent;
	border-bottom: 1px solid transparent;
	@include _zindex(cover);
	@include _transition(background-color, 200ms);

	.cksource-logo {
		color: var(--color-seal-1);
		position: absolute;
		top: 50%;
		display: inline-block;
		margin-left: $menu__offset * 2;
		background: {
			repeat: no-repeat;
			size: contain;
		}
		transform: translateY(-50%);
		padding: var(--space-xs) var(--space-s);
		line-height: 1;

		@include _breakpoint--sm {
			margin-left: _space() + $menu__offset;
		}
	}

	&-navigation {
		display: none;
		float: right;
		padding-right: $menu__offset * 2;
		height: $top-menu__height;
		line-height: $top-menu__height;
		@include _transition(padding);

		@include media.small {
			display: block;
			padding-right: _space() + $menu__offset;
		}

		&--ready {
			visibility: visible;
			opacity: 1;
		}

		&__socials {
			list-style: none;
			margin: 0;
			padding: 0;
			padding-right: $menu__offset * 2;
			display: flex;
			gap: _half-space();
			align-items: center;
			position: absolute;
			bottom: 65%;
			right: 0;

			@include _breakpoint--md {
				padding-right: _space() + $menu__offset + 1rem;
			}

			.menu-social-item {
				margin: 0;
			}

			.menu-social-link {
				--c-sprite-icon-unit: 1.6rem;
				display: inline-block;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background-color: #ffffff30;
				background-repeat: no-repeat;
				background-size: contain;
				color: var(--color-seal-1);

				body:has(.header-neo) & {
					background-color: var(--color-tang-6);
				}

				&:hover {
					background-color: #ffffff50;
				}
				&--twitter .c-sprite-icon--social-x {
					transform: translateX(-1px);
				}
			}
		}

		&__socials--mobile {
			position: static;
			margin-top: -3.9rem;

			.menu-social-link {
				width: 32px;
				height: 32px;
				--c-sprite-icon-unit: 2rem;
			}
		}

		&__list {
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&__item {
			display: inline-block;
			height: $top-menu__height;
			position: relative;

			&:first-of-type {
				margin-left: 0;
			}

			a {
				display: block;
				color: var(--color-seal-1);
				font-size: calc(var(--step-1) * 1.5);
				font-weight: 600;
				height: 100%;
				line-height: $top-menu__height;
				padding: 0 $menu__offset;
				position: relative;
				@include _transition(color);

				&:after {
					content: "";
					background-color: transparent;
					width: calc(100% - #{$menu__offset * 2});
					height: 2px;
					bottom: 15px;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					@include _transition(background-color);
				}

				&:hover {
					color: #fff;
					text-decoration: none;

					&:after {
						background-color: #fff;
					}
				}
				&:focus,
				&:hover {
					text-decoration: none;
				}
			}
			&--current a::after {
				background-color: var(--color-comet-5);
			}
		}
	}

	&-more {
		display: none;

		&--active {
			display: inline-block;
		}

		&__list li {
			a {
				height: auto;
				line-height: inherit;
				padding: 1rem 1.5rem;
			}

			&:first-of-type a {
				padding-top: 1.5rem;
			}

			&:last-of-type a {
				padding-bottom: 1.5rem;
			}
		}

		&__toggler {
			background-color: transparent;
			border: none;
			font: {
				weight: 800;
				size: _font-scale(big);
			}
			padding: {
				left: $menu__offset;
				right: $menu__offset;
			}

			@include _breakpoint--sm {
				font-size: _font-scale(medium);
			}

			&:hover,
			&:focus {
				background-color: transparent;
				border: none;
				color: car(--color-tang-5);
			}
		}
	}

	.p-home & {
		padding-top: calc(var(--space-m) * 1.5);
		top: 0;
		background-color: color-mix(
			in srgb,
			var(--color-seal-1) 80%,
			var(--color-mix-base, transparent)
		);
		height: var(--menu-height);

		&::after {
			position: absolute;
			width: 100vw;
			height: 2px;
			content: "";
			display: block;
			bottom: -2px;
			left: 0;
			background: var(--gradient-neo);
		}
		.cksource-logo {
			color: var(--color-shark-1);
		}
	}

	.c-sprite-icon--ui-new-tab {
		--c-sprite-icon-unit: 16px;
		margin-left: var(--space-3xs);
	}
}
