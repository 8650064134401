$primary-color: var(--color-tang-5);
$accent-color: color-mix(
	in srgb,
	var(--color-tang-5) 60%,
	var(--color-mix-base, var(--color-seal-1))
);

// Header isn't the part of the form wrapper
.form__header {
	text-align: center;
	line-height: 1.2;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 8px;

	@include _breakpoint--sm {
		margin-bottom: 32px;
	}

	&::after {
		display: none;
	}
}

.form-box {
	max-width: 960px;
	padding: 32px 16px;
	background-color: var(--color-seal-1);
	margin-left: 16px;
	margin-right: 16px;
	@include _shadow();

	@include _breakpoint--sm {
		padding: 32px;
		margin-left: auto;
		margin-right: auto;
	}

	@include _breakpoint--md {
		padding: 32px 48px;
	}
}

.form {
	& > * {
		&:last-child {
			margin-top: 16px;

			@include _breakpoint--sm {
				margin-top: 32px;
			}
		}
	}

	.form__col {
		display: flex;
		flex-direction: column;

		@include _breakpoint--sm {
			flex-direction: row;
		}

		& > * {
			flex: 1;

			&:first-child {
				@include _breakpoint--sm {
					margin-right: 8px;
				}
			}

			&:last-child {
				@include _breakpoint--sm {
					margin-left: 8px;
				}
			}
		}
	}

	.form__row {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-top: 4px;
		padding-bottom: 12px;

		@include _breakpoint--sm {
			margin-top: 4px;
			padding-bottom: 32px;
		}
	}

	.form__row--checkbox {
		padding-bottom: 12px;

		.error-message {
			bottom: 0px;
			left: 32px;
			font-size: 12px;
		}
	}

	.form__label {
		color: #e52283;
		margin-bottom: 4px;
	}

	.form__label-file-type {
		font-size: 13px;
		font-weight: 300;
		line-height: 1.6;
		color: #4e4b4b;
	}

	.form__label--checkbox {
		position: relative;
		padding-left: 32px;
		margin-bottom: 0;

		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 4px;
			width: 16px;
			height: 16px;
			border: 2px solid $primary-color;
			transition: 0.25s ease-in-out background-color;
		}

		a {
			font-size: inherit;
		}
	}

	.form__label--radio {
		display: flex;
		flex-direction: column;
		padding: 4px 0;
		border: 1px solid $primary-color;
		background-color: transparent;
		width: 100%;
		text-align: center;

		@include _breakpoint--sm {
			padding: 8px 0;
		}
	}

	.form__input {
		padding: 8px;
		border: 2px solid #e4e4f7;
		width: 100%;

		@include _breakpoint--sm {
			padding: 12px;
		}

		&:focus {
			outline: 2px solid $primary-color;
		}
	}

	.form__textarea {
		resize: vertical;
		min-height: 120px;
	}

	.form__cv-text {
		margin-top: 4px;
		font-size: 13px;
		margin-bottom: 0;
	}

	.form__radio-box {
		display: flex;
	}

	.form__radio-header {
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
		margin-bottom: 8px;
	}

	.form__radio,
	.form__checkbox {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;

		&:focus + .form__label--checkbox {
			&::before {
				outline: 2px solid $primary-color;
			}
		}
	}

	.form__checkbox {
		&:checked + .form__label--checkbox {
			&::before {
				background-color: $primary-color;
			}
		}
	}

	.form__radio {
		&:checked + .form__label--radio {
			background-color: $accent-color;
		}
	}

	.error-message {
		position: absolute;
		bottom: -2px;
		left: 0;
		color: red;
		font-size: _font-scale(tiny);
		line-height: 1;
		display: none;
		margin: 0;

		@include _breakpoint--sm {
			bottom: 14px;
			font-size: _font-scale(small);
		}
	}

	.form-error ~ .error-message {
		display: block;
	}

	.form__submit {
		display: block;
		appearance: none;
		border: none;
		color: #ffffff;
		background-color: $primary-color;
		width: 100%;
		font-weight: bold;
		padding: 16px 24px;
	}
}
