// this file is generated by gulp

	.c-sprite-icon--partner-att {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-broadcom {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-citi {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-disney {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-drupal {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-elsevier {
		--c-sprite-icon-width:  calc(5.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-fedex {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-ibm {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-microsoft {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-mit {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-mozilla {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-nbcuniversal {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-novartis {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-pfizer {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-rakuten {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-salesforce {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-sas {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-shortcut {
		--c-sprite-icon-width:  calc(5.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-siemens {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-snapchat {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-square {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-tata {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-thomsonreuters {
		--c-sprite-icon-width:  calc(9.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-volvo {
		--c-sprite-icon-width:  calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--partner-zendesk {
		--c-sprite-icon-width:  calc(9.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-angular {
		--c-sprite-icon-width:  calc(10.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-asp {
		--c-sprite-icon-width:  calc(9.3125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-bootstrap {
		--c-sprite-icon-width:  calc(7.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-dotnet {
		--c-sprite-icon-width:  calc(4.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-electron {
		--c-sprite-icon-width:  calc(13.8125 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-express {
		--c-sprite-icon-width:  calc(7.875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-java {
		--c-sprite-icon-width:  calc(2.0625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-laravel {
		--c-sprite-icon-width:  calc(9.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-maven {
		--c-sprite-icon-width:  calc(10.625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-nextjs {
		--c-sprite-icon-width:  calc(8.5625 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-php {
		--c-sprite-icon-width:  calc(6.6875 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-react {
		--c-sprite-icon-width:  calc(8.75 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-spring {
		--c-sprite-icon-width:  calc(8.25 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-symfony {
		--c-sprite-icon-width:  calc(11.375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-typescript {
		--c-sprite-icon-width:  calc(8.9375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-vitejs {
		--c-sprite-icon-width:  calc(5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-vue {
		--c-sprite-icon-width:  calc(6.5 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}

	.c-sprite-icon--tech-webpack {
		--c-sprite-icon-width:  calc(9.375 * var(--c-sprite-icon-unit, 1rem));
		--c-sprite-icon-height:  calc(4 * var(--c-sprite-icon-unit, 1rem));
	}
