$customers__item-height: 700px;
$customers__item-bg-width: 650px;
$customers__white-triangle-width: 200px;
$customers__top-bg-height--sm: 145px;
$customers__top-bg-height--sm-plus: 225px;

.customers {
	.section-header {
		@include _breakpoint--md {
			h2 {
				margin-left: _space();
			}
		}
	}

	.section-container {
		@include _breakpoint--sm-only {
			width: $tablet-middle-container-width--wide;
			max-width: 90%;
			margin: 0 auto;
		}
	}

	&__item {
		margin-bottom: _space();
		padding-top: 110px;
		background: {
			color: var(--color-seal-1);
			repeat: no-repeat;
			size: contain;
		}
		@include _shadow;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include _breakpoint--mo-small {
			padding-top: 90px;
		}

		@include _breakpoint--sm {
			padding-top: 145px;

			&,
			&:last-of-type {
				margin-bottom: _double-space();
			}
		}

		@include _breakpoint--sm-plus {
			padding-top: 190px;
		}

		@include _breakpoint--md {
			position: relative;
			display: flex;
			width: 100%;
			padding: 0;
			height: $customers__item-height;
			background-size: contain;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: $customers__item-bg-width -
					$customers__white-triangle-width;
				width: 0;
				height: 0;
				z-index: _get-zindex(default);
				border: {
					top: $customers__item-height solid var(--color-seal-1);
					right: $customers__white-triangle-width solid transparent;
				}
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				width: 650px;
				height: 180px;
				background-color: color-mix(
					in srgb,
					var(--color-tang-5) 0.8,
					var(--color-mix-base, transparent)
				);
			}
		}

		&-description {
			width: 100%;
			color: var(--color-shark-2);
			z-index: _get-zindex(float);

			@include _breakpoint--mo {
				padding-bottom: 1rem;
			}

			@include _breakpoint--sm {
				font-size: _font-scale(big);
			}

			&-content {
				padding: _space();

				@include _breakpoint--mo {
					background-color: var(--color-seal-1);
				}

				@include _breakpoint--md {
					padding: 0;
				}
			}

			@include _breakpoint--md {
				position: absolute;
				background-color: transparent;
				padding: _space();
			}

			h3 {
				margin: 0;
				padding: _space();
				line-height: 1;
				color: var(--color-seal-1);
				background-color: color-mix(
					in srgb,
					var(--color-tang-5) 0.8,
					var(--color-mix-base, transparent)
				);

				@include _breakpoint--md {
					padding: 0;
					margin: {
						top: inherit;
						bottom: _space();
					}
					line-height: inherit;
					background-color: inherit;
					color: var(--color-shark-1);
					@include _underline;
				}
			}
		}

		&-author {
			text-align: right;
			line-height: _space();
			margin: {
				top: _space() + 1rem;
				bottom: 0;
			}

			@include _breakpoint--sm {
				margin-top: _space();
				padding-right: 1rem;
			}

			@include _breakpoint--md {
				padding-right: 0;
			}

			&-name {
				display: block;
				width: 100%;
			}

			&-profession {
				display: block;
				font-size: 1.1rem;

				@include _breakpoint--sm {
					font-size: _font-scale(medium);
				}
			}
		}

		header {
			background-color: transparent;

			h3,
			p {
				margin-bottom: 0;
			}

			@include _breakpoint--md {
				h3 {
					margin-bottom: inherit;
					padding-bottom: _space();
				}
			}

			p {
				background-color: var(--color-seal-1);
				margin: 0;
				padding: _space();

				@include _breakpoint--md {
					margin: {
						top: _space();
						bottom: 0;
					}
					padding: 0;
					background-color: transparent;
				}
			}
		}

		footer {
			padding: _space();
			background-color: var(--color-shark-5);
			z-index: _get-zindex(float);

			@include _breakpoint--mo {
				display: none;
			}

			@include _breakpoint--md {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 178px;
			}

			h4,
			p {
				margin: {
					bottom: 0;
					top: 1rem;
				}
				color: var(--color-shark-2);
			}

			p {
				font-size: _font-scale(small);
				margin-top: 0;
			}
		}

		blockquote {
			padding: 1rem _space();
			margin: 0;
			background-color: var(--color-seal-1);
			font-style: italic;
			color: var(--color-shark-1);

			@include _breakpoint--sm {
				margin-bottom: _space();
				line-height: 3rem;
				padding: _space();
			}

			@include _breakpoint--md {
				background-color: transparent;
				margin-top: _space();
				padding: 0;
			}
		}

		&--left {
			@include _breakpoint--md {
				background-position: 100% 0;

				&:after {
					right: 0;
					left: auto;
				}

				.customers__item-description {
					padding-right: $customers__item-bg-width - 30px;
				}

				footer {
					padding-right: $customers__item-bg-width;
				}
			}
		}

		&--right {
			@include _breakpoint--md {
				&:before {
					left: 451px;
					border: {
						bottom: $customers__item-height solid
							var(--color-seal-1);
						left: $customers__white-triangle-width solid transparent;
						top: 0;
						right: 0;
					}
				}

				&:after {
					left: 0;
					right: auto;
				}

				.customers__item-description {
					padding: {
						left: $customers__item-bg-width + 20px;
						right: _space();
					}

					.customers__item-author {
						padding-right: 0;
					}
				}

				footer {
					padding-left: $customers__item-bg-width - 100px;
				}
			}
		}

		&--college {
			background-image: image_url("customers-college-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-college-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-college-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-college.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-college@2x.jpg",
						"bg"
					);
				}
			}
		}

		&--indiegogo {
			background-image: image_url("customers-indiegogo-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-indiegogo-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-indiegogo-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-indiegogo.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-indiegogo@2x.jpg",
						"bg"
					);
				}
			}
		}

		&--coremedia {
			background-image: image_url("customers-coremedia-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-coremedia-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-coremedia-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-coremedia.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-coremedia@2x.jpg",
						"bg"
					);
				}
			}
		}

		&--citi {
			background-image: image_url("customers-citi-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-citi-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-citi-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-citi.jpg", "bg");

				@include _hdpi {
					background-image: image_url("customers-citi@2x.jpg", "bg");
				}
			}
		}

		&--government {
			background-image: image_url("customers-goverment-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-goverment-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-goverment-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-goverment.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-goverment@2x.jpg",
						"bg"
					);
				}
			}
		}

		&--greenrope {
			background-image: image_url("customers-greenrope-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-greenrope-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-greenrope-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-greenrope.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-greenrope@2x.jpg",
						"bg"
					);
				}
			}
		}

		&--synergy {
			background-image: image_url("customers-synergy-mo.jpg", "bg");

			@include _breakpoint--sm {
				background-image: image_url("customers-synergy-ta.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-synergy-ta@2x.jpg",
						"bg"
					);
				}
			}

			@include _breakpoint--md {
				background-image: image_url("customers-synergy.jpg", "bg");

				@include _hdpi {
					background-image: image_url(
						"customers-synergy@2x.jpg",
						"bg"
					);
				}
			}
		}
	}
}
