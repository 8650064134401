.b-partners {
	// TODO :: cleanup overrides
	--l-wrapper-max: 80rem; // used by noSwipe mode

	h2 {
		font-size: calc(var(--step-4) * 1.5);
		line-height: var(--headline-line-height);

		&::after {
			content: none;
			display: none;
		}
	}
	// TODO :: fin
}

// https://codepen.io/t_afif/pen/ZEVVejP

// number of slides
$n: 14;

// slide width
$w: 9rem;

// gap as percentage of width
$g: 0.2;

.b-partners__track {
	--slide-duration: 30s;
	display: grid;
	grid-template-columns: repeat(#{$n}, $w);
	gap: $g * $w;
	mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
	overflow: hidden;

	&:hover .b-partners__slide {
		animation-play-state: paused;
	}
}

.b-partners__slide {
	grid-area: 1/1;
	animation: slide-animation var(--slide-duration) linear infinite;
	width: 100%;

	// TODO :: cleanup
	width: 9vw;
	--c-sprite-icon-unit: 21px;
	// TODO :: fin
}

@for $i from 2 to ($n + 1) {
	.b-partners__slide:nth-child(#{$i}) {
		animation-delay: #{calc((1 - $i) / $n * var(--slide-duration))};
	}
}

@keyframes slide-animation {
	#{calc(100/($n + ($n - 2) * $g) * 1%)} {
		transform: translate(-100%);
	}

	#{calc((100/($n + ($n - 2) * $g) + 0.01) * 1%)} {
		transform: translate(calc(($n - 1 + ($n - 2) * $g) * 100%));
	}
}
