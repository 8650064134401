.job__description {
	flex-grow: 1;
	margin-bottom: 32px;

	@include _breakpoint--md {
		margin-bottom: initial;
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > * {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
		@include _breakpoint--sm {
			margin-left: _double-space();
			margin-right: _double-space();
		}
	}

	@include _breakpoint--md {
		margin-right: 24px;
	}

	@include _breakpoint--mo {
		width: 100%;
	}

	ol {
		margin-block: 0;

		ul {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		& > li {
			&::before,
			&::after {
				display: none;
			}
		}
	}

	ul {
		list-style: none;
		list-style-position: inside;

		@include _breakpoint--sm {
			list-style-position: outside;
		}

		ul {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}

	li {
		&::before {
			content: "\2022";
			color: var(--color-tang-5);
			display: inline-block;
			width: 1em;
			margin-left: -1em;
		}
	}

	h4 {
		font-size: _font-scale(big);
		font-weight: 300;
		margin-top: _half-space() * 3;
		margin-bottom: 12px;

		@include _breakpoint--mo {
			font-size: 1.9rem;
		}

		@include _breakpoint--sm {
			padding-left: 20px;
		}

		& + ul {
			margin-top: initial;
		}
	}

	p {
		padding-left: 20px;
	}

	p + ul {
		margin-top: -$pretty-line-height--rem * 0.5;
	}
}
