/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,1408,16,1.333,4,1,&s=0.65|0.5|0.35|0.15,1.35|1.65|2|2.65|3.35|4|6.65,s-l */

:root {
	--step-4: clamp(1.6rem, calc(1.14rem + 2.29vw), 3.16rem); // h1 - 50px
	--step-3: clamp(1.42rem, calc(1.15rem + 1.39vw), 2.37rem); // h2 - 38px
	--step-2: clamp(1.27rem, calc(1.12rem + 0.75vw), 1.78rem); // h3 - 28px
	--step-1: clamp(1.13rem, calc(1.06rem + 0.31vw), 1.33rem); // h4 - 21px
	--step-0: clamp(1rem, calc(1rem + 0vw), 1rem); // p - 16px
	--step--1: clamp(0.75rem, calc(0.93rem + -0.2vw), 0.89rem); // small - 12px
}
