.header--404 {
	background-color: var(--color-tropical-7);

	&:after {
		display: none;

		@include _breakpoint--sm {
			content: "";
			position: absolute;
			background-color: transparent;
			width: 100%;
			height: 100%;
			border-style: solid;
			border-width: 0 0 20vw 100vw;
			border-color: transparent transparent var(--color-seal-1)
				transparent;
			left: 0;
			bottom: 0;
			display: block;
		}

		@include _breakpoint--hd {
			border-width: 0 0 10vw 100vw;
		}
	}

	.header-cover {
		display: none;
	}

	.header-content {
		position: absolute;
		left: _space();
		bottom: 20px;
		width: 80%;
		@include _zindex(air);

		@include _breakpoint--sm {
			left: _double-space();
			width: 760px;
			max-width: calc(100% - 100px - #{$main-space--px});
			left: 100px;
			bottom: 150px;
		}

		h1 {
			font-size: 10rem;
			line-height: 1;
			padding-bottom: 10px;
			font-weight: bold;

			@include _breakpoint--sm {
				font-size: 15rem;
			}
		}
	}
}

.footer--404 {
	display: none;
}

.site-content--404 {
	background-color: var(--color-seal-1);
	padding: 30px 20px;
	margin-bottom: 0;

	@include _breakpoint--sm {
		padding: 0;
	}

	section {
		position: relative;
		margin: 0;

		@include _breakpoint--sm {
			width: 800px;
			max-width: calc(100% - 100px - 25px);
			left: 100px;
		}

		@include _breakpoint--md {
			left: 50%;
			margin-left: -135px;
			transform: translateX(-50%);
		}

		@include _breakpoint--lg {
			margin-left: -358px;
		}
	}

	.page-404 {
		&__heading {
			font-size: 3rem;
			margin-bottom: _space();
			font-weight: bold;
			color: var(--color-shark-1);
		}

		&__content {
			p {
				font-weight: bold;
				color: var(--color-shark-1);
				line-height: 1.5;
				margin: {
					top: 0;
					bottom: 5px;
				}
			}
		}

		&__footer {
			text-align: center;
			padding-top: 50px;
			padding-bottom: 0;

			@include _breakpoint--sm {
				padding-top: 150px;
				padding-bottom: 50px;
			}

			.svg-ico {
				display: inline-block;
				opacity: 0.4;
				@include _transition();

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}
