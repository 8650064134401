.module-subscribe {
	text-align: center;
	padding: {
		top: _double-space();
		bottom: _double-space();
	}
	background-color: color-mix(
		in srgb,
		var(--color-comet-6) 0.25,
		var(--color-mix-base, transparent)
	);

	&__actions {
		margin-top: _space();
	}

	&:not(.module-subscribe--plain) {
		h2 {
			font-size: 3.7rem;
			line-height: _double-space();
		}
	}

	p,
	h2 {
		width: 640px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	&--plain {
		background-color: transparent;
		padding-top: 0;

		p,
		h2 {
			width: 680px;
		}
	}
}
