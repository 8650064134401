$spaces: ('4xs', '3xs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl');

.l-p {
	@each $space in $spaces {
		// all
		&-#{$space} {
			padding: var(--space-#{$space});
		}

		// left
		&l-#{$space} {
			padding-left: var(--space-#{$space});
		}

		// right
		&r-#{$space} {
			padding-right: var(--space-#{$space});
		}

		// horizontal
		&h-#{$space} {
			padding-left: var(--space-#{$space});
			padding-right: var(--space-#{$space});
		}

		// top
		&t-#{$space} {
			padding-top: var(--space-#{$space});
		}

		// bottom
		&b-#{$space} {
			padding-bottom: var(--space-#{$space});
		}

		// vertical
		&v-#{$space} {
			padding-top: var(--space-#{$space});
			padding-bottom: var(--space-#{$space});
		}
	}
}
