$link-color: var(--color-tang-5);

.link {
	position: relative;
	text-decoration: none;
	background-image: linear-gradient(
		to right,
		var(--color-tang-6) 0%,
		var(--color-tang-7) 100%
	);
	background-repeat: repeat-x;
	transition: 0.25s background-size ease-in-out,
		0.25s border-color ease-in-out;

	background-position: 0 100%;
	background-size: 0% 1px;
	background-repeat: no-repeat;
	&:hover {
		text-decoration: none;
		background-size: 100% 1px;
	}
}

.link--big {
	font-size: _font-scale(xbig);
}

.link--decorator {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(16px, -30%) rotate(-45deg);
		width: 10px;
		height: 10px;
		border-right: 2px solid $link-color;
		border-bottom: 2px solid $link-color;
		transition: transform 0.25s ease-in-out;
	}

	&:hover {
		&::after {
			transform: translate(20px, -30%) rotate(-45deg);
			border-color: currentColor;
		}
	}
}
