@use "../../../src/sass/tools/media";

.b-info-list {
	// @to-do
	// utility class candidate
	img[src$=".png"] {
		filter: drop-shadow(var(--dropshadow-1));
	}
	// TODO :: temp overrides - cleanup typography
	--l-wrapper-max: 88vw;

	a:not([class*="ch2"]),
	li,
	p {
		font-size: 2.1rem;
		line-height: 1.4;
		font-size: var(--step-2);
	}
	a {
		padding: calc(0.75rem * 1.5) calc(1.5rem * 1.5);
	}

	h2 {
		font-size: calc(var(--step-4) * 1.5);
		line-height: var(--headline-line-height);
	}

	h3 {
		font-size: calc(var(--step-2) * 1.5);
	}

	img {
		max-width: 100%;
		height: auto;
	}
	// TODO :: fin

	&--dark {
		background-color: var(--color-seal-2);
	}

	&--horizontal {
		--l-wrapper-max: 68rem;

		@include media.small {
			.c-card__main {
				flex-direction: row;
				align-items: center;
			}
		}

		.c-card__header {
			margin-inline: auto;
			max-width: 15rem;
		}
	}

	&--vertical {
		.l-grid {
			justify-content: center;
			--l-grid-placement: auto-fit;
			--l-grid-min-item-size: clamp(16rem, 90%, 28rem);
			--l-grid-max-item-size: #{calc(1 / 3 * 1fr)};
		}

		.c-icon-list {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
		}

		.c-icon-list__footer {
			flex-grow: 1;
			align-items: end;
		}
	}
}

.b-info-list__main {
	--max-ch: 70ch;
}
