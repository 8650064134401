.accordion {
	&__item {
		position: relative;
		border-radius: 4px;

		&--hide {
			display: none;
		}

		header {
			position: relative;
			@include _transition(border);

			&:hover,
			&:focus {
				outline: none;
				@include _breakpoint--sm {
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 0;
						border-top: 24px solid var(--color-tang-5);
						border-right: 24px solid transparent;
					}
				}
			}
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 2rem;
				opacity: 0.5;
				transform: translateY(-50%);
				// @extend .svg-ico__arrow-right;
				@include _transition;
				background: var(--color-tang-6);
				clip-path: polygon(
					50% 10%,
					70% 10%,
					40% 50%,
					70% 90%,
					50% 90%,
					20% 50%
				);
				transform: translateY(-50%) rotate(180deg);
				width: 25px;
				height: 32px;
			}
		}

		&--active {
			header {
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
					border-top: 24px solid var(--color-tropical-7);
					border-right: 24px solid transparent;
				}

				&::after {
					transition: transform 0.25s ease;
					transform: rotate(90deg) translateX(-50%);
					transform-origin: center;
				}
			}

			.offers__list-item-salary--big {
				opacity: 0;
			}
		}

		&.is-expanded header::after {
			transition: transform 0.25s ease;
			transform: translateY(-50%) rotate(90deg);
		}
	}
}

.accordion__item-content {
	@include _breakpoint--md {
		display: flex;
	}
}
