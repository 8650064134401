/* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1408,24,1.25,5,2,&s=0.65|0.5|0.35|0.15,1.35|1.65|2.65|4|6,s-l */

:root {
	--space-4xs: clamp(0.13rem, calc(0.09rem + 0.18vw), 0.25rem);
	--space-3xs: clamp(0.38rem, calc(0.34rem + 0.18vw), 0.5rem);
	--space-2xs: clamp(0.5rem, calc(0.43rem + 0.37vw), 0.75rem);
	--space-xs: clamp(0.63rem, calc(0.51rem + 0.55vw), 1rem);
	--space-s: clamp(1rem, calc(0.85rem + 0.74vw), 1.5rem);
	--space-m: clamp(1.38rem, calc(1.19rem + 0.92vw), 2rem);
	--space-l: clamp(1.63rem, calc(1.37rem + 1.29vw), 2.5rem);
	--space-xl: clamp(2.63rem, calc(2.22rem + 2.02vw), 4rem);
	--space-2xl: clamp(4rem, calc(3.41rem + 2.94vw), 6rem);
	--space-3xl: clamp(6rem, calc(5.12rem + 4.41vw), 9rem);

	/* One-up pairs */

	/* Custom pairs */
}
