.b-in-numbers {
	--l-grid-gap: 0;
	--l-grid-placement: auto-fit;
	--l-grid-min-item-size: 12rem;
	--l-grid-max-item-size: 14rem;
	background-image: var(--gradient-3);
	background-color: var(--color-tang-5);
	color: var(--color-seal-1);
	padding-top: 0;

	.l-grid {
		row-gap: var(--space-s);
		justify-content: center;
	}
	// TODO :: cleanup overrides
	--l-wrapper-max: 88vw;
	--l-grid-min-item-size: 14rem;
	--l-grid-max-item-size: 20rem;

	ol[role="list"],
	ul[role="list"] {
		list-style: none;
	}
	li {
		margin-top: 0;
	}

	h3 {
		font-size: calc(var(--step-4) * 1.5);
	}
	p {
		font-size: calc(var(--step-1) * 1.5);
	}
	// TODO :: fin
}
