section,
.section {
	@include _section;
	padding-top: _space();

	@include _breakpoint--sm {
		padding-top: _double-space();
	}

	&:last-of-type {
		padding-bottom: _double-space();
	}

	// temp fix for cookie hub banner bug
	&.ch2 {
    display: initial;
}
}

.section {
	&-header {
		margin-bottom: _space();
	}

	&-container {
		@include _bs-container();
	}

	&-relative {
		position: relative;
		width: 100%;

		@include _breakpoint--md {
			max-width: 1250px;
		}

		@include _breakpoint--lg {
			max-width: 1440px;
		}
	}
}

header,
main,
footer {
	display: flex;
	width: 100%;
	flex-direction: column;
}

* {
	box-sizing: border-box;
}
