$image-border-radius: 8px;
$image-border-width: 9px;
$image-border-color: #ffffff;

.gallery-slider {
	position: relative;
	overflow: hidden;
	margin-top: 42px;
	margin-bottom: space();
	padding-bottom: _double-space();
}

.gallery {
	align-items: center;

	.gallery__link {
		display: inline-block;
		overflow: hidden;
		border-radius: $image-border-radius;
		position: relative;

		&::before {
			content: "";
			z-index: 1;
			position: absolute;
			inset: 0;
			border: $image-border-width solid $image-border-color;
			box-shadow: 0px 0px 24px 0px rgba(246, 151, 193, 1);
		}

		&:focus,
		&:hover {
			.gallery__image {
				transform: scale(1.1);
			}
		}

		&:focus {
			&::before {
				border-color: #ffc702;
			}
		}
	}

	.gallery__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: $image-border-radius;
		transform: scale(1);
		transition: 0.25s ease-in-out transform;
	}
}

// Slider options
button.module-photos__prev,
button.module-photos__next {
	position: absolute;
	top: calc(100% - 50px);
	cursor: pointer;
	border: none;
	opacity: 0.3;
	@include _zindex(air);
	background-image: none;
	width: 24px;
	height: 36px;
	padding: var(--space-2xs);
	box-sizing: content-box;
	left: 50%;

	&:focus,
	&:hover {
		outline: none;
		opacity: 1;
	}

	&:after {
		clip-path: polygon(
			50% 10%,
			70% 10%,
			40% 50%,
			70% 90%,
			50% 90%,
			20% 50%
		);
		position: relative;
		display: block;
		background-color: var(--color-tang-6);
		background-image: none;
		width: 100%;
		height: 100%;
		content: "";
	}
}

.module-photos__prev {
	transform: translateX(calc(-50% - 24px));
}

.module-photos__next {
	transform: translateX(calc(-50% + 24px)) rotate(180deg);
}
