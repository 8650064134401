$button__base-size: 12px;
$button__base-font-size: $base-font-size--rem;
$button__base-change-step: 0.3rem;
$button__mobile-ratio: 1.2;

$button-sizes: (
	"big": $button__base-size * 1.3,
	"normal": $button__base-size,
	"small": $button__base-size * 0.8,
	"tiny": $button__base-size * 0.6,
);
$button-font-sizes: (
	"big": $button__base-font-size,
	"normal": $button__base-font-size - $button__base-change-step,
	"small": $button__base-font-size -
		(
			$button__base-change-step * 1.5,
		),
	"tiny": $button__base-font-size -
		(
			$button__base-change-step * 2,
		),
);
$cta-widths: (
	"long": _space() * 9,
	"normal": _space() * 7,
	"short": _space() * 5,
);

// Fix IE force button background color issue.
button {
	background-color: transparent;
}

@mixin _button() {
	display: inline-block;
	text: {
		decoration: none;
		align: center;
	}
	font-weight: 600;
	cursor: pointer;
	user-select: none;
	border-radius: var(--space-3xs);
	@include _transition;
	border-radius: var(--space-3xl);
	padding: var(--space-m) var(--space-xl);

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@mixin _button-reset() {
	background-color: inherit;
	border: 0;
}

@mixin _button-size($size: "normal") {
	@if (quote($size) != "big") {
		// padding: (map-get($button-sizes, quote($size)) * $button__mobile-ratio)
		// 	(map-get($button-sizes, quote($size)) * 2 * $button__mobile-ratio);
		// prettier-ignore
		font-size: calc(map-get($button-font-sizes, quote($size)) * $button__mobile-ratio);
	} @else {
		// padding: map-get($button-sizes, quote($size))
		// 	map-get($button-sizes, quote($size)) *
		// 	2
		// 	map-get($button-sizes, quote($size)) +
		// 	2px;
		font-size: map-get($button-font-sizes, quote($size));
	}

	@include _breakpoint--md {
		// padding: map-get($button-sizes, quote($size))
		// 	map-get($button-sizes, quote($size)) * 2
		// 	map-get($button-sizes, quote($size)) + 2px;
		font-size: map-get($button-font-sizes, quote($size));
	}
}

@mixin _button-color($inverse: false, $type: "tang-5") {
	border: 0;

	@if ($inverse != true) {
		background-color: var(--color-#{$type});
		color: var(--color-seal-1);
	} @else {
		background-color: var(--color-seal-1);
		color: var(--color-#{$type});
	}

	&:hover,
	&:focus {
		@if ($inverse != true) {
			background-color: color-mix(
				in srgb,
				var(--color-#{$type}) 5%,
				var(--color-mix-base, var(--color-shark-1))
			);
		} @else {
			background-color: var(--color-#{$type});
		}
		color: var(--color-seal-1);
	}

	&:disabled,
	&[disabled="true"] {
		&:hover,
		&:focus {
			@if ($inverse != true) {
				background-color: var(--color-#{$type});
				color: var(--color-seal-1);
			} @else {
				background-color: var(--color-seal-1);
				color: var(--color-#{$type});
			}
		}
	}
}

@mixin _cta($type: "normal") {
	@include _breakpoint--sm {
		min-width: map-get($cta-widths, $type);
	}
}

.btn {
	@include _button;
	@include _button-color;
	@include _button-size("small");

	@include _breakpoint--sm {
		@include _button-size;
	}

	&:disabled,
	&[disabled="true"] {
		opacity: 0.6;
		cursor: not-allowed;
	}

	&--success {
		@include _button-color("false", "comet-6");

		&.btn--frame {
			@include _button-color("true", "comet-6");
		}
	}

	&--frame {
		@include _button-color("true");

		&-bright {
			@include _button-color("true", "seal-1");
			background-color: transparent;

			&:hover,
			&:focus {
				color: var(--color-tang-5);
			}
		}
	}

	&--big {
		@include _button_size("big");
	}

	&--small {
		@include _button_size("small");
	}

	&--tiny {
		@include _button_size("tiny");
	}

	&-cta {
		@include _cta;

		&--long {
			@include _cta("long");
		}

		&--short {
			@include _cta("short");
		}
	}

	&-desktop-only {
		display: none;
		@include _breakpoint--md {
			display: block;
		}
	}

	&-mobile-only {
		display: block;
		@include _breakpoint--md {
			display: none;
		}
	}
}
