@use '../../../src/sass/tools/media';

.c-icon-list {
	.c-sprite-icon {
		flex-shrink: 0;
	}
}

.c-icon-list__header {
	--a-color: var(--color-shark-1);
	display: flex;
	align-items: center;
	gap: var(--space-xs);
}

// assigned to ul
.c-icon-list__main {
	display: flex;
	flex-direction: column;
	gap: var(--space-3xs);
}

// assigned to li
.c-icon-list__item {
	display: flex;
	gap: var(--space-4xs);
}

.c-icon-list__columns {
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--space-3xs);

	@include media.small {
		grid-template-columns: repeat(var(--c-icon-list-columns, 1), 1fr);
	}
}

.c-icon-list__item--custom-icon {
	align-items: center;
	gap: 0.6rem;

	.c-sprite-icon {
		--c-sprite-icon-width: 2.1rem;
		--c-sprite-icon-height: 2.1rem;
	}
}
