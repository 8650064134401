$skill-badge-wrapper-margin-x: 4px;
$skill-badge-wrapper-margin-y: 12px;

$skill-badge-font-size: _font-scale(small);
$skill-badge-line-height: 1.5;
$skill-badge-background-color: #ffffff;
$skill-badge-border: 1px solid var(--color-tang-5);
$skill-badge-border-radius: 12px;
$skill-badge-padding: 4px 12px;
$skill-badge-margin-y: 4px;

.skill-badge-wrapper {
	margin-left: -$skill-badge-wrapper-margin-x;
	margin-right: -$skill-badge-wrapper-margin-x;
	margin-bottom: $skill-badge-wrapper-margin-y;
}

.skill-badge {
	display: inline-block;
	font-size: $skill-badge-font-size;
	line-height: $skill-badge-line-height;
	background-color: $skill-badge-background-color;
	border: $skill-badge-border;
	border-radius: $skill-badge-border-radius;
	margin-left: $skill-badge-wrapper-margin-x;
	margin-right: $skill-badge-wrapper-margin-x;
	margin-bottom: $skill-badge-margin-y;
	padding: $skill-badge-padding;
}
