$blog-margin-x: 16px;

.blog {
    position: relative;
    overflow: hidden;

    .blog-item {
        margin: 0;
        width: 100%;
        
        &:not(:last-of-type) {
            margin-bottom: 2 * $blog-margin-x;
        }
        
        @include _breakpoint--sm {
            padding-left: $blog-margin-x;
            padding-right: $blog-margin-x;
            width: 50%;
        }
        
        @include _breakpoint--md {
            margin-bottom: initial;
            width: 33.333%;
        }
    }
}

.blog-wrapper {
    margin-top: _double-space();
    margin-bottom: _double-space();

    .btn-wrapper {
        text-align: center;
    }
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: -$blog-margin-x;
    margin-right: -$blog-margin-x;
    margin-bottom: 24px;
}
