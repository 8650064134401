$careers__instagram-item-size: 300px;

.careers {
	.section {
		&-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include _breakpoint--mo {
				display: none;
			}
		}

		&-instagram-cta {
			a {
				text-decoration: none;

				.svg-ico__instagram {
					margin: 0 10px;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}

.offers {
	padding-top: _double-space();
	@include _transition(all, 600ms);

	@include _breakpoint--mo {
		.section-container {
			padding: 0;
		}

		h2 {
			margin-left: $gutter-width * 0.5;
		}

		a {
			font-weight: bold;
		}
	}

	.no-offers {
		max-width: 100%;
		padding: _space();
		margin: {
			top: _space();
			left: auto;
			right: auto;
			bottom: 0;
		}
		background-color: var(--color-seal-1);
		@include _shadow;

		@include _breakpoint--sm {
			width: 90%;
		}

		@include _breakpoint--md {
			width: $tablet-middle-container-width--wide-md - 150px;
		}
	}

	&__list {
		@include _list-reset;
		@include _shadow;
		list-style: none;
		margin-top: _space();

		&-item {
			position: relative;
			background-color: var(--color-seal-1);

			&.accordion__item--active {
				.offers__list-item-header {
					.offer__list-item-bottom {
						display: none;
					}
				}
			}

			&-content {
				display: none;

				@extend .pretty-text;

				@include _breakpoint--mo {
					flex-direction: column;
					&,
					p {
						font-size: 1.5rem;
					}
				}

				@include _breakpoint--sm {
					font-size: inherit;
					overflow: visible !important;
				}

				.accordion__item--active & {
					display: flex;
					flex-direction: column-reverse;

					@include _breakpoint--md {
						flex-direction: row;
					}
				}
			}
			& + .offers__list-item {
				margin-top: 1rem;
			}
		}
	}

	&-more {
		margin-top: _double-space();
		text-align: center;
	}
}

.job {
	&__side {
		padding-bottom: _space();

		@include _breakpoint--md {
			padding-right: _space();
		}

		@include _breakpoint--md {
			flex: 0 0 340px;
		}
	}
	&__apply {
		&-form {
			width: 500px;
			max-width: 90%;
			max-height: 0;
			opacity: 0;
			text-align: left;
			margin: {
				top: 0;
				bottom: 0;
			}
			transition: opacity 300ms ease-in 200ms, margin 200ms ease,
				max-height 300ms ease;

			&--active {
				opacity: 1;
				margin: {
					top: _double-space();
					bottom: _double-space();
				}
			}

			&--hide {
				display: none;
				@include fadeOutUp(
					$duration: 300ms,
					$function: ease-in-out,
					$fill: both
				);
			}

			legend {
				padding-bottom: 7px;
				font-size: _font-scale(big);
				color: var(--color-shark-2);
			}

			.form {
				&-group {
					margin-bottom: _space();
				}

				&__label {
					text-align: left;
					width: 100%;
				}
			}
		}

		&-btn {
			@include _transition;

			@include _breakpoint--mo {
				max-width: 100%;
			}

			&--open {
				display: none;
			}
		}
	}
}
