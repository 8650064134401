@mixin focus($focus-inner-color, $focus-outer-color) {
	transition-property: box-shadow;
	transition-duration: var(--transition-time-1);
	outline: 0; // remove default :focus outline style
	box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) $focus-inner-color,
		0 0 0 var(--space-4xs) $focus-outer-color;
}

@mixin style(
	$focus-outer-color: var(--color-tang-5),
	$focus-inner-color: var(--color-seal-1),
	$focus-visible-modifier: true
) {
	/**
   * :focus-visible
   * https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
   */

	/**
   * Provide a fallback style for browsers that don't support :focus-visible
   */
	&:focus {
		@include focus($focus-inner-color, $focus-outer-color);
	}

	/**
   * Draw a very noticeable focus style for keyboard-focus on browsers that do support :focus-visible
   */
	&:focus-visible {
		@include focus($focus-inner-color, $focus-outer-color);
		@content;
	}

	// used for ui kit presentation purposes
	@if $focus-visible-modifier {
		&--focus-visible {
			@include focus($focus-inner-color, $focus-outer-color);
			@content;
		}
	}

	/**
   * Remove the focus indicator on mouse-focus for browsers that do support :focus-visible
   */
	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}
