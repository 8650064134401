.swal2-modal {
	font-family: inherit;

	.swal2-title {
		font: {
			weight: 200;
			size: 3.7rem;
		}
	}

	label {
		display: block;
		text-align: left;
		padding-left: 12px;
	}
}

.form-control {
	&[readonly] {
		background-color: color-mix(
			in srgb,
			var(--color-shark-1) 0.03,
			var(--color-mix-base, transparent)
		);
	}
}

.vis {
	&-timeline {
		font-family: var(--font-family);
		font-size: 12pt;
		border: none;
	}

	&-item {
		border-color: color-mix(
			in srgb,
			var(--color-tang-5) 0.5,
			var(--color-mix-base, transparent)
		);
		border-width: 2px;
		background-color: var(--color-seal-1);
		font-size: _font-scale(medium);
		color: var(--color-shark-1);
		cursor: default;
		@include _shadow(small);

		&.vis-box {
			transform: translateX(35%);
		}

		&.vis-selected {
			border-color: color-mix(
				in srgb,
				var(--color-tang-5) 0.5,
				var(--color-mix-base, transparent)
			);
			background-color: var(--color-tang-5);
		}

		&.vis-dot {
			border-width: 10px;
			border-radius: 10px;
		}

		&.vis-line {
			border-width: 2px;
		}
	}

	&-time-axis {
		.vis-text {
			color: var(--color-shark-1);
			padding-top: 10px;
			padding-left: 10px;

			&.vis-major {
				font-weight: bold;
			}
		}

		.vis-grid {
			&.vis-minor {
				border-width: 2px;
				border-color: var(--color-shark-5);
			}

			&.vis-major {
				border-width: 2px;
				border-color: var(--color-shark-5);
			}
		}
	}
}
