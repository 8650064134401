$decorator-color: #b604592b;
$decorator-background-color: #ffffff;
$decorator-thickness: 3px;
$decorator-thickness-mobile: 2px;
$decorator-size: 44px;
$decorator-size-mobile: 24px;
$decorator-size-mobile--small: 16px;


.icon-box {
    display: inline-block;
    position: absolute;
    z-index: 1;
    background-color: $decorator-background-color;
    border-radius: 50%;
    top: -4px;
    padding: $decorator-size-mobile--small;
    border: $decorator-thickness-mobile solid $decorator-color;
    
    @include _breakpoint--xsm {
        top: -8px;
        padding: $decorator-size-mobile;
    }
    
    @include _breakpoint--sm {
        top: 40px;
        padding: $decorator-size;
        border-width: $decorator-thickness;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0.4);
        z-index: 1;
        
        @include _breakpoint--xsm {
            transform: translate(-50%,-50%) scale(0.6);
        }

        @include _breakpoint--sm {
            transform: translate(-50%, -50%);
        }
    }
}
