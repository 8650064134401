.module-trustedby {
	display: inline-block;
	width: 100%;

	@include _breakpoint--sm-plus {
		padding-top: 295px;
	}

	&__white-inner {
		display: inline-block;
		width: 100%;
		padding: {
			top: _double-space();
			bottom: _space() * 3;
		}
		background: {
			color: var(--color-seal-1);
			image: image_url("trusted-by-mo.svg", "bg");
			repeat: no-repeat;
		}

		&::after {
			content: "";
			background-image: image_url("trusted-by-mo.svg", "bg");
		}

		@include _breakpoint--sm {
			padding: {
				top: _double-space() * 2;
				bottom: _double-space() * 2;
			}
			background: {
				image: image_url("trusted-by.svg", "bg");
				position: -190px 510px;
				size: 670px 680px;
			}
		}

		@include _breakpoint--sm-plus {
			background: {
				position: -80px 270px;
				size: 796px 1000px;
			}
		}

		@include _breakpoint--md {
			background: {
				size: 1100px;
				position: -90px 330px;
			}
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		max-width: 100%;

		@include _breakpoint--sm {
			margin-top: _space();
		}
	}

	&__logotype {
		margin: 0 auto;
		width: 130px;
		height: 60px;
		background: {
			size: 140px auto;
			repeat: no-repeat;
			position: center center;
		}

		@include _breakpoint--xsm {
			width: 150px;
			height: 80px;
			background-size: 160px;
		}

		@include _breakpoint--sm {
			width: 240px;
			height: 130px;
			background: {
				size: 220px auto;
			}
		}

		@include _breakpoint--md {
			width: 300px;
			height: 130px;
			background: {
				size: 230px auto;
			}
		}

		&--accenture {
			background-image: image_url("logo-accenture.svg", "partner");
			background-size: 110px;

			@include _breakpoint--xsm {
				background-size: 140px;
			}

			@include _breakpoint--sm {
				background-size: 160px;
			}
		}

		&--adobe {
			background-image: image_url("logo-adobe.svg", "partner");
		}

		&--att {
			background-image: image_url("logo-att.svg", "partner");
		}

		&--citi {
			background-image: image_url("logo-citi.svg", "partner");
		}

		&--deloitte {
			background-image: image_url("logo-deloitte.svg", "partner");
		}

		&--disney {
			background-image: image_url("logo-disney.svg", "partner");
		}

		&--drupal {
			background-image: image_url("logo-drupal.svg", "partner");
		}

		&--ibm {
			background-image: image_url("logo-ibm.svg", "partner");
		}

		&--microsoft {
			background-image: image_url("logo-microsoft.svg", "partner");
		}

		&--mit {
			background-image: image_url("logo-mit.svg", "partner");
			background-size: 42px auto;

			@include _breakpoint--sm {
				background-size: 72px auto;
			}
		}

		&--mozilla {
			background-image: image_url("logo-mozilla.svg", "partner");
		}

		&--nbc {
			background-image: image_url("logo-nbc.svg", "partner");
		}

		&--novartis {
			background-image: image_url("logo-novartis.svg", "partner");
			background-size: 170px auto;

			@include _breakpoint--xsm {
				background-size: 190px auto;
			}

			@include _breakpoint--sm {
				background-size: 290px auto;
			}

			@include _breakpoint--md {
				background-size: 340px auto;
			}
		}

		&--oracle {
			background-image: image_url("logo-oracle.svg", "partner");
		}

		&--siemens {
			background-image: image_url("logo-siemens.svg", "partner");
		}

		&--volvo {
			background-image: image_url("logo-volvo.svg", "partner");
		}

		&--tata {
			background-image: image_url("logo-tata.svg", "partner");
		}

		&--thomson {
			background-image: image_url("logo-thomson.svg", "partner");
			background-size: 140px auto;

			@include _breakpoint--xsm {
				background-size: 160px auto;
			}

			@include _breakpoint--sm {
				background-size: 270px auto;
			}

			@include _breakpoint--md {
				background-size: 320px auto;
			}
		}

		&--unicef {
			background-image: image_url("logo-unicef.svg", "partner");
		}
	}
}
