$features-grid__gutter: 10px;
$features-grid__max-width: 1440px;

.features-grid {
	position: relative;
	height: 900px;
	width: 100%;
	max-width: 100%;
	margin: _space() auto (_double-space() * 2);
	@include _transition;

	@include _breakpoint--sm {
		height: 700px;
	}

	@include _breakpoint--md {
		height: 750px;
		width: 90%;
		max-width: $features-grid__max-width;
	}

	&--big {
		margin-bottom: _double-space();
		@include _breakpoint--sm {
			height: 750px;
		}

		@include _breakpoint--md {
			height: 850px;
		}
	}

	&__wrapper {
		h2 {
			text-align: center;
		}
	}

	&__col {
		position: absolute;
		height: 100%;
		margin: 0;
		padding: 0;
		overflow: hidden;
		transition: all 300ms ease-out;

		.features-grid__item {
			left: $features-grid__gutter * 0.5;
			right: $features-grid__gutter * 0.5;
		}

		&:first-of-type {
			.features-grid__item {
				&--full,
				&--half,
				&--third {
					left: $features-grid__gutter;
				}
			}
		}

		&:last-of-type {
			.features-grid__item {
				&--full,
				&--half,
				&--third {
					right: $features-grid__gutter;
				}
			}
		}

		&--left,
		&--middle,
		&--right {
			width: 100%;
		}

		@include _breakpoint--sm {
			&--left,
			&--middle,
			&--right {
				width: 33.3333%;
			}

			&--left {
				left: 0;
			}

			&--middle {
				left: 33.3333%;
			}

			&--right {
				left: 66.6666%;
			}
		}
	}

	&--irregular {
		.features-grid__col {
			@include _breakpoint--sm {
				&--left {
					width: 50%;
				}

				&--middle {
					width: 25%;
					left: 50%;
				}

				&--right {
					width: 25%;
					left: 75%;
				}
			}
		}
	}

	&__item {
		position: absolute;

		&--full {
			top: $features-grid__gutter;
			bottom: $features-grid__gutter;

			.features-grid__item-description {
				bottom: 10%;
				top: auto;
				transform: none;

				p {
					font-size: inherit;
				}
			}
		}

		&--half {
			&:first-of-type {
				top: $features-grid__gutter;
				bottom: 50%;

				.features-grid__item-inner {
					bottom: $features-grid__gutter * 0.5;
				}
			}

			&:last-of-type {
				top: 50%;
				bottom: $features-grid__gutter;

				.features-grid__item-inner {
					top: $features-grid__gutter * 0.5;
				}
			}
		}

		&--third {
			&:first-of-type {
				top: $features-grid__gutter;
				bottom: 66.6666%;

				.features-grid__item-inner {
					bottom: $features-grid__gutter * 0.5;
				}
			}

			&:nth-of-type(2) {
				top: 33.3333%;
				bottom: 33.3333%;

				.features-grid__item-inner {
					top: $features-grid__gutter * 0.5;
					bottom: $features-grid__gutter * 0.5;
				}
			}

			&:last-of-type {
				top: 66.6666%;
				bottom: $features-grid__gutter;

				.features-grid__item-inner {
					top: $features-grid__gutter * 0.5;
				}
			}
		}

		&-description {
			padding: _space();
			width: 100%;
			background-color: color-mix(
				in srgb,
				var(--color-shark-1) 40%,
				var(--color-mix-base, transparent)
			);
			overflow: hidden;
			@include _center(v);
			@include _zindex(float);
			@include _transition;
			@include _shadow(raise);

			p {
				color: var(--color-seal-1);
				transition: all 500ms ease-in-out;
				text-align: center;
				margin: 0;
				font-size: _font-scale(medium);
			}
		}

		&-inner,
		&-cover {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&-cover {
			margin: 0;
			background: {
				position: 50% 0;
				repeat: no-repeat;
				size: cover;
				// image: url( ../images/hero-item.jpg );
				color: var(--color-tang-5);
			}
			// filter: grayscale( 10% );
			transition: transform 300ms ease-out, filter 300ms ease-out;
		}

		&-inner {
			overflow: hidden;
		}
	}
}
