.offers-filter-handler {
	margin-left: 15px;
	margin-right: 15px;
	margin-top: _space();

	@include _breakpoint--sm {
		display: none;
	}
}

.job-filters {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%) rotateZ(-90deg);
		width: 0.8em;
		height: 0.5em;
		background-color: var(--color-tang-5);
		clip-path: polygon(100% 0%, 0 0%, 50% 100%);
		transition: 0.25s ease-in-out transform;
	}
}

.job-filters--active {
	&::after {
		transform: translateY(-50%) rotateZ(0);
	}
}

.icon--settings {
	height: 24px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: image_url("gears.svg", "icon");
	border: none;
	appearance: none;
	padding: 0 16px 0 40px;
}

.offers-filter-wrapper {
	display: none;
	margin-top: 32px;
	padding-left: 15px;
	padding-right: 15px;

	@include _breakpoint--sm {
		display: block;
	}

	@include _breakpoint--sm {
		display: flex;
		justify-content: space-between;
		padding-left: initial;
		padding-right: initial;
		margin-left: -8px;
		margin-right: -8px;
	}

	& > * {
		flex: 1;
	}
}

.offers-filter-wrapper--active {
	display: block;
}
