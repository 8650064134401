.l-grid {
	display: grid;
	grid-template-columns: repeat(
		var(--l-grid-placement, auto-fill),
		minmax(
			var(--l-grid-min-item-size, 16rem),
			var(--l-grid-max-item-size, 1fr)
		)
	);
	gap: var(--l-grid-gap, var(--space-s));
}

.l-grid--50-50 {
	--l-grid-placement: auto-fit;
	--l-grid-min-item-size: clamp(16rem, 50vw, 26rem);
}
