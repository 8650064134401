section.contest {
	padding-top: 0;
	padding-bottom: _space();
	border-top: 0;
	background-color: #ffffff;

	.menu {
		left: 0;
		width: auto;

		@include _breakpoint--sm {
			height: 65px;
		}
	}

	.cksource-logo {

		margin-left: 37px;
		@include _breakpoint--sm {
			margin-left: 53px;
		}
	}

	.btn-quiz-box {
		padding-top: 24px;
		border-top: 2px solid #0086cc1c;
	}
}

section.contest.contest-meetjs {
	background-color: #391c69;
	color: #ffffff;
}

.decorators {
	position: relative;
}

.decorator {
	content: '';
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;

	&--left-top-curtain {
		top: 59px;
		left: 0;
		width: 256px;
		height: 384px;
		background-image: image_url("deco-left-top-curtain.svg", "contest");

		@include _breakpoint--sm {
			top: 127px;
		}
	}

	&--right-bottom-curtain {
		right: 0;
		bottom: 0;
		width: 329px;
		height: 175px;
		background-image: image_url("deco-right-bottom-triangle.svg", "contest");
	}
}

.contest-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 500px;

	@include _breakpoint--sm {
		flex-direction: row;
	}
}
