// TODO  :: cleanup cks overrides
.b-info {
	.l-wrapper {
		--l-wrapper-max: calc(88rem * 1.5);
	}

	.l-grid {
		--l-grid-min-item-size: calc(16rem * 2.5);
	}

	h3 {
		font-size: calc(var(--step-2) * 1.3);
	}
}
// TODO :: fin

.b-info {
	background-color: var(--color-seal-2);

	.l-grid {
		justify-content: center;
		--l-grid-placement: auto-fit;
	}
}

.b-info--light {
	background-color: var(--color-seal-1);
}

.b-info--premium {
	background: var(--gradient-1);
	color: var(--color-seal-1);
}

// 2 column
// assuming that core `.l-grid` remains as it is
.b-info--thin .b-info__cards {
	--l-wrapper-max: 48rem;
}

// 3 column
// assuming that core `.l-grid` remains as it is
.b-info--slim .b-info__cards {
	--l-wrapper-max: 68rem;
}

// 4 column
// assuming that core `.l-grid` remains as it is
.b-info--narrow .b-info__cards {
	--l-wrapper-max: 78rem;
}

.b-info__icon {
	--c-sprite-icon-height: var(--space-l);
	--c-sprite-icon-width: var(--space-l);
	border-radius: 50%;
	background-color: var(--color-seal-1);
}

// let's see if it is not too big
.b-info__cards .c-card {
	--c-sprite-icon-height: 2.65rem;
	--c-sprite-icon-width: 2.65rem;
}
