@charset "UTF-8";
/**
 * Default colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default light text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Pick a color from default colors map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale hex map.
 *
 * @access public
 * @param {String} $type - Color name.
 */
/**
 * Returns text color from predefinied map.
 *
 * @access public
 * @param {String} $type [ primary ] - Text color type.
 * @param {String} $scale [ gray ] - Scale type (available gray and light).
 */
/**
 * Display border with default color.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border.
 * @param {String} $width [ 1px ] - Width of the border.
 */
/**
 * Set predefinied border radius.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border radius.
 */
/**
 * Default colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default light text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Pick a color from default colors map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale hex map.
 *
 * @access public
 * @param {String} $type - Color name.
 */
/**
 * Returns text color from predefinied map.
 *
 * @access public
 * @param {String} $type [ primary ] - Text color type.
 * @param {String} $scale [ gray ] - Scale type (available gray and light).
 */
/* mobile first breakpoint create helper */
/* Sizes are defined in ../settings/variables */
.row--hidden {
  display: none;
}

/**
 * Default box shadows.
 *
 * @type Map
 * @access public
 */
/**
 * Default box shadows transition.
 *
 * @type variable
 * @access public
 */
/**
 * Implement box shadow
 *
 * @access public
 * @param {String} $type [ default ] - Type of box shadow (small || default).
 * @param {Boolean} $transition [ false ] - Determinated if box shadow should be animated.
 */
/**
 * Set transition with default values.
 *
 * @access public
 * @param {String} $property [ all ] - Property name.
 * @param {String} $duration [ 200ms ] - Transition duration time.
 * @param {String} $timing-function [ 200ms ] - Name of timing function.
 * @param {String} $delay [ null ] - Delay time.
 */
/*
Mulish subsetted for English, Polish & Turkish using gfonts `text` variable
characters: !"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~¢£¥¨©«®´¸»ÂÇÎÓÖÛÜâçîóöûüĄąĆćĘęĞğİıŁłŃńŚśŞşŹźŻżˆ˚˜           ​‌‍‎‏‐‑‒–—―‖‗‘’‚“”„‚‛“”„‟†‡•‣․‥…‧  ‪‫‬‭‮ ‰‱′″‴‵‶‷‸‹››※‼‽‾‿⁀⁁⁂⁃⁄⁅⁆⁇⁈⁉⁊⁋⁌⁍⁎⁏⁐⁑⁒⁓⁔⁕⁖⁗⁘⁙⁚⁛⁜⁝⁞ ⁠⁡⁢⁣⁤⁥⁦⁧⁨⁩⁪⁫⁬⁭⁮⁯€™
Stylesheet link for current version from google: https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300..800;1,300..800&&text=%21%22%23%24%25%26%27%28%29%2A%2B%2C-.%2F0123456789%3A%3B%3C%3D%3E%3F%40ABCDEFGHIJKLMNOPQRSTUVWXYZ%5B%5C%5D%5E_%60abcdefghijklmnopqrstuvwxyz%7B%7C%7D~%C2%A2%C2%A3%C2%A5%C2%A8%C2%A9%C2%AB%C2%AE%C2%B4%C2%B8%C2%BB%C3%82%C3%87%C3%8E%C3%93%C3%96%C3%9B%C3%9C%C3%A2%C3%A7%C3%AE%C3%B3%C3%B6%C3%BB%C3%BC%C4%84%C4%85%C4%86%C4%87%C4%98%C4%99%C4%9E%C4%9F%C4%B0%C4%B1%C5%81%C5%82%C5%83%C5%84%C5%9A%C5%9B%C5%9E%C5%9F%C5%B9%C5%BA%C5%BB%C5%BC%CB%86%CB%9A%CB%9C%E2%80%80%E2%80%81%E2%80%82%E2%80%83%E2%80%84%E2%80%85%E2%80%86%E2%80%87%E2%80%88%E2%80%89%E2%80%8A%E2%80%8B%E2%80%8C%E2%80%8D%E2%80%8E%E2%80%8F%E2%80%90%E2%80%91%E2%80%92%E2%80%93%E2%80%94%E2%80%95%E2%80%96%E2%80%97%E2%80%98%E2%80%99%E2%80%9A%E2%80%9C%E2%80%9D%E2%80%9E%E2%80%9A%E2%80%9B%E2%80%9C%E2%80%9D%E2%80%9E%E2%80%9F%E2%80%A0%E2%80%A1%E2%80%A2%E2%80%A3%E2%80%A4%E2%80%A5%E2%80%A6%E2%80%A7%E2%80%A8%E2%80%A9%E2%80%AA%E2%80%AB%E2%80%AC%E2%80%AD%E2%80%AE%E2%80%AF%E2%80%B0%E2%80%B1%E2%80%B2%E2%80%B3%E2%80%B4%E2%80%B5%E2%80%B6%E2%80%B7%E2%80%B8%E2%80%B9%E2%80%BA%E2%80%BA%E2%80%BB%E2%80%BC%E2%80%BD%E2%80%BE%E2%80%BF%E2%81%80%E2%81%81%E2%81%82%E2%81%83%E2%81%84%E2%81%85%E2%81%86%E2%81%87%E2%81%88%E2%81%89%E2%81%8A%E2%81%8B%E2%81%8C%E2%81%8D%E2%81%8E%E2%81%8F%E2%81%90%E2%81%91%E2%81%92%E2%81%93%E2%81%94%E2%81%95%E2%81%96%E2%81%97%E2%81%98%E2%81%99%E2%81%9A%E2%81%9B%E2%81%9C%E2%81%9D%E2%81%9E%E2%81%9F%E2%81%A0%E2%81%A1%E2%81%A2%E2%81%A3%E2%81%A4%E2%81%A5%E2%81%A6%E2%81%A7%E2%81%A8%E2%81%A9%E2%81%AA%E2%81%AB%E2%81%AC%E2%81%AD%E2%81%AE%E2%81%AF%E2%82%AC%E2%84%A2
*/
@font-face {
  font-style: normal;
  font-weight: 300 800;
  src: url(https://fonts.gstatic.com/l/font?kit=1Ptvg83HX_SGhgqU3QAqQqFdk_S_53g2xIaoNp0VDhtw7-GVUBN0tDQ-67CvTk-woiU_UymhWc6uZamsR35zmHRfyzWjoiw7o7kw5Cy088v_cJioRmLZRm08KDflvUOWty8hPgdtcVu4hVhYfQmRqWPaBdO7adZNQsVhb8oToLK7UG8_MTWYxyrFShMhBKmnzy0XyKKLH_Q89uZrWu2rYhhnLhdKxg2CnmYwqGxijs-n0LoDdxSPkwRgDJeMpUxhk0hQbBsPKJ4JCP74b5nykcZ4rGDnPGwm_iO5USqol9uqm7_w7A7PhqgZow2jCK7oqkW1plEbhX5jrnvG_MPQMBhuARiHDaK_XQ3he9wD0aM_oth0NaNtLhFM45mYLBU49w_1yGsCUVeHq53m8C8MbLFK7HhqcRtbBEdWFQZ28CTF4JAuATIfDt8aGg6pa8CrZb4sNxWxr-80J1zQ6I6h86FlZh1Xg1G6O8ZcnnHI8IP0oAZ13cP-OADgm4HKZjYO27dCopWJxswwZD6gOJ2_7WOW0ybWci8ZYN9V27q-6WMuxz2nDekyW2m0aogrgmOlG7d05z0GdLiYzdUsRpEHDl3BosSq7QtHjd3bAC4a1wqeZmLSAxJYEoy0C8nnXOEftfYU9fkFttnbZc5iLXLxcGwMRFWOzMZRSa4M2xd9Mv1B59HwMgtdBeZwS5CKa4HcUVsdb4r4qQZYXe3jvqMFgNdSQ6Q3VqSO1KI&skey=9f5b077cc22e75c7&v=v13) format("woff2-variations");
  font-family: "Mulish";
  font-display: swap;
}
@font-face {
  font-style: italic;
  font-weight: 300 800;
  src: url(https://fonts.gstatic.com/l/font?kit=1Pttg83HX_SGhgqk2gotYKNnBcIIwXowwoSqOJMXDB127eOLThF2sjI86b6hTE22pCc9LVejW8ioZ6uiSXxxnnJdySu9oC49pbsy6iK28c35cpqWeGDbQGs-Kjnrv0GQsS0jIBlvc12-h1pWcwuTr2XYug-7aNZLQshhbsoRoLe7Sm8zMTaZ_irAShohGamizyAXz6K1H_E8_-Z2WuirbxhgKS9Kxw2Bnmcwt2xjjsin0bosdxWOowRjDJaMokx_k0lQaRsOKLgJC_75b578q8ZkrGbOIA5E_kDbUErKlbrImNmS6Gmtg8x7pWjBD8SKoi7Xrzl5jxcBpRWk8KyyPXQMD3XlAtDdTX6Daqxhw9Jdsa4WIdQPO2Uu9ez6O29a73SX0RNgSy7lsOOE7FBucc0o8gUIblk5JAQ0NEYU0mWnw9ZMJXV9K5t4PEvLTIrJTfVOHl3ThaZWDBKyxMHD3u0HSFA1rAPYC5U-ryGqwtKWk1AX6ZScDVSCrdSoUWxs4-wgm83r_JVSX2DCBMLd0D_07Xu0TE16Ybw229rd6gJNxVvECI5RXw3Xbe1IhAnGEtwX71Vlf9H7x7tPS_5kAjGiranJ43kknK64EF55xHv9dBSxFmU7BvjXHLyESpt8rI137YFmraC4f7ABMA2SbBBvWyjt0oQyaO1v-1ceEbwixZeTF0w-IaITbNXpTcu_eBB-R8Kbgk87d6OAk-xmrJsxbOlUe-ru_OIZlQ&skey=12f3c822011b7b1c&v=v13) format("woff2-variations");
  font-family: "MulishItalic";
  font-display: swap;
}
section.ch2 {
  margin: 0 !important;
}

html {
  font-size: 62.5%;
}
@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  html {
    font-size: 65%;
  }
}

body {
  font-family: var(--font-family);
  font-size: var(--step-0);
  font-weight: 400;
  line-height: var(--body-line-height);
  color: var(--color-shark-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  body {
    font-size: 19px;
    font-size: 1.9rem;
    line-height: 3.4rem;
  }
}

pre,
code,
kbd {
  font-family: var(--font-family-mono);
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

h1,
h2 {
  font-family: var(--font-family);
}

h2 {
  font-weight: 300;
}

h1 {
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 3.4rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 5.7rem;
    line-height: 10.2rem;
    letter-spacing: -2px;
  }
}
@media (min-width: 1280px) {
  h1 {
    font-size: 6.3rem;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 1.1;
}
@media (min-width: 768px) {
  h2 {
    font-size: 4.9rem;
  }
}
@media (min-width: 1280px) {
  h2 {
    font-size: 5.3rem;
  }
}

h3 {
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 3.4rem;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
@media (min-width: 768px) {
  h3 {
    font-size: 3.2rem;
    line-height: 6.8rem;
  }
}

h4 {
  font-size: 2rem;
  line-height: 3.4rem;
}

h5 {
  font-size: 1.7rem;
  line-height: 3.4rem;
}

h4,
h5 {
  font-weight: 600;
}

p,
ul,
ol,
table,
figure {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
@media (min-width: 0) and (max-width: 767px) {
  a.link--long-url {
    font-size: 0;
    position: relative;
  }
  a.link--long-url:before {
    position: absolute;
    content: "[link]";
    font-size: initial;
  }
}

li {
  margin-top: 0.7rem;
}
li:first-child {
  margin-top: 0;
}

ol ol {
  list-style-type: lower-alpha;
}

@media (min-width: 0) and (max-width: 767px) {
  ol,
ul {
    padding-left: 2rem;
  }
}

.pretty-text, .offers__list-item-content {
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 3rem;
}
.pretty-text h1, .offers__list-item-content h1,
.pretty-text h2,
.offers__list-item-content h2,
.pretty-text h3,
.offers__list-item-content h3,
.pretty-text h4,
.offers__list-item-content h4,
.pretty-text h5,
.offers__list-item-content h5 {
  font-weight: 600;
  letter-spacing: inherit;
}
.pretty-text h1, .offers__list-item-content h1 {
  font-size: 3.7rem;
  font-family: var(--font-family);
  font-weight: 200;
  line-height: 1.04;
  margin-bottom: 3rem;
}
.pretty-text h2, .offers__list-item-content h2 {
  font-size: 3rem;
  line-height: 1.04;
}
.pretty-text h3, .offers__list-item-content h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.pretty-text h4, .offers__list-item-content h4 {
  font-size: 1.9rem;
  line-height: 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.pretty-text h5, .offers__list-item-content h5 {
  font-size: 1.7rem;
  line-height: 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.pretty-text h4, .offers__list-item-content h4,
.pretty-text h5,
.offers__list-item-content h5 {
  font-weight: 700;
}
.pretty-text p, .offers__list-item-content p {
  margin-top: 0;
  font-size: 1.9rem;
  line-height: 3rem;
  letter-spacing: -0.003rem;
}
.pretty-text img, .offers__list-item-content img,
.pretty-text figure,
.offers__list-item-content figure,
.pretty-text ol,
.offers__list-item-content ol,
.pretty-text ul,
.offers__list-item-content ul {
  display: block;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-ms-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    -ms-transform: skewX(0.390625deg) skewY(0.390625deg);
    -o-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -ms-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -o-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@-moz-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    -ms-transform: skewX(0.390625deg) skewY(0.390625deg);
    -o-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -ms-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -o-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@-ms-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    -ms-transform: skewX(0.390625deg) skewY(0.390625deg);
    -o-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -ms-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -o-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@-o-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    -ms-transform: skewX(0.390625deg) skewY(0.390625deg);
    -o-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -ms-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -o-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    -moz-transform: skewX(0.390625deg) skewY(0.390625deg);
    -ms-transform: skewX(0.390625deg) skewY(0.390625deg);
    -o-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -moz-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -ms-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    -o-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-ms-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-ms-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-o-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-ms-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-o-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-moz-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-ms-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-o-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@-moz-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@-ms-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@-o-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-moz-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-ms-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-o-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-moz-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-ms-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-o-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-moz-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-ms-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-o-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-moz-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-ms-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-o-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-ms-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-ms-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@-ms-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-ms-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}
@-ms-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}
@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-ms-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-ms-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-ms-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
@-moz-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
@-ms-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
}
@-moz-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
}
@-ms-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
}
@-o-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
}
@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
}
@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
}
@-moz-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
}
@-ms-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
}
@-o-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
}
@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
}
@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
}
@-moz-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
}
@-ms-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
}
@-o-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
}
@keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
  }
}
@-webkit-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@-moz-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@-ms-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@-o-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@-webkit-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
}
@-moz-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
}
@-ms-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
}
@-o-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
}
@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
}
@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
}
@-moz-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
}
@-ms-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
}
@-o-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
}
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
  }
}
@-webkit-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-moz-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-ms-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-o-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-webkit-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-moz-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-ms-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-o-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-webkit-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-moz-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-ms-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-o-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-webkit-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-moz-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-ms-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-o-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-webkit-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-moz-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-ms-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-o-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-moz-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-ms-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-o-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}
@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-moz-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-ms-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-o-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-moz-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-ms-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-o-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-moz-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-ms-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-o-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-moz-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-ms-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-o-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-moz-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-ms-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-o-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-moz-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-ms-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-o-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-moz-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-ms-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-o-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-moz-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-ms-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-o-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-webkit-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
  }
}
@-moz-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
  }
}
@-ms-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
  }
}
@-o-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
  }
}
@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
  }
}
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-ms-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}
@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}
@-ms-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}
@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-ms-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@-moz-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@-ms-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@-o-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@-webkit-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@-moz-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@-ms-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@-o-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@-moz-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@-ms-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@-o-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@-webkit-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@-moz-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@-ms-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@-o-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-moz-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-ms-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-o-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
@-moz-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
@-ms-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
@-o-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
@-moz-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
@-ms-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
@-o-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
}
@-webkit-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-moz-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-ms-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@-o-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
@keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
.svg-ico__vertical-arrows, .svg-ico__twitter-float-white, .svg-ico__twitter-float, .svg-ico__twitter-circle, .team-member__link-ico--twitter, .footer .footer-social-mo__ico--twitter, .svg-ico__twitter-black, .svg-ico__twitter, .svg-ico__tutorials, .svg-ico__stackoverflow, .svg-ico__menu-dark, .svg-ico__menu, .svg-ico__localization, .svg-ico__linkedin-circle, .team-member__link-ico--linkedin, .footer .footer-social-mo__ico--linkedin, .svg-ico__issue-tracker, .svg-ico__instagram-circle, .footer .footer-social-mo__ico--instagram, .svg-ico__instagram-black, .svg-ico__instagram, .svg-ico__ideas, .svg-ico__hamburger, .svg-ico__guildes, .svg-ico__guides, .svg-ico__google-circle, .team-member__link-ico--g-plus, .footer .footer-social-mo__ico--g-plus, .svg-ico__gitter, .svg-ico__github-circle, .team-member__link-ico--github, .svg-ico__github, .svg-ico__g-plus-float-white, .svg-ico__g-plus-float, .svg-ico__g-plus-black, .svg-ico__g-plus, .svg-ico__flat-twitter, .svg-ico__flat-linkedin, .svg-ico__flat-github, .svg-ico__fb-float-white, .svg-ico__fb-float, .svg-ico__fb-black, .svg-ico__fb, .svg-ico__faq, .svg-ico__facebook-circle, .footer .footer-social-mo__ico--fb, .svg-ico__examples, .svg-ico__dot, .svg-ico__close-white, .svg-ico__check, .svg-ico__arrow-right-mo, .svg-ico__arrow-right-blue, .svg-ico__arrow-right-36, .svg-ico__arrow-right, .svg-ico__arrow-left-blue, .svg-ico__arrow-left, .svg-ico__api {
  background: url("../images/vector.svg") no-repeat;
}

.svg-ico__api {
  background-position: 71.546961326% 26.7477203647%;
  width: 84px;
  height: 84px;
}

.svg-ico__arrow-left {
  background-position: 33.3333333333% 73.8764044944%;
  width: 38px;
  height: 57px;
}

.svg-ico__arrow-left-blue {
  background-position: 42.6470588235% 73.8764044944%;
  width: 38px;
  height: 57px;
}

.svg-ico__arrow-right {
  background-position: 0 100%;
  width: 34px;
  height: 44px;
}

.svg-ico__arrow-right-36 {
  background-position: 61.2745098039% 73.6694677871%;
  width: 38px;
  height: 56px;
}

.svg-ico__arrow-right-blue {
  background-position: 51.9607843137% 73.8764044944%;
  width: 38px;
  height: 57px;
}

.svg-ico__arrow-right-mo {
  background-position: 17.8313253012% 98.6631016043%;
  width: 31px;
  height: 39px;
}

.svg-ico__check {
  background-position: 70.4433497537% 87.3350923483%;
  width: 40px;
  height: 34px;
}

.svg-ico__close-white {
  background-position: 25.7352941176% 98.4%;
  width: 38px;
  height: 38px;
}

.svg-ico__dot {
  background-position: 8.3743842365% 98.927613941%;
  width: 40px;
  height: 40px;
}

.svg-ico__examples {
  background-position: 0 54.4072948328%;
  width: 84px;
  height: 84px;
}

.svg-ico__facebook-circle, .footer .footer-social-mo__ico--fb {
  background-position: 87.7237851662% 15.3631284916%;
  width: 55px;
  height: 55px;
}

.svg-ico__faq {
  background-position: 23.2044198895% 54.4072948328%;
  width: 84px;
  height: 84px;
}

.svg-ico__fb {
  background-position: 100% 0;
  width: 47px;
  height: 45px;
}

.svg-ico__fb-black {
  background-position: 100% 12.2282608696%;
  width: 47px;
  height: 45px;
}

.svg-ico__fb-float {
  background-position: 0 76.231884058%;
  width: 68px;
  height: 68px;
}

.svg-ico__fb-float-white {
  background-position: 17.9894179894% 76.231884058%;
  width: 68px;
  height: 68px;
}

.svg-ico__flat-github {
  background-position: 34.8780487805% 97.8779840849%;
  width: 36px;
  height: 36px;
}

.svg-ico__flat-linkedin {
  background-position: 43.6585365854% 97.8779840849%;
  width: 36px;
  height: 36px;
}

.svg-ico__flat-twitter {
  background-position: 52.4390243902% 97.8779840849%;
  width: 36px;
  height: 36px;
}

.svg-ico__g-plus {
  background-position: 0 88.2666666667%;
  width: 49px;
  height: 38px;
}

.svg-ico__g-plus-black {
  background-position: 12.3425692695% 88.2666666667%;
  width: 49px;
  height: 38px;
}

.svg-ico__g-plus-float {
  background-position: 24.685138539% 88.2666666667%;
  width: 49px;
  height: 38px;
}

.svg-ico__g-plus-float-white {
  background-position: 37.0277078086% 88.2666666667%;
  width: 49px;
  height: 38px;
}

.svg-ico__github {
  background-position: 25.138121547% 0;
  width: 84px;
  height: 90px;
}

.svg-ico__github-circle, .team-member__link-ico--github {
  background-position: 87.9487179487% 0;
  width: 56px;
  height: 55px;
}

.svg-ico__gitter {
  background-position: 46.408839779% 54.4072948328%;
  width: 84px;
  height: 84px;
}

.svg-ico__google-circle, .team-member__link-ico--g-plus, .footer .footer-social-mo__ico--g-plus {
  background-position: 87.7237851662% 30.7262569832%;
  width: 55px;
  height: 55px;
}

.svg-ico__guides {
  background-position: 0 28%;
  width: 84px;
  height: 88px;
}

.svg-ico__guildes {
  background-position: 23.2044198895% 28%;
  width: 84px;
  height: 88px;
}

.svg-ico__hamburger {
  background-position: 79.9019607843% 86.8766404199%;
  width: 38px;
  height: 32px;
}

.svg-ico__ideas {
  background-position: 0 0;
  width: 91px;
  height: 91px;
}

.svg-ico__instagram {
  background-position: 100% 24.5231607629%;
  width: 47px;
  height: 46px;
}

.svg-ico__instagram-black {
  background-position: 100% 37.0572207084%;
  width: 47px;
  height: 46px;
}

.svg-ico__instagram-circle, .footer .footer-social-mo__ico--instagram {
  background-position: 87.2773536896% 46.0893854749%;
  width: 53px;
  height: 55px;
}

.svg-ico__issue-tracker {
  background-position: 46.408839779% 28%;
  width: 84px;
  height: 88px;
}

.svg-ico__linkedin-circle, .team-member__link-ico--linkedin, .footer .footer-social-mo__ico--linkedin {
  background-position: 87.7237851662% 61.4525139665%;
  width: 55px;
  height: 55px;
}

.svg-ico__localization {
  background-position: 48.3425414365% 0;
  width: 84px;
  height: 90px;
}

.svg-ico__menu {
  background-position: 48.8778054863% 88.0319148936%;
  width: 45px;
  height: 37px;
}

.svg-ico__menu-dark {
  background-position: 60.0997506234% 88.0319148936%;
  width: 45px;
  height: 37px;
}

.svg-ico__stackoverflow {
  background-position: 71.546961326% 0;
  width: 84px;
  height: 88px;
}

.svg-ico__tutorials {
  background-position: 69.6132596685% 54.4072948328%;
  width: 84px;
  height: 84px;
}

.svg-ico__twitter {
  background-position: 100% 49.0566037736%;
  width: 47px;
  height: 42px;
}

.svg-ico__twitter-black {
  background-position: 100% 60.3773584906%;
  width: 47px;
  height: 42px;
}

.svg-ico__twitter-circle, .team-member__link-ico--twitter, .footer .footer-social-mo__ico--twitter {
  background-position: 87.7237851662% 76.8156424581%;
  width: 55px;
  height: 55px;
}

.svg-ico__twitter-float {
  background-position: 100% 71.6981132075%;
  width: 47px;
  height: 42px;
}

.svg-ico__twitter-float-white {
  background-position: 100% 83.0188679245%;
  width: 47px;
  height: 42px;
}

.svg-ico__vertical-arrows {
  background-position: 71.8204488778% 73.4636871508%;
  width: 45px;
  height: 55px;
}

.ico, .team-member__link-ico {
  display: inline-block;
  vertical-align: middle;
}

.footer {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: var(--color-seal-2);
  background-color: var(--color-shark-1);
  background-repeat: no-repeat;
  background-size: auto 100%;
}
@media (min-width: 768px) {
  .footer {
    font-size: 1.6rem;
    background-position-x: -223px;
    background-size: auto 463px;
  }
}
@media (min-width: 1280px) {
  .footer {
    background-position-x: -158px;
  }
}
.footer .footer-main {
  width: 100%;
}
.footer .footer__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
@media (min-width: 768px) {
  .footer .footer__info {
    align-items: flex-start;
    gap: 48px;
  }
}
.footer-content__wrapper {
  width: 100%;
  padding: 0 2.5rem;
}
@media (min-width: 768px) {
  .footer-content__wrapper {
    display: inline-block;
    width: 100%;
  }
}
.footer-main {
  display: flex;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .footer-main {
    text-align: right;
  }
}
@media (min-width: 0) and (max-width: 767px) {
  .footer-main {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-main.section-container {
    width: 100%;
    padding: 0 2.5rem;
  }
}
.footer-main h2 {
  text-align: left;
  color: var(--color-seal-1);
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 0) and (max-width: 767px) {
  .footer-main h2 {
    display: none;
  }
}
.footer-main h2:after {
  display: none;
}
.footer__column {
  vertical-align: top;
}
@media (min-width: 768px) {
  .footer__column {
    display: inline-block;
    margin-right: 2.5rem;
    text-align: left;
  }
  .footer__column:nth-of-type(1) {
    min-width: 19rem;
  }
  .footer__column:nth-of-type(1), .footer__column:nth-of-type(2) {
    float: left;
  }
  .footer__column:nth-of-type(3), .footer__column:nth-of-type(4) {
    margin-right: 0;
    padding-left: 2.5rem;
  }
  .footer__column:nth-of-type(2) {
    margin-left: auto;
  }
  .footer__column:nth-of-type(3) {
    margin-right: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .footer__column {
    margin-right: 10rem;
  }
}
.footer__column h3 {
  color: var(--color-seal-1);
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 1.6rem;
}
.footer__column h3 + p {
  margin-top: 0;
}
@media (min-width: 0) and (max-width: 767px) {
  .footer__address {
    text-align: center;
    margin-top: 2.5rem;
  }
}
.footer__address a {
  color: var(--color-seal-2);
}
.footer__address a:hover {
  color: var(--color-seal-1);
}
.footer-menu,
.footer-menu ul, .footer-socials,
.footer-socials ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-menu a, .footer-socials a {
  color: var(--color-seal-2);
}
.footer-menu a:hover, .footer-socials a:hover {
  color: var(--color-seal-1);
}
@media (min-width: 0) and (max-width: 767px) {
  .footer-socials {
    display: none;
  }
}
.footer .footer-social-mo {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .footer .footer-social-mo {
    display: none;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .footer .footer-social-mo {
    text-align: inherit;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
  }
}
.footer .footer-social-mo__ico {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}
.footer .footer-social-mo__ico:last-of-type {
  margin-right: 0;
}
@media (min-width: 1024px) {
  .footer .footer-social-mo__ico--small {
    transform: scale(0.8);
  }
}
.footer-menu__item a[target=_blank] {
  position: relative;
}
.footer-menu__item a[target=_blank]:after {
  content: url("data:image/svg+xml;utf8,<svg fill='none' stroke='#dbdcdc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='16' height='16' xmlns='http://www.w3.org* 0.5000/svg'><path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'/><polyline points='15 3 21 3 21 9'/><line x1='10' x2='21' y1='14' y2='3'/></svg>");
  position: absolute;
  right: -23px;
  top: 0;
  display: block;
}
@media (min-width: 0) and (max-width: 767px) {
  .footer-menu__list {
    width: 100%;
  }
  .footer-menu__item {
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-shark-4);
  }
}
.footer-policy {
  display: inline-block;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .footer-policy {
    font-size: 1.4rem;
  }
}
@media (min-width: 1280px) {
  .footer-policy {
    text-align: inherit;
  }
}
.footer-policy__rights, .footer-policy__menu {
  text-align: center;
}
@media (min-width: 1024px) {
  .footer-policy__rights, .footer-policy__menu {
    text-align: inherit;
  }
}
.footer-policy__rights {
  margin: 0;
  color: var(--color-seal-2);
}
.footer-policy__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .footer-policy__menu {
    float: right;
  }
}
.footer-policy__menu li {
  display: inline-block;
  margin-right: 1.25rem;
}
.footer-policy__menu li:last-of-type {
  margin-right: 0;
}
.footer-policy__menu li a {
  color: var(--color-seal-2);
}

#chevrony {
  height: 1720px;
  width: auto;
  transform: scale(1.2);
}

.header {
  position: relative;
  height: 240px;
  background-attachment: fixed;
  background-size: auto 240px;
  background-color: var(--color-shark-1);
  background-repeat: no-repeat;
  background-position: center top;
  color: var(--color-seal-1);
  overflow: hidden;
}
.header .header-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.5rem;
  width: 100%;
  z-index: 999;
}
@media (min-width: 768px) {
  .header .header-wrapper {
    bottom: 65px;
  }
}
.header .header-wrapper .header-wrapper-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}
@media (min-width: 768px) {
  .header .header-wrapper .header-wrapper-inner {
    flex-direction: row;
  }
}
.header .header-wrapper .header-gptw {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 77px;
  height: 129px;
  transition: 0.25s ease-in-out filter;
  background-image: url(../images/icon/gptw.svg);
}
@media (min-width: 768px) {
  .header .header-wrapper .header-gptw {
    width: 95px;
    height: 160px;
    align-self: flex-end;
  }
}
.header .header-wrapper .header-gptw:hover {
  filter: brightness(1.1);
}
.header .header-wrapper .header-wrapper-text {
  max-width: 760px;
}
@media (min-width: 768px) {
  .header .header-wrapper .header-wrapper-text {
    margin-bottom: 70px;
  }
}
.header .header-wrapper h1 {
  position: relative;
  padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .header .header-wrapper h1 {
    padding-bottom: 25px;
    line-height: 1.05;
  }
}
@media (min-width: 1280px) {
  .header .header-wrapper h1 {
    padding-bottom: 30px;
    line-height: 7.5rem;
  }
}
.header picture img {
  min-width: 100%;
  height: 240px;
  object-position: center center;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) {
  .header picture img {
    height: 500px;
  }
}
@media (min-width: 1280px) {
  .header picture img {
    height: auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header {
    height: 500px;
  }
}
.header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: color-mix(in srgb, var(--color-shark-1) 10%, var(--color-mix-base, transparent));
}
.header-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 238px;
  height: 500px;
  opacity: 1;
  z-index: 99;
  transition: all 200ms ease-in-out;
}
@media (min-width: 768px) {
  .header-cover {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.header-cover::after {
  content: "";
  height: 200%;
  width: 200%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-tang-6);
  clip-path: url(#chevrony);
  opacity: 0.4;
  transform: translate(-649px, -300px);
}
.header-cover::before {
  content: "";
  height: 200%;
  width: 200%;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  background: var(--color-tang-5);
  clip-path: url(#chevrony);
  opacity: 0.65;
  transform: translate(-557px, -351px);
}
@media (min-width: 768px) {
  .header--big {
    height: 500px;
  }
}
@media (min-width: 1280px) {
  .header--big {
    height: 720px;
    background-size: 1920px 720px;
  }
  .header--big .header-cover {
    height: 720px;
  }
  .header--big .header-content {
    width: 520px;
    bottom: 110px;
  }
}
@media (min-width: 768px) {
  .header--about-us .header-content {
    padding-right: calc(10vw + 151px);
  }
}
@media (min-width: 1680px) {
  .header--about-us .header-content {
    padding-right: initial;
  }
}
.header--about-us, .header--careers {
  z-index: 2;
  height: 360px;
}
@media (min-width: 768px) {
  .header--about-us, .header--careers {
    height: 500px;
  }
}
.header--about-us .header-cover, .header--careers .header-cover {
  background-image: none;
}
@media (min-width: 0) and (max-width: 767px) {
  .header--about-us .header-cover, .header--careers .header-cover {
    width: 100%;
    height: 360px;
    background-color: rgba(0, 0, 0, 0.35);
    background-size: 360px;
    background-position-y: bottom;
  }
}
@media (min-width: 0) and (max-width: 767px) {
  .header--about-us .header-content, .header--careers .header-content {
    bottom: 50%;
    transform: translateY(50%);
  }
}
.header--about-us picture img, .header--careers picture img {
  height: 360px;
}
@media (min-width: 768px) {
  .header--about-us picture img, .header--careers picture img {
    height: 500px;
  }
}
@media (min-width: 1280px) {
  .header--about-us picture img, .header--careers picture img {
    height: auto;
  }
}
@media (min-width: 1280px) {
  .header--about-us picture img {
    height: 500px;
    width: 100%;
  }
}
.header .btn.btn-cta--long {
  background-color: var(--color-seal-1);
  color: var(--color-shark-1);
}
.header .btn.btn-cta--long:hover {
  background-color: var(--color-seal-1);
  color: var(--color-tang-5);
}

.header--left {
  text-align: left;
}

.header--center {
  text-align: center;
}

.header-separator--big {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .header-separator--big {
    margin-bottom: 32px;
  }
}

.header-about-us {
  font-weight: 300;
  line-height: 1.6;
  padding-bottom: 0;
}

.header-neo.header--home {
  background: var(--color-seal-1);
  color: var(--color-shark-1);
  top: var(--menu-height);
  height: 75vw;
  margin-bottom: calc(var(--menu-height) * 1.1 - 11px);
}
@media (width >= 48rem) {
  .header-neo.header--home {
    height: 800px;
  }
}
.header-neo.header--home::after {
  content: none;
  display: none;
}
.header-neo.header--home h1 {
  font-size: calc(var(--step-4) * 1.3);
  padding-bottom: 0;
  line-height: 1.1;
  color: var(--color-shark-1);
  text-wrap-style: balance;
  text-align: center;
  font-weight: 800;
}
.header-neo.header--home h1::after {
  display: none !important;
}
@media (width >= 28rem) {
  .header-neo.header--home h1 {
    font-size: calc(var(--step-4) * 1.5);
  }
}
@media (width >= 48rem) {
  .header-neo.header--home h1 {
    font-size: calc(var(--step-4) * 2.1);
  }
}
.header-neo.header--home .header-wrapper {
  bottom: unset;
  width: 100vw;
  height: 100%;
}
.header-neo.header--home .header-wrapper-inner {
  height: 100%;
}
.header-neo.header--home .header-wrapper-decorators {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
}
@media (width >= 48rem) {
  .header-neo.header--home .header-wrapper-decorators {
    padding-bottom: var(--space-3xl);
  }
}
.header-neo.header--home .header-wrapper-text {
  margin: 0 auto;
  padding: calc(var(--space-s) * 1.5) calc(var(--space-m) * 1.5) 0;
  height: 100%;
}
@media (width >= 28rem) {
  .header-neo.header--home .header-wrapper-text {
    padding: calc(var(--space-l) * 1.5) calc(var(--space-m) * 1.5) 0;
  }
}
@media (width >= 48rem) {
  .header-neo.header--home .header-wrapper-text {
    padding: calc(var(--space-2xl) * 1.3) calc(var(--space-m) * 1.5) 0;
  }
}
.header-neo.header--home .header-wrapper-ctas {
  justify-content: center;
  position: relative;
  padding-top: var(--space-s);
}
@media (width >= 48rem) {
  .header-neo.header--home .header-wrapper-ctas {
    padding-top: var(--space-3xl);
  }
}
.header-neo.header--home .c-sprite-icon.c-sprite-icon--deco-large-hex {
  --c-sprite-stroke-width: 1.5px;
  color: var(--color-seal-1);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  height: 814px;
  filter: drop-shadow(0 14px 31px rgba(0, 0, 0, 0.05)) drop-shadow(0 56px 56px rgba(0, 0, 0, 0.04)) drop-shadow(0 126px 76px rgba(0, 0, 0, 0.02)) drop-shadow(0 224px 90px rgba(0, 0, 0, 0.011));
  transform: translate(-50%, calc(-45% - clamp(144px, 43vw, 117px)));
  min-width: 360px;
}
@media (width >= 28rem) {
  .header-neo.header--home .c-sprite-icon.c-sprite-icon--deco-large-hex {
    transform: translate(-50%, calc(-50% - clamp(131px, 42vw, 153px)));
    bottom: 98px;
  }
}
@media (width >= 48rem) {
  .header-neo.header--home .c-sprite-icon.c-sprite-icon--deco-large-hex {
    height: 1020px;
    bottom: 0;
    transform: translateX(-50%) translateY(-50%);
    top: calc(50% - 238px);
  }
}
.header-neo.header--home svg.c-sprite-icon.c-sprite-icon--deco-multi-hex {
  --c-sprite-icon-height: var(, var(--space-l));
  --c-sprite-icon-width: 80vw;
  --c-sprite-icon-height: 50vh;
  transform: translateX(-50%);
  position: relative;
  left: calc(50% - 33px);
  width: calc(var(--menu-height) * 13.89);
  height: 100%;
  bottom: 0;
  opacity: 0.5;
}
@media (width >= 48rem) {
  .header-neo.header--home svg.c-sprite-icon.c-sprite-icon--deco-multi-hex {
    left: calc(50% - 37px);
    transform: translateX(-50%) translateY(83px);
  }
}

body:has(.header-neo) {
  --menu-height: 111px;
  overflow-x: hidden;
}
body:has(.header-neo) .menu {
  padding-top: calc(var(--space-m) * 1.5);
  top: 0;
  background-color: color-mix(in srgb, var(--color-seal-1) 80%, var(--color-mix-base, transparent));
  height: var(--menu-height);
}
body:has(.header-neo) .menu::after {
  position: absolute;
  width: 100vw;
  height: 2px;
  content: "";
  display: block;
  bottom: -2px;
  left: 0;
  background: var(--gradient-neo);
}
body:has(.header-neo) .menu .cksource-logo {
  color: var(--color-tropical-7);
}
body:has(.header-neo) .menu-navigation__item a {
  color: var(--color-shark-1);
}
body:has(.header-neo) .menu-navigation__item a:after {
  transition: all 0.3s ease;
}
body:has(.header-neo) .menu-navigation__item a:hover {
  color: var(--color-tang-6);
}
body:has(.header-neo) .menu-navigation__socials {
  transform: translateY(var(--space-xs));
}

.header.header--careers picture img,
.header.header--team picture img {
  max-width: 100%;
}

@media (min-width: 1280px) {
  .header.header--team picture img {
    transform: translateY(-40%);
  }

  .header.header--careers picture img {
    transform: translateY(-24%);
  }
}
section,
.section {
  display: inline-block;
  width: 100%;
  padding-top: 2.5rem;
}
@media (min-width: 768px) {
  section,
.section {
    padding-top: 5rem;
  }
}
section:last-of-type,
.section:last-of-type {
  padding-bottom: 5rem;
}
section.ch2,
.section.ch2 {
  display: initial;
}

.section-header {
  margin-bottom: 2.5rem;
}
.section-container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1280px) {
  .section-container {
    max-width: 1250px;
  }
}
@media (min-width: 1680px) {
  .section-container {
    max-width: 1440px;
  }
}
.section-relative {
  position: relative;
  width: 100%;
}
@media (min-width: 1280px) {
  .section-relative {
    max-width: 1250px;
  }
}
@media (min-width: 1680px) {
  .section-relative {
    max-width: 1440px;
  }
}

header,
main,
footer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

.accordion__item {
  position: relative;
  border-radius: 4px;
}
.accordion__item--hide {
  display: none;
}
.accordion__item header {
  position: relative;
  transition: border 200ms ease-in-out;
}
.accordion__item header:hover, .accordion__item header:focus {
  outline: none;
}
@media (min-width: 768px) {
  .accordion__item header:hover::before, .accordion__item header:focus::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 24px solid var(--color-tang-5);
    border-right: 24px solid transparent;
  }
}
.accordion__item header::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  opacity: 0.5;
  transform: translateY(-50%);
  transition: all 200ms ease-in-out;
  background: var(--color-tang-6);
  clip-path: polygon(50% 10%, 70% 10%, 40% 50%, 70% 90%, 50% 90%, 20% 50%);
  transform: translateY(-50%) rotate(180deg);
  width: 25px;
  height: 32px;
}
.accordion__item--active header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 24px solid var(--color-tropical-7);
  border-right: 24px solid transparent;
}
.accordion__item--active header::after {
  transition: transform 0.25s ease;
  transform: rotate(90deg) translateX(-50%);
  transform-origin: center;
}
.accordion__item--active .offers__list-item-salary--big {
  opacity: 0;
}
.accordion__item.is-expanded header::after {
  transition: transform 0.25s ease;
  transform: translateY(-50%) rotate(90deg);
}

@media (min-width: 1280px) {
  .accordion__item-content {
    display: flex;
  }
}

.cta {
  position: relative;
}
@media (min-width: 1024px) {
  .cta {
    --b-cta--height: 295px;
    height: calc(var(--b-cta--height) + var(--space-3xl));
  }
}
.site-content--careers .cta {
  background-color: var(--color-seal-1);
}
.cta, .cta:last-of-type {
  padding-bottom: 0;
}
.cta__yellow-part h2 {
  text-align: center;
  font-weight: 400;
  line-height: var(--headline-line-height);
  color: var(--color-seal-1);
}
.cta__yellow-part h2:after {
  display: none;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .cta__yellow-part h2 {
    font-size: 4rem;
    padding-bottom: 1rem;
  }
}
@media (min-width: 1024px) {
  .cta__yellow-part {
    position: absolute;
    height: var(--b-cta--height);
    width: calc(100% - 320px);
    left: 0;
    z-index: 2;
  }
  .cta__yellow-part h2 {
    position: absolute;
    top: 50%;
    left: 2.5rem;
    text-align: inherit;
    transform: translateY(-50%);
    font-size: 4rem;
    margin-right: 100px;
  }
  .cta__yellow-part h2:after {
    display: block;
    background-color: var(--color-tang-5);
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .cta__yellow-part h2 {
    left: 5rem;
  }
}
@media (min-width: 1024px) and (min-width: 1280px) {
  .cta__yellow-part h2 {
    left: 150px;
  }
}
@media (min-width: 1024px) and (min-width: 1680px) {
  .cta__yellow-part h2 {
    left: 200px;
  }
}
@media (min-width: 1280px) {
  .cta__yellow-part {
    width: calc(100% - 430px);
  }
  .cta__yellow-part h2 {
    font-size: 4.3rem;
  }
}
@media (min-width: 1680px) {
  .cta__yellow-part {
    width: 66%;
  }
}
.cta__yellow-part-wrapper {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}
@media (min-width: 1024px) {
  .cta__yellow-part-wrapper {
    padding-right: 60px;
  }
}
.cta__yellow-part-inner {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 5rem 1rem;
  background-color: var(--color-tang-5);
}
.cta__yellow-part-inner .btn {
  display: block;
  margin: 2.5rem auto 0;
  color: var(--color-shark-1);
  background-color: var(--color-comet-5);
  border: none;
  width: max-content;
  padding-top: 11px;
  padding-bottom: 14px;
}
@media (min-width: 1024px) {
  .cta__yellow-part-inner .btn {
    display: none;
  }
}
.cta__orange-part {
  display: none;
  position: absolute;
  height: 295px;
  width: 420px;
  right: 0;
  z-index: 999;
}
@media (min-width: 1024px) {
  .cta__orange-part {
    display: block;
  }
}
@media (min-width: 1280px) {
  .cta__orange-part {
    width: 520px;
  }
}
@media (min-width: 1680px) {
  .cta__orange-part {
    width: 40%;
  }
}
.cta__orange-part-wrapper {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}
.cta__orange-part-inner {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-color: var(--color-tang-5);
}
.cta__orange-part .btn {
  position: absolute;
  top: 50%;
  left: 150px;
  color: var(--color-shark-1);
  background-color: var(--color-comet-5);
  border: none;
  padding-top: 20px;
  padding-bottom: 21px;
  font-size: 2.1rem;
  font-weight: 800;
  transform: translateY(-50%);
  padding: var(--space-s) var(--space-m);
}
@media (min-width: 1280px) {
  .cta__orange-part .btn {
    left: 50%;
    font-size: 2.3rem;
    transform: translate(-50%, -50%);
  }
}
@media (width >= 108rem) {
  .cta__orange-part .btn {
    padding: var(--space-m) var(--space-xl);
  }
}
.cta__orange-part .btn:hover {
  background-color: var(--color-seal-1);
  color: var(--color-tang-5);
}
.cta__actions {
  margin-top: 2.5rem;
}
.cta--2 .btn {
  margin-right: 2.5rem;
}
.cta--2 .btn:last-of-type {
  margin-right: 0;
}
.cta p {
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.features-grid {
  position: relative;
  height: 900px;
  width: 100%;
  max-width: 100%;
  margin: 2.5rem auto 10rem;
  transition: all 200ms ease-in-out;
}
@media (min-width: 768px) {
  .features-grid {
    height: 700px;
  }
}
@media (min-width: 1280px) {
  .features-grid {
    height: 750px;
    width: 90%;
    max-width: 1440px;
  }
}
.features-grid--big {
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .features-grid--big {
    height: 750px;
  }
}
@media (min-width: 1280px) {
  .features-grid--big {
    height: 850px;
  }
}
.features-grid__wrapper h2 {
  text-align: center;
}
.features-grid__col {
  position: absolute;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  transition: all 300ms ease-out;
}
.features-grid__col .features-grid__item {
  left: 5px;
  right: 5px;
}
.features-grid__col:first-of-type .features-grid__item--full, .features-grid__col:first-of-type .features-grid__item--half, .features-grid__col:first-of-type .features-grid__item--third {
  left: 10px;
}
.features-grid__col:last-of-type .features-grid__item--full, .features-grid__col:last-of-type .features-grid__item--half, .features-grid__col:last-of-type .features-grid__item--third {
  right: 10px;
}
.features-grid__col--left, .features-grid__col--middle, .features-grid__col--right {
  width: 100%;
}
@media (min-width: 768px) {
  .features-grid__col--left, .features-grid__col--middle, .features-grid__col--right {
    width: 33.3333%;
  }
  .features-grid__col--left {
    left: 0;
  }
  .features-grid__col--middle {
    left: 33.3333%;
  }
  .features-grid__col--right {
    left: 66.6666%;
  }
}
@media (min-width: 768px) {
  .features-grid--irregular .features-grid__col--left {
    width: 50%;
  }
  .features-grid--irregular .features-grid__col--middle {
    width: 25%;
    left: 50%;
  }
  .features-grid--irregular .features-grid__col--right {
    width: 25%;
    left: 75%;
  }
}
.features-grid__item {
  position: absolute;
}
.features-grid__item--full {
  top: 10px;
  bottom: 10px;
}
.features-grid__item--full .features-grid__item-description {
  bottom: 10%;
  top: auto;
  transform: none;
}
.features-grid__item--full .features-grid__item-description p {
  font-size: inherit;
}
.features-grid__item--half:first-of-type {
  top: 10px;
  bottom: 50%;
}
.features-grid__item--half:first-of-type .features-grid__item-inner {
  bottom: 5px;
}
.features-grid__item--half:last-of-type {
  top: 50%;
  bottom: 10px;
}
.features-grid__item--half:last-of-type .features-grid__item-inner {
  top: 5px;
}
.features-grid__item--third:first-of-type {
  top: 10px;
  bottom: 66.6666%;
}
.features-grid__item--third:first-of-type .features-grid__item-inner {
  bottom: 5px;
}
.features-grid__item--third:nth-of-type(2) {
  top: 33.3333%;
  bottom: 33.3333%;
}
.features-grid__item--third:nth-of-type(2) .features-grid__item-inner {
  top: 5px;
  bottom: 5px;
}
.features-grid__item--third:last-of-type {
  top: 66.6666%;
  bottom: 10px;
}
.features-grid__item--third:last-of-type .features-grid__item-inner {
  top: 5px;
}
.features-grid__item-description {
  padding: 2.5rem;
  width: 100%;
  background-color: color-mix(in srgb, var(--color-shark-1) 40%, var(--color-mix-base, transparent));
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  transition: all 200ms ease-in-out;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.05);
}
.features-grid__item-description p {
  color: var(--color-seal-1);
  transition: all 500ms ease-in-out;
  text-align: center;
  margin: 0;
  font-size: 1.6rem;
}
.features-grid__item-inner, .features-grid__item-cover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.features-grid__item-cover {
  margin: 0;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-tang-5);
  transition: transform 300ms ease-out, filter 300ms ease-out;
}
.features-grid__item-inner {
  overflow: hidden;
}

.form-response {
  display: none;
  text-align: center;
  padding: 2.5rem;
  margin: 2.5rem;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: var(--space-3xs);
  background-color: color-mix(in srgb, var(--color-seal-1) 0.8, var(--color-mix-base, transparent));
}
.form-response:before {
  content: "";
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-right: 2.5rem;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
}
.form-response--error {
  color: var(--color-tropical-4);
  border-color: var(--color-tropical-4);
}
.form-response--error:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org* 0.5000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%23D9534F' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm56.6 207.2c-6 6-15.7 6-21.7 0L150.7 173 115 208.7c-6 6-15.6 6-21.6 0-6-6-6-15.7 0-21.7l35.7-35.6L96 118c-6-6-6-15.6 0-21.5 6-6 15.6-6 21.6 0l33.2 33.2L182.4 98c6-6 15.7-6 21.7 0s6 15.7 0 21.7l-31.5 31.7 34 34c6 6 6 15.8 0 21.8z'/%3E%3C/svg%3E%0A");
}
.form-response--success {
  color: var(--color-comet-6);
  border-color: var(--color-comet-6);
}
.form-response--success:before {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org* 0.5000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%235CB85C' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm73.4 128.8l-76.7 69.5c-2 1.7-4 2.8-6.3 3.4-2 1-4.2 1.4-6.3 1.4-3.7 0-7.5-1.3-10.5-4l-47.2-45c-6-5.8-6.4-15.5-.5-21.7 5.8-6 15.5-6.4 21.7-.5l37.5 35.6 67.8-61.3c6.3-5.6 16-5 21.7 1.2 5.7 6.2 5.2 16-1 21.6z'/%3E%3C/svg%3E%0A");
}
.form-response--active {
  display: block;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -ms-animation-duration: 300ms;
  -o-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.squares__container {
  display: flex;
  width: 940px;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1280px) {
  .squares__container {
    justify-content: flex-start;
    width: 1250px;
  }
}
@media (min-width: 1680px) {
  .squares__container {
    width: 1560px;
  }
}
.squares__item {
  position: relative;
  width: 100%;
  max-width: 151px;
  color: white;
  flex: 1 0 auto;
  height: auto;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .squares__item {
    max-width: 302px;
  }
}
.squares__item:before {
  content: "";
  display: table;
  padding-top: 100%;
}

.module-subscribe {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: color-mix(in srgb, var(--color-comet-6) 0.25, var(--color-mix-base, transparent));
}
.module-subscribe__actions {
  margin-top: 2.5rem;
}
.module-subscribe:not(.module-subscribe--plain) h2 {
  font-size: 3.7rem;
  line-height: 5rem;
}
.module-subscribe p,
.module-subscribe h2 {
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.module-subscribe--plain {
  background-color: transparent;
  padding-top: 0;
}
.module-subscribe--plain p,
.module-subscribe--plain h2 {
  width: 680px;
}

.support__tiles {
  display: flex;
  flex-wrap: wrap;
}
.support__tiles-item {
  position: relative;
  width: 340px;
  max-width: 90%;
  color: inherit;
  padding: 2.5rem;
  text-align: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .support__tiles-item {
    width: 33.3333%;
  }
}
@media (min-width: 1680px) {
  .support__tiles-item {
    width: 25%;
  }
}
.support__tiles-item .support__ico-item {
  transition: all 200ms ease-in-out;
}
.support__tiles-item:hover, .support__tiles-item:focus {
  background-color: var(--color-seal-2);
  text-decoration: none;
  outline: none;
  color: inherit;
}
.support__tiles-item:hover .support__ico-item, .support__tiles-item:focus .support__ico-item {
  transform: scale(1.2);
}
.support__ico-item {
  display: block;
  margin: 0 auto;
}

.swal2-modal {
  font-family: inherit;
}
.swal2-modal .swal2-title {
  font-weight: 200;
  font-size: 3.7rem;
}
.swal2-modal label {
  display: block;
  text-align: left;
  padding-left: 12px;
}

.form-control[readonly] {
  background-color: color-mix(in srgb, var(--color-shark-1) 0.03, var(--color-mix-base, transparent));
}

.vis-timeline {
  font-family: var(--font-family);
  font-size: 12pt;
  border: none;
}
.vis-item {
  border-color: color-mix(in srgb, var(--color-tang-5) 0.5, var(--color-mix-base, transparent));
  border-width: 2px;
  background-color: var(--color-seal-1);
  font-size: 1.6rem;
  color: var(--color-shark-1);
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
}
.vis-item.vis-box {
  transform: translateX(35%);
}
.vis-item.vis-selected {
  border-color: color-mix(in srgb, var(--color-tang-5) 0.5, var(--color-mix-base, transparent));
  background-color: var(--color-tang-5);
}
.vis-item.vis-dot {
  border-width: 10px;
  border-radius: 10px;
}
.vis-item.vis-line {
  border-width: 2px;
}
.vis-time-axis .vis-text {
  color: var(--color-shark-1);
  padding-top: 10px;
  padding-left: 10px;
}
.vis-time-axis .vis-text.vis-major {
  font-weight: bold;
}
.vis-time-axis .vis-grid.vis-minor {
  border-width: 2px;
  border-color: var(--color-shark-5);
}
.vis-time-axis .vis-grid.vis-major {
  border-width: 2px;
  border-color: var(--color-shark-5);
}

.module-trustedby {
  display: inline-block;
  width: 100%;
}
@media (min-width: 1024px) {
  .module-trustedby {
    padding-top: 295px;
  }
}
.module-trustedby__white-inner {
  display: inline-block;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 7.5rem;
  background-color: var(--color-seal-1);
  background-image: url(../images/bg/trusted-by-mo.svg);
  background-repeat: no-repeat;
}
.module-trustedby__white-inner::after {
  content: "";
  background-image: url(../images/bg/trusted-by-mo.svg);
}
@media (min-width: 768px) {
  .module-trustedby__white-inner {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-image: url(../images/bg/trusted-by.svg);
    background-position: -190px 510px;
    background-size: 670px 680px;
  }
}
@media (min-width: 1024px) {
  .module-trustedby__white-inner {
    background-position: -80px 270px;
    background-size: 796px 1000px;
  }
}
@media (min-width: 1280px) {
  .module-trustedby__white-inner {
    background-size: 1100px;
    background-position: -90px 330px;
  }
}
.module-trustedby__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
@media (min-width: 768px) {
  .module-trustedby__wrapper {
    margin-top: 2.5rem;
  }
}
.module-trustedby__logotype {
  margin: 0 auto;
  width: 130px;
  height: 60px;
  background-size: 140px auto;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 360px) {
  .module-trustedby__logotype {
    width: 150px;
    height: 80px;
    background-size: 160px;
  }
}
@media (min-width: 768px) {
  .module-trustedby__logotype {
    width: 240px;
    height: 130px;
    background-size: 220px auto;
  }
}
@media (min-width: 1280px) {
  .module-trustedby__logotype {
    width: 300px;
    height: 130px;
    background-size: 230px auto;
  }
}
.module-trustedby__logotype--accenture {
  background-image: url(../images/partner/logo-accenture.svg);
  background-size: 110px;
}
@media (min-width: 360px) {
  .module-trustedby__logotype--accenture {
    background-size: 140px;
  }
}
@media (min-width: 768px) {
  .module-trustedby__logotype--accenture {
    background-size: 160px;
  }
}
.module-trustedby__logotype--adobe {
  background-image: url(../images/partner/logo-adobe.svg);
}
.module-trustedby__logotype--att {
  background-image: url(../images/partner/logo-att.svg);
}
.module-trustedby__logotype--citi {
  background-image: url(../images/partner/logo-citi.svg);
}
.module-trustedby__logotype--deloitte {
  background-image: url(../images/partner/logo-deloitte.svg);
}
.module-trustedby__logotype--disney {
  background-image: url(../images/partner/logo-disney.svg);
}
.module-trustedby__logotype--drupal {
  background-image: url(../images/partner/logo-drupal.svg);
}
.module-trustedby__logotype--ibm {
  background-image: url(../images/partner/logo-ibm.svg);
}
.module-trustedby__logotype--microsoft {
  background-image: url(../images/partner/logo-microsoft.svg);
}
.module-trustedby__logotype--mit {
  background-image: url(../images/partner/logo-mit.svg);
  background-size: 42px auto;
}
@media (min-width: 768px) {
  .module-trustedby__logotype--mit {
    background-size: 72px auto;
  }
}
.module-trustedby__logotype--mozilla {
  background-image: url(../images/partner/logo-mozilla.svg);
}
.module-trustedby__logotype--nbc {
  background-image: url(../images/partner/logo-nbc.svg);
}
.module-trustedby__logotype--novartis {
  background-image: url(../images/partner/logo-novartis.svg);
  background-size: 170px auto;
}
@media (min-width: 360px) {
  .module-trustedby__logotype--novartis {
    background-size: 190px auto;
  }
}
@media (min-width: 768px) {
  .module-trustedby__logotype--novartis {
    background-size: 290px auto;
  }
}
@media (min-width: 1280px) {
  .module-trustedby__logotype--novartis {
    background-size: 340px auto;
  }
}
.module-trustedby__logotype--oracle {
  background-image: url(../images/partner/logo-oracle.svg);
}
.module-trustedby__logotype--siemens {
  background-image: url(../images/partner/logo-siemens.svg);
}
.module-trustedby__logotype--volvo {
  background-image: url(../images/partner/logo-volvo.svg);
}
.module-trustedby__logotype--tata {
  background-image: url(../images/partner/logo-tata.svg);
}
.module-trustedby__logotype--thomson {
  background-image: url(../images/partner/logo-thomson.svg);
  background-size: 140px auto;
}
@media (min-width: 360px) {
  .module-trustedby__logotype--thomson {
    background-size: 160px auto;
  }
}
@media (min-width: 768px) {
  .module-trustedby__logotype--thomson {
    background-size: 270px auto;
  }
}
@media (min-width: 1280px) {
  .module-trustedby__logotype--thomson {
    background-size: 320px auto;
  }
}
.module-trustedby__logotype--unicef {
  background-image: url(../images/partner/logo-unicef.svg);
}

.shirt-box {
  display: none;
  margin-top: 8px;
}
.shirt-box .shirt-sizes {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .shirt-box .shirt-sizes {
    flex-direction: row-reverse;
  }
}
.shirt-box .shirt-sizes .shirt-sizes__image {
  margin: 0;
}
.shirt-box .shirt-sizes .shirt-sizes__image img {
  max-width: 100px;
}
.shirt-box .shirt-sizes .shirt-sizes__box {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .shirt-box .shirt-sizes .shirt-sizes__box {
    margin-right: 32px;
  }
}
.shirt-box .shirt-sizes .table + .table {
  margin-top: 16px;
}
.shirt-box .shirt-sizes .table {
  font-size: 14px;
  margin: 0;
}
@media (min-width: 360px) {
  .shirt-box .shirt-sizes .table {
    font-size: 16px;
  }
}
.shirt-box .shirt-sizes .table thead {
  background-color: #333333;
  color: #ffffff;
}
.shirt-box .shirt-sizes .table td,
.shirt-box .shirt-sizes .table th {
  padding: 4px;
}
.shirt-box .shirt-sizes .table td:first-of-type,
.shirt-box .shirt-sizes .table th:first-of-type {
  width: 130px;
}
.shirt-box .shirt-sizes .table tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 197, 242, 0.1607843137);
}

.shirt-box--active {
  display: block;
}

.btn-shirts-box {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .btn-shirts-box {
    width: auto;
    margin: 0;
    margin-left: auto;
  }
}

.btn-shirts {
  color: var(--color-tang-5);
  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.5;
}
button {
  background-color: transparent;
}

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  border-radius: var(--space-3xs);
  transition: all 200ms ease-in-out;
  border-radius: var(--space-3xl);
  padding: var(--space-m) var(--space-xl);
  border: 0;
  background-color: var(--color-tang-5);
  color: var(--color-seal-1);
  font-size: calc(1.9rem-0.45rem * 1.2);
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:hover, .btn:focus {
  background-color: color-mix(in srgb, var(--color-tang-5) 5%, var(--color-mix-base, var(--color-shark-1)));
  color: var(--color-seal-1);
}
.btn:disabled:hover, .btn:disabled:focus, .btn[disabled=true]:hover, .btn[disabled=true]:focus {
  background-color: var(--color-tang-5);
  color: var(--color-seal-1);
}
@media (min-width: 1280px) {
  .btn {
    font-size: 1.9rem-0.45rem;
  }
}
@media (min-width: 768px) {
  .btn {
    font-size: 1.92rem;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .btn {
    font-size: 1.6rem;
  }
}
.btn:disabled, .btn[disabled=true] {
  opacity: 0.6;
  cursor: not-allowed;
}
.btn--success {
  border: 0;
  background-color: var(--color-comet-6);
  color: var(--color-seal-1);
}
.btn--success:hover, .btn--success:focus {
  background-color: color-mix(in srgb, var(--color-comet-6) 5%, var(--color-mix-base, var(--color-shark-1)));
  color: var(--color-seal-1);
}
.btn--success:disabled:hover, .btn--success:disabled:focus, .btn--success[disabled=true]:hover, .btn--success[disabled=true]:focus {
  background-color: var(--color-comet-6);
  color: var(--color-seal-1);
}
.btn--success.btn--frame {
  border: 0;
  background-color: var(--color-comet-6);
  color: var(--color-seal-1);
}
.btn--success.btn--frame:hover, .btn--success.btn--frame:focus {
  background-color: color-mix(in srgb, var(--color-comet-6) 5%, var(--color-mix-base, var(--color-shark-1)));
  color: var(--color-seal-1);
}
.btn--success.btn--frame:disabled:hover, .btn--success.btn--frame:disabled:focus, .btn--success.btn--frame[disabled=true]:hover, .btn--success.btn--frame[disabled=true]:focus {
  background-color: var(--color-comet-6);
  color: var(--color-seal-1);
}
.btn--frame {
  border: 0;
  background-color: var(--color-tang-5);
  color: var(--color-seal-1);
}
.btn--frame:hover, .btn--frame:focus {
  background-color: color-mix(in srgb, var(--color-tang-5) 5%, var(--color-mix-base, var(--color-shark-1)));
  color: var(--color-seal-1);
}
.btn--frame:disabled:hover, .btn--frame:disabled:focus, .btn--frame[disabled=true]:hover, .btn--frame[disabled=true]:focus {
  background-color: var(--color-tang-5);
  color: var(--color-seal-1);
}
.btn--frame-bright {
  border: 0;
  background-color: var(--color-seal-1);
  color: var(--color-seal-1);
  background-color: transparent;
}
.btn--frame-bright:hover, .btn--frame-bright:focus {
  background-color: color-mix(in srgb, var(--color-seal-1) 5%, var(--color-mix-base, var(--color-shark-1)));
  color: var(--color-seal-1);
}
.btn--frame-bright:disabled:hover, .btn--frame-bright:disabled:focus, .btn--frame-bright[disabled=true]:hover, .btn--frame-bright[disabled=true]:focus {
  background-color: var(--color-seal-1);
  color: var(--color-seal-1);
}
.btn--frame-bright:hover, .btn--frame-bright:focus {
  color: var(--color-tang-5);
}
.btn--big {
  font-size: 1.9rem;
}
@media (min-width: 1280px) {
  .btn--big {
    font-size: 1.9rem;
  }
}
.btn--small {
  font-size: calc(1.9rem-0.45rem * 1.2);
}
@media (min-width: 1280px) {
  .btn--small {
    font-size: 1.9rem-0.45rem;
  }
}
.btn--tiny {
  font-size: calc(1.9rem-0.6rem * 1.2);
}
@media (min-width: 1280px) {
  .btn--tiny {
    font-size: 1.9rem-0.6rem;
  }
}
@media (min-width: 768px) {
  .btn-cta {
    min-width: 17.5rem;
  }
}
@media (min-width: 768px) {
  .btn-cta--long {
    min-width: 22.5rem;
  }
}
@media (min-width: 768px) {
  .btn-cta--short {
    min-width: 12.5rem;
  }
}
.btn-desktop-only {
  display: none;
}
@media (min-width: 1280px) {
  .btn-desktop-only {
    display: block;
  }
}
.btn-mobile-only {
  display: block;
}
@media (min-width: 1280px) {
  .btn-mobile-only {
    display: none;
  }
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

.link {
  position: relative;
  text-decoration: none;
  background-image: linear-gradient(to right, var(--color-tang-6) 0%, var(--color-tang-7) 100%);
  background-repeat: repeat-x;
  transition: 0.25s background-size ease-in-out, 0.25s border-color ease-in-out;
  background-position: 0 100%;
  background-size: 0% 1px;
  background-repeat: no-repeat;
}
.link:hover {
  text-decoration: none;
  background-size: 100% 1px;
}

.link--big {
  font-size: 2.4rem;
}

.link--decorator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(16px, -30%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid var(--color-tang-5);
  border-bottom: 2px solid var(--color-tang-5);
  transition: transform 0.25s ease-in-out;
}
.link--decorator:hover::after {
  transform: translate(20px, -30%) rotate(-45deg);
  border-color: currentColor;
}

.link {
  position: relative;
  text-decoration: none;
  background-image: linear-gradient(to right, var(--color-tang-6) 0%, var(--color-tang-7) 100%);
  background-repeat: repeat-x;
  transition: 0.25s background-size ease-in-out, 0.25s border-color ease-in-out;
  background-position: 0 100%;
  background-size: 0% 1px;
  background-repeat: no-repeat;
}
.link:hover {
  text-decoration: none;
  background-size: 100% 1px;
}

.link--big {
  font-size: 2.4rem;
}

.link--decorator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(16px, -30%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid var(--color-tang-5);
  border-bottom: 2px solid var(--color-tang-5);
  transition: transform 0.25s ease-in-out;
}
.link--decorator:hover::after {
  transform: translate(20px, -30%) rotate(-45deg);
  border-color: currentColor;
}

.hero {
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .hero {
    padding-top: 64px;
    padding-bottom: 64px;
    margin-bottom: 10rem;
  }
}

.hero-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .hero-inner {
    flex-direction: row;
  }
}

.devjs-hero {
  background-color: #0086CD;
  color: #ffffff;
  position: relative;
}
.devjs-hero::after {
  position: absolute;
  content: "";
  pointer-events: none;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translateX(-50%) rotate(45deg);
}
@media (min-width: 768px) {
  .devjs-hero::after {
    width: 72px;
    height: 72px;
  }
}
.devjs-hero::after {
  bottom: -12px;
  background: inherit;
}
@media (min-width: 768px) {
  .devjs-hero::after {
    bottom: -30px;
  }
}
.devjs-hero .meetjs-logo,
.devjs-hero .devjs-logo {
  max-width: 160px;
  margin-top: 12px;
  margin-left: initial;
}
@media (min-width: 768px) {
  .devjs-hero .meetjs-logo,
.devjs-hero .devjs-logo {
    margin-top: initial;
    margin-left: 32px;
  }
}

.banner {
  position: fixed;
  top: 20%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  min-width: 320px;
  opacity: 0;
  visibility: hidden;
}
.banner .banner__content {
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  padding: 56px 32px;
}
@media (min-width: 768px) {
  .banner .banner__content {
    min-width: 540px;
  }
}
.banner .banner__content::before, .banner .banner__content::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: var(--color-tang-5);
  opacity: 0.6;
  width: 24px;
  height: 24px;
}
@media (min-width: 768px) {
  .banner .banner__content::before, .banner .banner__content::after {
    width: 40px;
    height: 40px;
  }
}
.banner .banner__content::before {
  left: 24px;
}
@media (min-width: 768px) {
  .banner .banner__content::before {
    left: 40px;
  }
}
.banner .banner__content::after {
  bottom: 24px;
}
@media (min-width: 768px) {
  .banner .banner__content::after {
    bottom: 40px;
  }
}
.banner .banner__header {
  text-align: center;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 12px;
}
.banner .banner__header::after {
  display: none;
}
.banner .banner__text {
  text-align: center;
  margin: 0;
}
.banner .banner__close-btn {
  border: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
}
.banner .banner__close-btn::before, .banner .banner__close-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-tang-5);
  width: 100%;
  height: 6px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.banner .banner__close-btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.banner--active {
  opacity: 1;
  visibility: visible;
  z-index: 9999999;
}

.banner-thankyou {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
.banner-thankyou::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--color-tang-5);
  opacity: 0.6;
}

.banner-quiz {
  border: 4px solid rgba(0, 134, 204, 0.831372549);
  width: calc(100% - 10px);
  transform: translate(-50%, -10%);
  opacity: 0;
  transition: 0.25s ease-in-out transform, 0.25s ease-in-out opacity;
  min-width: auto;
  border-radius: 4px;
  top: 5px;
  visibility: visible;
  z-index: -1;
}
.banner-quiz .banner__content {
  padding: 12px;
  background-color: #e7f1fa;
}
.banner-quiz .banner__content::before, .banner-quiz .banner__content::after {
  display: none;
}
.banner-quiz .banner__header {
  font-size: 2.4rem;
  margin-bottom: initial;
}
.banner-quiz.banner--active {
  z-index: 1;
  opacity: 1;
  transform: translate(-50%, 0);
  transition: 0.25s ease-in-out transform, 0.25s ease-in-out opacity;
}

.form__header {
  text-align: center;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .form__header {
    margin-bottom: 32px;
  }
}
.form__header::after {
  display: none;
}

.form-box {
  max-width: 960px;
  padding: 32px 16px;
  background-color: var(--color-seal-1);
  margin-left: 16px;
  margin-right: 16px;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
@media (min-width: 768px) {
  .form-box {
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1280px) {
  .form-box {
    padding: 32px 48px;
  }
}

.form > *:last-child {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .form > *:last-child {
    margin-top: 32px;
  }
}
.form .form__col {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .form .form__col {
    flex-direction: row;
  }
}
.form .form__col > * {
  flex: 1;
}
@media (min-width: 768px) {
  .form .form__col > *:first-child {
    margin-right: 8px;
  }
}
@media (min-width: 768px) {
  .form .form__col > *:last-child {
    margin-left: 8px;
  }
}
.form .form__row {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  padding-bottom: 12px;
}
@media (min-width: 768px) {
  .form .form__row {
    margin-top: 4px;
    padding-bottom: 32px;
  }
}
.form .form__row--checkbox {
  padding-bottom: 12px;
}
.form .form__row--checkbox .error-message {
  bottom: 0px;
  left: 32px;
  font-size: 12px;
}
.form .form__label {
  color: #e52283;
  margin-bottom: 4px;
}
.form .form__label-file-type {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.6;
  color: #4e4b4b;
}
.form .form__label--checkbox {
  position: relative;
  padding-left: 32px;
  margin-bottom: 0;
}
.form .form__label--checkbox::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-tang-5);
  transition: 0.25s ease-in-out background-color;
}
.form .form__label--checkbox a {
  font-size: inherit;
}
.form .form__label--radio {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  border: 1px solid var(--color-tang-5);
  background-color: transparent;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .form .form__label--radio {
    padding: 8px 0;
  }
}
.form .form__input {
  padding: 8px;
  border: 2px solid #e4e4f7;
  width: 100%;
}
@media (min-width: 768px) {
  .form .form__input {
    padding: 12px;
  }
}
.form .form__input:focus {
  outline: 2px solid var(--color-tang-5);
}
.form .form__textarea {
  resize: vertical;
  min-height: 120px;
}
.form .form__cv-text {
  margin-top: 4px;
  font-size: 13px;
  margin-bottom: 0;
}
.form .form__radio-box {
  display: flex;
}
.form .form__radio-header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 8px;
}
.form .form__radio,
.form .form__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.form .form__radio:focus + .form__label--checkbox::before,
.form .form__checkbox:focus + .form__label--checkbox::before {
  outline: 2px solid var(--color-tang-5);
}
.form .form__checkbox:checked + .form__label--checkbox::before {
  background-color: var(--color-tang-5);
}
.form .form__radio:checked + .form__label--radio {
  background-color: color-mix(in srgb, var(--color-tang-5) 60%, var(--color-mix-base, var(--color-seal-1)));
}
.form .error-message {
  position: absolute;
  bottom: -2px;
  left: 0;
  color: red;
  font-size: 1.2rem;
  line-height: 1;
  display: none;
  margin: 0;
}
@media (min-width: 768px) {
  .form .error-message {
    bottom: 14px;
    font-size: 1.4rem;
  }
}
.form .form-error ~ .error-message {
  display: block;
}
.form .form__submit {
  display: block;
  appearance: none;
  border: none;
  color: #ffffff;
  background-color: var(--color-tang-5);
  width: 100%;
  font-weight: bold;
  padding: 16px 24px;
}

.text-limiter {
  text-align: right;
  color: #e52283;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 4px;
}

.badges-wrapper {
  padding-top: 24px;
  padding-bottom: 48px;
  max-width: 490px;
  margin-inline: auto;
}
@media (min-width: 768px) {
  .badges-wrapper {
    padding-top: 48px;
    margin-inline: auto;
    max-width: 760px;
  }
}

.badge-row {
  max-width: 400px;
  margin-inline: auto;
}
@media (min-width: 768px) {
  .badge-row {
    max-width: initial;
    margin-inline: initial;
    display: flex;
    gap: 20px;
  }
}
.badge-row .badge {
  margin-inline: auto;
}
@media (min-width: 768px) {
  .badge-row .badge {
    margin-left: initial;
  }
}
.badge-row .badge-content {
  text-align: center;
}
@media (min-width: 768px) {
  .badge-row .badge-content {
    text-align: initial;
  }
}
.badge-row .badge-list .badge-link {
  display: flex;
  gap: 8px;
  text-align: initial;
  margin-inline: initial;
}
@media (min-width: 768px) {
  .badge-row + .badge-row {
    position: relative;
    padding-top: 24px;
    margin-top: 24px;
  }
}
@media (min-width: 768px) {
  .badge-row + .badge-row::before {
    content: "";
    position: absolute;
    top: 0;
    left: 60%;
    height: 3px;
    width: 130px;
    transform: translateX(-50%);
    background-color: #efecee;
  }
}
.badge-row > .badge {
  margin-bottom: -20px;
}
@media (min-width: 768px) {
  .badge-row > .badge {
    margin: 0 auto -20px;
  }
}

.badge-list {
  padding-left: 8px;
}
.badge-list .badge {
  width: 32px;
  height: 32px;
}
.badge-list li {
  display: flex;
}
.badge-list p {
  margin: 0;
}

.badge {
  display: block;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.badge-cke5,
.badge-cke4,
.badge-ckbox,
.badge-premium-features {
  width: 128px;
  height: 128px;
}

.badge-cke5 {
  background-image: url(../images/icon/cke5.svg);
}

.badge-cke4 {
  background-image: url(../images/icon/cke4.svg);
}

.badge-premium-features {
  background-image: url(../images/icon/premium-features.svg);
}

.badge-revision-history {
  background-image: url(../images/icon/revision-history.png);
}

.badge-ckbox {
  background-image: url(../images/icon/ckbox.svg);
}

.badge-real-time-collaboration {
  background-image: url(../images/icon/real-time.svg);
}

.badge-track-changes {
  background-image: url(../images/icon/track-changes.svg);
}

.badge-export-pdf {
  background-image: url(../images/icon/export-pdf.png);
}

.badge-comments {
  background-image: url(../images/icon/comments.svg);
}

.decorators {
  position: relative;
}

.decorator {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 125px;
}

.decorator--big {
  width: 180px;
  height: 190px;
}

.decorator--hex-cke5 {
  top: 0;
  right: 0;
  background-image: url(../images/decorators/hex-decorator-cke5.svg);
  transform: translate(10px, 140px);
}

.decorator--hex-cke4 {
  top: 0;
  right: 0;
  background-image: url(../images/decorators/hex-decorator-cke4.svg);
  transform: translate(70px, 40px);
}

.decorator--hex-collaboration {
  top: 35%;
  left: 0;
  background-image: url(../images/decorators/hex-decorator-collaboration.svg);
  transform: translate(-120px, 120px);
}

.decorator--hex-export-pdf {
  top: 35%;
  left: 0;
  background-image: url(../images/decorators/hex-decorator-export-pdf.svg);
  transform: translate(0px, 20px);
}

.decorator--hex-revision-history {
  top: 70%;
  left: 0;
  background-image: url(../images/decorators/hex-decorator-revision-history.svg);
}

.decorator--hex-uploaders {
  top: 80%;
  left: 70%;
  background-image: url(../images/decorators/hex-decorator-uploaders.svg);
  transform: translate(190px, 50px);
}

.contest-bar {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .contest-bar {
    margin-bottom: 32px;
    padding-top: 43px;
    padding-bottom: 43px;
  }
}
.contest-bar .contest-bar-logo {
  max-width: 60px;
}
@media (min-width: 768px) {
  .contest-bar .contest-bar-logo {
    max-width: 80px;
  }
}

.contest-bar--devjs {
  background-color: #0086CD;
}

.contest-bar--meetjs {
  background-color: #001234;
}
.contest-bar--meetjs .contest-bar-logo {
  max-width: 80px;
}
@media (min-width: 768px) {
  .contest-bar--meetjs .contest-bar-logo {
    max-width: 120px;
  }
}

.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown__list {
  display: none !important;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 100%;
  background-color: var(--color-seal-1);
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
@media (min-width: 0) and (max-width: 767px) {
  .dropdown__list {
    max-width: 100%;
    width: 100%;
  }
}
.dropdown__list li {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown__list li a {
  color: var(--color-tang-5);
  padding: 1.5rem;
}
.dropdown--dark .dropdown__list {
  background-color: color-mix(in srgb, var(--color-shark-1) 90%, var(--color-mix-base, transparent));
}
.dropdown--dark .dropdown__list li a {
  color: var(--color-seal-2);
}
.dropdown[data-open=true] ul {
  display: block !important;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -ms-animation-duration: 300ms;
  -o-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dropdown button[data-toggle=dropdown] {
  position: relative;
  padding-right: 3rem;
  border-radius: 0;
}
.dropdown button[data-toggle=dropdown]:after {
  content: "▼";
  position: absolute;
  right: 1.25rem;
  top: 55%;
  font-size: 1.1rem;
  transform: translateY(-50%);
}
.dropdown--hide {
  display: none;
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -ms-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -ms-animation-duration: 300ms;
  -o-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dropdown--huge {
  width: 100%;
}
.dropdown--huge button[data-toggle=dropdown] {
  width: 100%;
  padding: 2.5rem;
  background-color: var(--color-seal-1);
  color: var(--color-shark-1);
  font-size: 1.9rem;
  text-align: left;
}
@media (min-width: 768px) {
  .dropdown--huge button[data-toggle=dropdown] {
    padding: 2.5rem;
    font-size: 1.9rem;
  }
}
.dropdown--huge button[data-toggle=dropdown]:after {
  content: "";
  right: 2.5rem;
  top: 50%;
  background-color: var(--color-tang-5);
  background-image: none;
  clip-path: polygon(0% 44%, 50% 12%, 100% 44%, 100% 33%, 50% 0%, 0% 32%, 0% 59%, 50% 88%, 100% 56%, 100% 68%, 50% 100%, 0% 71%);
  --size: 28px;
  width: var(--size);
  height: calc(var(--size) * 1.2);
}
@media (min-width: 0) and (max-width: 767px) {
  .dropdown--huge button[data-toggle=dropdown]:after {
    right: 0.5rem;
    transform: translateY(-50%) scale(0.7);
  }
}
.dropdown--huge[data-open=true] button:focus, .dropdown--huge[data-open=true] button:active {
  outline: none;
}
.dropdown--huge .dropdown__list {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 999;
}
.dropdown--huge .dropdown__list li:first-of-type {
  border-top: 1px solid var(--color-shark-4);
}
.dropdown--huge .dropdown__list li a {
  display: block;
  padding: 2.5rem;
}
@media (min-width: 768px) {
  .dropdown--huge .dropdown__list li a {
    font-size: 2.1rem;
    padding: 2.5rem;
  }
}
.dropdown--huge .dropdown__list li a:hover {
  color: var(--color-tang-5);
  text-decoration: none;
}

.menu {
  position: absolute;
  top: 2.5rem;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  z-index: 999999;
  transition: background-color 200ms ease-in-out;
}
.menu .cksource-logo {
  color: var(--color-seal-1);
  position: absolute;
  top: 50%;
  display: inline-block;
  margin-left: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  padding: var(--space-xs) var(--space-s);
  line-height: 1;
}
@media (min-width: 768px) {
  .menu .cksource-logo {
    margin-left: 3.5rem;
  }
}
.menu-navigation {
  display: none;
  float: right;
  padding-right: 2rem;
  height: 65px;
  line-height: 65px;
  transition: padding 200ms ease-in-out;
}
@media (width >= 48rem) {
  .menu-navigation {
    display: block;
    padding-right: 3.5rem;
  }
}
.menu-navigation--ready {
  visibility: visible;
  opacity: 1;
}
.menu-navigation__socials {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
  display: flex;
  gap: 1.25rem;
  align-items: center;
  position: absolute;
  bottom: 65%;
  right: 0;
}
@media (min-width: 1280px) {
  .menu-navigation__socials {
    padding-right: 4.5rem;
  }
}
.menu-navigation__socials .menu-social-item {
  margin: 0;
}
.menu-navigation__socials .menu-social-link {
  --c-sprite-icon-unit: 1.6rem;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1882352941);
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--color-seal-1);
}
body:has(.header-neo) .menu-navigation__socials .menu-social-link {
  background-color: var(--color-tang-6);
}
.menu-navigation__socials .menu-social-link:hover {
  background-color: rgba(255, 255, 255, 0.3137254902);
}
.menu-navigation__socials .menu-social-link--twitter .c-sprite-icon--social-x {
  transform: translateX(-1px);
}
.menu-navigation__socials--mobile {
  position: static;
  margin-top: -3.9rem;
}
.menu-navigation__socials--mobile .menu-social-link {
  width: 32px;
  height: 32px;
  --c-sprite-icon-unit: 2rem;
}
.menu-navigation__list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu-navigation__item {
  display: inline-block;
  height: 65px;
  position: relative;
}
.menu-navigation__item:first-of-type {
  margin-left: 0;
}
.menu-navigation__item a {
  display: block;
  color: var(--color-seal-1);
  font-size: calc(var(--step-1) * 1.5);
  font-weight: 600;
  height: 100%;
  line-height: 65px;
  padding: 0 1rem;
  position: relative;
  transition: color 200ms ease-in-out;
}
.menu-navigation__item a:after {
  content: "";
  background-color: transparent;
  width: calc(100% - 2rem);
  height: 2px;
  bottom: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 200ms ease-in-out;
}
.menu-navigation__item a:hover {
  color: #fff;
  text-decoration: none;
}
.menu-navigation__item a:hover:after {
  background-color: #fff;
}
.menu-navigation__item a:focus, .menu-navigation__item a:hover {
  text-decoration: none;
}
.menu-navigation__item--current a::after {
  background-color: var(--color-comet-5);
}
.menu-more {
  display: none;
}
.menu-more--active {
  display: inline-block;
}
.menu-more__list li a {
  height: auto;
  line-height: inherit;
  padding: 1rem 1.5rem;
}
.menu-more__list li:first-of-type a {
  padding-top: 1.5rem;
}
.menu-more__list li:last-of-type a {
  padding-bottom: 1.5rem;
}
.menu-more__toggler {
  background-color: transparent;
  border: none;
  font-weight: 800;
  font-size: 2.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .menu-more__toggler {
    font-size: 1.6rem;
  }
}
.menu-more__toggler:hover, .menu-more__toggler:focus {
  background-color: transparent;
  border: none;
  color: car(--color-tang-5);
}
.p-home .menu {
  padding-top: calc(var(--space-m) * 1.5);
  top: 0;
  background-color: color-mix(in srgb, var(--color-seal-1) 80%, var(--color-mix-base, transparent));
  height: var(--menu-height);
}
.p-home .menu::after {
  position: absolute;
  width: 100vw;
  height: 2px;
  content: "";
  display: block;
  bottom: -2px;
  left: 0;
  background: var(--gradient-neo);
}
.p-home .menu .cksource-logo {
  color: var(--color-shark-1);
}
.menu .c-sprite-icon--ui-new-tab {
  --c-sprite-icon-unit: 16px;
  margin-left: var(--space-3xs);
}

.site-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  z-index: 1000002;
  transition: padding 200ms ease-in-out;
}
@media (width >= 48rem) {
  .site-nav {
    display: none;
  }
}
.site-nav--active {
  position: fixed;
  top: 0;
  transition: all 200ms ease-in-out;
}
.site-nav__toggler {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  height: 39px;
  width: 40px;
  padding: 0;
  cursor: pointer;
  background-color: inherit;
  border: 0;
  transition: all 200ms ease-in-out;
}
@media (min-width: 1280px) {
  .site-nav__toggler {
    left: 2.5rem;
  }
}
.site-nav__toggler:focus, .site-nav__toggler:active {
  outline: none;
}
.site-nav__toggler .bar {
  position: absolute;
  left: 5px;
  height: 3px;
  width: 30px;
  display: block;
  background-color: var(--color-seal-1);
  border-radius: 10px;
  transition: transform 300ms ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
}
.site-nav__toggler .bar:nth-of-type(1) {
  top: 10px;
  transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
  animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}
.site-nav__toggler .bar:nth-of-type(2) {
  top: 18px;
  transition: opacity 100ms ease-in-out 100ms;
  animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}
.site-nav__toggler .bar:nth-of-type(3) {
  top: 26px;
  transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
  animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}
.p-home .site-nav__toggler .bar {
  background-color: var(--color-tang-5);
}
.p-home .site-nav__toggler {
  top: var(--space-l);
  right: var(--space-l);
}
.site-nav__toggler--active .bar:nth-of-type(1) {
  top: 22px;
  transform: rotate(45deg) translate(0px, -5px);
  transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
}
.site-nav__toggler--active .bar:nth-of-type(2) {
  opacity: 0;
}
.site-nav__toggler--active .bar:nth-of-type(3) {
  top: 22px;
  transform: rotate(-45deg) translate(5px, 0px);
  transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
}
.site-nav__panel {
  position: fixed;
  top: 0;
  right: -230px;
  height: 100vh;
  width: 230px;
  padding-top: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: var(--color-tang-8);
  overflow-y: auto;
  z-index: 1000001;
  transition: transform 300ms ease-in-out;
}
.site-nav__panel--active {
  transform: translateX(-230px);
}
@media (min-width: 768px) {
  .site-nav__panel {
    left: -280px;
    width: 280px;
  }
  .site-nav__panel--active {
    transform: translateX(280px);
  }
}
.site-nav__panel .c-sprite-icon--ui-new-tab {
  --c-sprite-icon-unit: 16px;
  margin-left: var(--space-3xs);
}
.site-nav__menu, .site-nav__submenu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-nav__menu-item, .site-nav__submenu-item {
  position: relative;
}
.site-nav__menu-item a, .site-nav__submenu-item a {
  display: block;
  color: var(--color-seal-1);
  padding: 1.2rem 0.2rem;
  font-weight: 600;
  line-height: 1.2;
  border-bottom: 1px solid color-mix(in srgb, var(--color-seal-1) 0.2, var(--color-mix-base, transparent));
}
.site-nav__menu-item a:focus, .site-nav__submenu-item a:focus {
  outline: none;
  background-color: color-mix(in srgb, var(--color-seal-1) 0.2, var(--color-mix-base, transparent));
}
.site-nav__menu-item a:focus, .site-nav__menu-item a:hover, .site-nav__submenu-item a:focus, .site-nav__submenu-item a:hover {
  text-decoration: none;
}
.site-nav__menu-item.menu-product__list-wrapper, .site-nav__submenu-item.menu-product__list-wrapper {
  padding-bottom: 1.2rem;
  border-bottom: 1px solid color-mix(in srgb, var(--color-seal-1) 0.2, var(--color-mix-base, transparent));
}
.site-nav__menu-item.menu-product__list-wrapper > a, .site-nav__submenu-item.menu-product__list-wrapper > a {
  border-bottom: 0;
}
.site-nav__menu-item--current::after, .site-nav__submenu-item--current::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-tang-5);
}
.site-nav__submenu-item a {
  border-bottom: 0;
  padding-left: 1.5rem;
}

.site-cover,
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: color-mix(in srgb, var(--color-shark-1) 0.8, var(--color-mix-base, transparent));
  z-index: -1;
  transition: opacity 200ms ease-in-out;
}
.site-cover--active,
.overlay--active {
  z-index: 1000000;
  opacity: 1;
}
.site-cover--hide,
.overlay--hide {
  opacity: 0;
}

.offers {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 5rem;
}
@media (min-width: 1280px) {
  .offers {
    overflow: initial;
    padding-bottom: 10rem;
  }
}

.offers__list-item-outer .offers__list-item-header {
  display: flex;
  flex-direction: column;
  padding: 16px 56px 8px 16px;
}
@media (min-width: 768px) {
  .offers__list-item-outer .offers__list-item-header {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 16px 72px 16px 50px;
  }
}
.offers__list-item-outer .offers__list-item-title {
  font-size: 1.9rem;
  line-height: 1.2;
  margin: 0 0 4px;
}
@media (min-width: 768px) {
  .offers__list-item-outer .offers__list-item-title {
    display: flex;
    justify-content: space-between;
    font-size: 2.4rem;
  }
}
.offers__list-item-outer .offers__list-item-salary {
  display: block;
  color: var(--color-tang-5);
  letter-spacing: 0.2px;
}
.offers__list-item-outer .offers__list-item-salary--big {
  font-size: 1.4rem;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .offers__list-item-outer .offers__list-item-salary--big {
    font-size: 2.4rem;
    margin-top: initial;
  }
}
.offers__list-item-outer .offer__list-item-bottom {
  margin: 0;
  color: #525050;
}
@media (min-width: 768px) {
  .offers__list-item-outer .offer__list-item-bottom {
    display: flex;
    justify-content: space-between;
  }
}
.offers__list-item-outer .offer__list-item-bottom p {
  margin: 0;
}

.no-job-position {
  display: none;
  font-size: 1.9rem;
  background-color: #ffffff;
  padding: 24px;
  border-left: 4px solid var(--color-tang-5);
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 768px) {
  .no-job-position {
    margin-left: initial;
    margin-right: initial;
  }
}
@media (min-width: 1280px) {
  .no-job-position {
    font-size: 2.4rem;
  }
}

.offers-filter-handler {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .offers-filter-handler {
    display: none;
  }
}

.job-filters {
  position: relative;
}
.job-filters::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotateZ(-90deg);
  width: 0.8em;
  height: 0.5em;
  background-color: var(--color-tang-5);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  transition: 0.25s ease-in-out transform;
}

.job-filters--active::after {
  transform: translateY(-50%) rotateZ(0);
}

.icon--settings {
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../images/icon/gears.svg);
  border: none;
  appearance: none;
  padding: 0 16px 0 40px;
}

.offers-filter-wrapper {
  display: none;
  margin-top: 32px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .offers-filter-wrapper {
    display: block;
  }
}
@media (min-width: 768px) {
  .offers-filter-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: initial;
    padding-right: initial;
    margin-left: -8px;
    margin-right: -8px;
  }
}
.offers-filter-wrapper > * {
  flex: 1;
}

.offers-filter-wrapper--active {
  display: block;
}

.offer-filter-box {
  width: 100%;
}
@media (min-width: 768px) {
  .offer-filter-box {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.offer-filter-box + .offer-filter-box {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .offer-filter-box + .offer-filter-box {
    margin-top: initial;
  }
}

.offer-filter-label {
  display: block;
}

.offer-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 1.9rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, var(--color-seal-1) 33%);
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
.offer-select select {
  grid-area: select;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 12px 24px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}
.offer-select select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--color-tang-5);
  border-radius: inherit;
}
.offer-select::after {
  content: "";
  grid-area: select;
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--color-tang-5);
  margin-right: 12px;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.job__description {
  flex-grow: 1;
  margin-bottom: 32px;
}
@media (min-width: 1280px) {
  .job__description {
    margin-bottom: initial;
  }
}
.job__description > *:first-child {
  margin-top: 0;
}
.job__description > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
@media (min-width: 768px) {
  .job__description > * {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
@media (min-width: 1280px) {
  .job__description {
    margin-right: 24px;
  }
}
@media (min-width: 0) and (max-width: 767px) {
  .job__description {
    width: 100%;
  }
}
.job__description ol {
  margin-block: 0;
}
.job__description ol ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.job__description ol > li::before, .job__description ol > li::after {
  display: none;
}
.job__description ul {
  list-style: none;
  list-style-position: inside;
}
@media (min-width: 768px) {
  .job__description ul {
    list-style-position: outside;
  }
}
.job__description ul ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.job__description li::before {
  content: "•";
  color: var(--color-tang-5);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.job__description h4 {
  font-size: 2.1rem;
  font-weight: 300;
  margin-top: 3.75rem;
  margin-bottom: 12px;
}
@media (min-width: 0) and (max-width: 767px) {
  .job__description h4 {
    font-size: 1.9rem;
  }
}
@media (min-width: 768px) {
  .job__description h4 {
    padding-left: 20px;
  }
}
.job__description h4 + ul {
  margin-top: initial;
}
.job__description p {
  padding-left: 20px;
}
.job__description p + ul {
  margin-top: -1.5rem;
}

.job__meta {
  position: sticky;
  top: 40px;
}
.job__meta .job__details {
  background-color: var(--color-seal-2);
  padding: 26px;
  border-radius: 4px;
  padding: 12px 12px 24px;
}
@media (min-width: 768px) {
  .job__meta .job__details {
    padding: 26px;
  }
}
.job__meta .job__meta-title {
  color: var(--color-shark-1);
  font-size: 2.1rem;
  line-height: 1.2;
  margin: 0;
}
@media (min-width: 1280px) {
  .job__meta .job__meta-title {
    font-size: 2.1rem;
  }
}
@media (min-width: 768px) {
  .job__meta .job__meta-salary-wrapper {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .job__meta .job__meta-salary-wrapper {
    display: block;
  }
}
.job__meta .job__meta-salary-box {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .job__meta .job__meta-salary-box {
    margin-top: initial;
  }
}
@media (min-width: 1280px) {
  .job__meta .job__meta-salary-box {
    margin-top: 4px;
  }
}
.job__meta .job__meta-salary-box + .job__meta-salary-box {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .job__meta .job__meta-salary-box + .job__meta-salary-box {
    margin-top: initial;
    margin-left: 5rem;
  }
}
@media (min-width: 1280px) {
  .job__meta .job__meta-salary-box + .job__meta-salary-box {
    margin-top: 8px;
    margin-left: initial;
  }
}
.job__meta .job__salary {
  margin-bottom: 1.5rem;
}
.job__meta .job__meta-salary-type {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 0;
}
@media (min-width: 1280px) {
  .job__meta .job__meta-salary-type {
    font-size: 1.4rem;
  }
}
.job__meta .job__meta-salary {
  color: var(--color-tang-5);
  font-size: 1.9rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0;
}
.job__meta .job__meta-salary-form {
  color: var(--color-shark-2);
  font-size: 14px;
  line-height: 1.2;
  padding-left: 4px;
}
.job__meta .job__skills h4 {
  margin-bottom: 0.7rem;
}
.job__meta .job__skills h5 {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;
}
.job__meta .job__skills-bar {
  margin-bottom: 1.25rem;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-shark-1);
}
.job__meta .job__skills-level {
  float: left;
  height: 100%;
  background-color: var(--color-tang-5);
  border-radius: 5px;
}
.job__meta .job__disclaimer {
  max-height: 215px;
  font-size: 1.2rem;
  overflow-y: auto;
  line-height: 1.6rem;
  font-style: italic;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .job__meta .job__disclaimer {
    margin-top: 24px;
  }
}

.offer__list-item-tiny-info {
  font-size: 1.9rem;
  line-height: 1.2;
}
.offer__list-item-bottom .offer__list-item-tiny-info {
  font-size: 1.4rem;
}
@media (min-width: 1280px) {
  .offer__list-item-tiny-info {
    font-size: 1.6rem;
  }
}

.offer__list-item-tiny-info + .offer__list-item-tiny-info {
  position: relative;
  padding-left: 8px;
  margin-left: 6px;
}
.offer__list-item-tiny-info + .offer__list-item-tiny-info::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 14px;
  background-color: rgba(85, 96, 110, 0.7215686275);
}

.job__details .btn {
  display: none;
}
@media (min-width: 1280px) {
  .job__details .btn {
    display: block;
  }
}

.contact-person {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-left: 64px;
}
@media (min-width: 360px) {
  .contact-person {
    padding-left: 104px;
  }
}
@media (min-width: 1280px) {
  .contact-person {
    padding-left: initial;
    padding-bottom: 16px;
    text-align: center;
  }
}
.contact-person::before, .contact-person::after {
  display: none;
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  border-bottom: 2px solid color-mix(in srgb, var(--color-tang-7) 10%, var(--color-mix-base, transparent));
}
@media (min-width: 1280px) {
  .contact-person::before, .contact-person::after {
    display: block;
  }
}
.contact-person::before {
  bottom: 0;
  left: 0;
  border-left: 2px solid color-mix(in srgb, var(--color-tang-7) 10%, var(--color-mix-base, transparent));
}
.contact-person::after {
  bottom: 0;
  right: 0;
  border-right: 2px solid color-mix(in srgb, var(--color-tang-7) 10%, var(--color-mix-base, transparent));
}
.contact-person:hover {
  text-decoration: none;
}
.contact-person:hover .person__contact {
  text-decoration: underline;
}
.contact-person .person__title {
  margin: 0;
  color: var(--color-shark-1);
  font-size: 2.1rem;
  line-height: 1.2;
  margin-bottom: 4px;
}
@media (min-width: 1280px) {
  .contact-person .person__title {
    font-size: 2.4rem;
    margin-bottom: initial;
  }
}
.contact-person .person__image {
  max-width: 40px;
  width: 100%;
  margin: 0;
  border-radius: 20%;
  box-shadow: 5px 2px 2px rgba(85, 124, 153, 0.1490196078);
  position: absolute;
  top: 0;
  left: 12px;
}
@media (min-width: 360px) {
  .contact-person .person__image {
    max-width: 72px;
  }
}
@media (min-width: 1280px) {
  .contact-person .person__image {
    max-width: 170px;
    margin: 12px auto 8px;
    display: block;
    position: static;
  }
}
.contact-person .person__name,
.contact-person .person__contact {
  margin: 0;
  color: var(--color-shark-1);
  line-height: 1.2;
}
@media (min-width: 1280px) {
  .contact-person .person__name {
    font-weight: bold;
  }
}
.contact-person .person__contact {
  display: inline-flex;
  font-size: 1.6rem;
  text-decoration: underline;
  margin-right: auto;
  padding-left: 24px;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: left center;
}
.contact-person .person__contact .c-sprite-icon--social-mail {
  --c-sprite-icon-unit: 6px;
  color: var(--color-tang-6);
  margin-right: var(--space-3xs);
}
@media (min-width: 1280px) {
  .contact-person .person__contact {
    margin-left: auto;
    font-size: 1.9rem;
    text-decoration: none;
  }
}

.skill-badge-wrapper {
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 12px;
}

.skill-badge {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: #ffffff;
  border: 1px solid var(--color-tang-5);
  border-radius: 12px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 4px 12px;
}

.flag {
  width: 18px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(1px 1px 13px rgba(0, 0, 0, 0.5882352941));
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3411764706);
}

.flag--position {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.flag--pl {
  background-image: url(../images/flags/pl.png);
}

.flag--en {
  background-image: url(../images/flags/gb.png);
}

.benefits {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.benefits h2 {
  z-index: 1;
}
.benefits .c-sprite-icon {
  --c-sprite-icon-height: 90px;
  --c-sprite-icon-width: 90px;
  margin: 0 auto;
  display: block;
}

.benefits-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-top: 5rem;
  margin-left: -12px;
  margin-right: -12px;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 360px) {
  .benefits-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .benefits-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    margin-left: -32px;
    margin-right: -32px;
  }
}

.benefit-item {
  padding: 0 12px;
  width: 100%;
}
@media (min-width: 768px) {
  .benefit-item {
    padding: 0 32px;
  }
}
.benefit .benefit__image {
  z-index: 1;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  border: 2px dashed var(--color-tang-5);
  background-color: var(--color-seal-1);
  background-image: url(../images/bg/benefits-mo@2x.png);
  background-repeat: no-repeat;
  background-size: 100% 120px;
  background-position: center;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
@media (min-width: 768px) {
  .benefit .benefit__image {
    width: 210px;
    height: 210px;
    background-size: auto 140px;
  }
}
.benefit .benefit__image--collaborative-env {
  background-image: url(../images/icon/collaborative-env.svg);
}
.benefit .benefit__image--creative-people {
  background-image: url(../images/icon/open-minded.svg);
}
.benefit .benefit__image--open-source {
  background-image: url(../images/icon/open-source.svg);
}
.benefit .benefit__image--fussball {
  background-image: url(../images/icon/table-tennis.svg);
}
.benefit .benefit__image--stability {
  background-image: url(../images/icon/stability.svg);
}
.benefit .benefit__image--healthcare {
  background-image: url(../images/icon/healthcare.svg);
}
.benefit .benefit__image--fitness {
  background-image: url(../images/icon/fitness.svg);
}
.benefit .benefit__image--remote {
  background-image: url(../images/icon/remote.svg);
}
.benefit .benefit__text {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 290px;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: var(--space-xs);
}
@media (min-width: 360px) {
  .benefit .benefit__text {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) {
  .benefit .benefit__text {
    font-size: 1.9rem;
  }
}
@media (min-width: 1280px) {
  .benefit .benefit__text {
    font-size: 2.1rem;
  }
}

.blog {
  position: relative;
  overflow: hidden;
}
.blog .blog-item {
  margin: 0;
  width: 100%;
}
.blog .blog-item:not(:last-of-type) {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .blog .blog-item {
    padding-left: 16px;
    padding-right: 16px;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .blog .blog-item {
    margin-bottom: initial;
    width: 33.333%;
  }
}

.blog-wrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.blog-wrapper .btn-wrapper {
  text-align: center;
}

.blog-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 24px;
}

.press {
  background-color: var(--color-seal-1);
  position: relative;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  overflow: hidden;
}
@media (min-width: 768px) {
  .press {
    margin-top: 5rem;
    padding-top: 5rem;
  }
}
.press .blog-item {
  margin: 0;
  width: 100%;
}
.press .blog-item:not(:last-of-type) {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .press .blog-item {
    padding-left: 16px;
    padding-right: 16px;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .press .blog-item {
    margin-bottom: initial;
  }
}

.card {
  display: block;
  position: relative;
  background-color: var(--color-seal-1);
  overflow: hidden;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
  transition: box-shadow 0.25s ease-in-out;
}
.card:hover {
  box-shadow: 0 16px 27px rgba(85, 124, 153, 0.1490196078);
}
.card:hover .card__title {
  color: var(--color-tang-5);
}
.card:hover .card__image::after {
  opacity: 0.5;
}
.card .card__content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 4px;
  padding: 12px 24px;
  background-color: var(--color-seal-1);
}
.card .card__title {
  color: var(--color-shark-1);
  font-size: 2.1rem;
  line-height: 1.2;
  margin: 0;
  transition: color 0.25s ease-in-out;
}
.card .card__image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 320px;
  flex: 1 0 auto;
  /**
         *  Blog images
         */
  /**
         *  Press images
         */
}
.card .card__image--blog-01 {
  background-image: url(../images/featured-posts/blog-candidate.png);
}
.card .card__image--blog-02 {
  background-image: url(../images/featured-posts/blog-best-practises-of-remote-work.png);
}
.card .card__image--blog-03 {
  background-image: url(../images/featured-posts/blog-collaboration.png);
}
.card .card__image--blog-04 {
  background-image: url(../images/featured-posts/blog-feedback-culture.png);
}
.card .card__image--blog-05 {
  background-image: url(../images/featured-posts/blog-meeting-madness.png);
}
.card .card__image--blog-06 {
  background-image: url(../images/featured-posts/blog-do-it-better.jpeg);
}
.card .card__image--press {
  background-size: contain;
  background-color: var(--color-seal-1);
  border-right: 2px solid var(--color-seal-2);
}
.card .card__image--press-nofluffjobs {
  background-image: url(../images/featured-posts/nofluffjobs.svg);
  background-size: auto;
}
.card .card__image--press-gptw {
  background-image: url(../images/featured-posts/gptw.svg);
  background-size: auto;
}
.card .card__image--press-inhire {
  background-image: url(../images/featured-posts/inhire.png);
  background-size: 80%;
}
.card .card__image--press-justgeekit {
  background-image: url(../images/featured-posts/justgeekit.svg);
  background-size: 90%;
}
.card .card__image--press-websiteplanet {
  background-image: url(../images/featured-posts/websiteplanet.png);
  background-size: auto;
}

/**
 *  Card horizontal
 */
.card--horizontal {
  display: flex;
  align-items: center;
}
.card--horizontal .card__image {
  height: 100px;
  width: 100px;
  z-index: 1;
}
.card--horizontal .card__content {
  position: static;
  display: flex;
  align-items: center;
}
.card--horizontal .card__content::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background-color: var(--color-tang-5);
  transform: translateX(-99%);
  transition: transform 0.25s ease-in-out;
}
.card--horizontal .card__title {
  isolation: isolate;
  font-size: 1.6rem;
}
.card--horizontal:hover {
  text-decoration: none;
}
.card--horizontal:hover .card__content::before {
  transform: translate(0);
}

.icon-star {
  display: block;
  position: relative;
  z-index: 1;
}
.icon-star::before, .icon-star::after {
  content: "";
  position: absolute;
  background-image: url(../images/icon/stars.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
}
@media (min-width: 768px) {
  .icon-star::before, .icon-star::after {
    display: block;
    width: 180px;
    height: 45px;
    top: -24px;
  }
}
@media (min-width: 1280px) {
  .icon-star::before, .icon-star::after {
    top: -60px;
    width: 270px;
    height: 65px;
  }
}
.icon-star::before {
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .icon-star::before {
    left: 0;
    transform: rotate(15deg);
  }
}
.icon-star::after {
  right: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .icon-star::after {
    right: 0;
    transform: rotate(-15deg);
  }
}

.icon-box {
  display: inline-block;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 50%;
  top: -4px;
  padding: 16px;
  border: 2px solid rgba(182, 4, 89, 0.168627451);
}
@media (min-width: 360px) {
  .icon-box {
    top: -8px;
    padding: 24px;
  }
}
@media (min-width: 768px) {
  .icon-box {
    top: 40px;
    padding: 44px;
    border-width: 3px;
  }
}
.icon-box svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.4);
  z-index: 1;
}
@media (min-width: 360px) {
  .icon-box svg {
    transform: translate(-50%, -50%) scale(0.6);
  }
}
@media (min-width: 768px) {
  .icon-box svg {
    transform: translate(-50%, -50%);
  }
}

.about-company-section {
  padding: 0;
}

.section-about {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .section-about {
    margin-top: 84px;
    margin-bottom: 42px;
  }
}
.section-about .about-us-content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .section-about .about-us-content {
    margin-top: 40px;
    flex-direction: row;
    gap: 57px;
  }
}
@media (min-width: 768px) {
  .section-about .about-us-content--reverse {
    flex-direction: row-reverse;
  }
}
.section-about .about-us-content + .about-us-content {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .section-about .about-us-content + .about-us-content {
    margin-top: 64px;
  }
}
.section-about .about-text {
  max-width: 478px;
  margin-top: 0;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .section-about .about-text {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
    flex: 1 1.5 auto;
  }
}
.section-about .about-text .highlight {
  font-weight: 400;
  background-color: rgba(236, 81, 154, 0.1019607843);
  padding-left: 4px;
  margin-right: 4px;
}
.section-about .about-text .highlight--no-lm {
  margin-right: 0;
}
.section-about .about-text .highlight--secondary {
  background-color: rgba(235, 77, 151, 0.2588235294);
}
.section-about .about-image,
.section-about .about-image--small {
  min-width: 0;
  width: 100%;
  height: auto;
  max-width: 524px;
  object-fit: contain;
}
.section-about .about-image--small {
  background: #f5f7ff;
  padding: 12px;
  border-radius: 24px;
  max-width: 524px;
}

.about-us-content {
  flex-direction: column !important;
  width: 100% !important;
  max-width: 77ch;
  margin: 0 auto;
  text-wrap: pretty;
}

p.about-text {
  max-width: unset !important;
}

.gallery-slider {
  position: relative;
  overflow: hidden;
  margin-top: 42px;
  margin-bottom: space();
  padding-bottom: 5rem;
}

.gallery {
  align-items: center;
}
.gallery .gallery__link {
  display: inline-block;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.gallery .gallery__link::before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
  border: 9px solid #ffffff;
  box-shadow: 0px 0px 24px 0px rgb(246, 151, 193);
}
.gallery .gallery__link:focus .gallery__image, .gallery .gallery__link:hover .gallery__image {
  transform: scale(1.1);
}
.gallery .gallery__link:focus::before {
  border-color: #ffc702;
}
.gallery .gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transform: scale(1);
  transition: 0.25s ease-in-out transform;
}

button.module-photos__prev,
button.module-photos__next {
  position: absolute;
  top: calc(100% - 50px);
  cursor: pointer;
  border: none;
  opacity: 0.3;
  z-index: 999;
  background-image: none;
  width: 24px;
  height: 36px;
  padding: var(--space-2xs);
  box-sizing: content-box;
  left: 50%;
}
button.module-photos__prev:focus, button.module-photos__prev:hover,
button.module-photos__next:focus,
button.module-photos__next:hover {
  outline: none;
  opacity: 1;
}
button.module-photos__prev:after,
button.module-photos__next:after {
  clip-path: polygon(50% 10%, 70% 10%, 40% 50%, 70% 90%, 50% 90%, 20% 50%);
  position: relative;
  display: block;
  background-color: var(--color-tang-6);
  background-image: none;
  width: 100%;
  height: 100%;
  content: "";
}

.module-photos__prev {
  transform: translateX(calc(-50% - 24px));
}

.module-photos__next {
  transform: translateX(calc(-50% + 24px)) rotate(180deg);
}

.section-employees {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .section-employees {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

.employees-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 32px;
  overflow: hidden;
  padding-bottom: 56px;
}

.employee-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  margin-inline: auto;
}
.employee-item .employee-name {
  margin: 0;
  font-size: 28px;
  line-height: 1;
  font-weight: 500;
}
.employee-item .employee-content {
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  position: relative;
  padding: 42px 24px 24px;
}
.employee-item .employee-content::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 135px;
  height: 109px;
  background-image: url(../images/decorators/quote.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.employee-item .employee-position {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
}
.employee-item .employee-meta {
  margin-top: auto;
  text-align: right;
}
.employee-item .employee-text {
  margin: 0;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.5;
}
.employee-item .employee-image {
  max-width: 180px;
  filter: drop-shadow(0px 16px 40px rgba(112, 144, 176, 0.2));
  margin-bottom: -32px;
}

@media (min-width: 768px) {
  .employees-wrapper::before, .employees-wrapper::after {
    content: "";
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 8vw;
  }
}
@media (min-width: 768px) {
  .employees-wrapper::before {
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(0, 212, 255, 0) 100%);
  }
}
@media (min-width: 768px) {
  .employees-wrapper::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    right: 0;
  }
}
.employees-wrapper .swiper-slide {
  padding: 24px 16px 48px;
}
@media (min-width: 768px) {
  .employees-wrapper .swiper-slide {
    padding: 48px 16px 48px;
  }
}

button.module-employees__prev,
button.module-employees__next {
  position: absolute;
  top: calc(100% - 50px);
  cursor: pointer;
  border: none;
  opacity: 0.3;
  z-index: 999;
  width: 24px;
  height: 36px;
  padding: var(--space-s);
  box-sizing: content-box;
  left: 50%;
}
button.module-employees__prev:focus, button.module-employees__prev:hover,
button.module-employees__next:focus,
button.module-employees__next:hover {
  outline: none;
  opacity: 1;
}
button.module-employees__prev:after,
button.module-employees__next:after {
  clip-path: polygon(50% 10%, 70% 10%, 40% 50%, 70% 90%, 50% 90%, 20% 50%);
  position: relative;
  display: block;
  background-color: var(--color-tang-6);
  background-image: none;
  width: 100%;
  height: 100%;
  content: "";
}

button.swiper-button-disabled {
  opacity: 0.1;
}
button.swiper-button-disabled:focus, button.swiper-button-disabled:hover {
  cursor: auto;
  opacity: 0.1;
}

.module-employees__prev {
  transform: translateX(calc(-50% - 24px));
}

.module-employees__next {
  transform: translateX(calc(-50% + 24px)) rotate(180deg);
}

.section-background {
  padding: 0;
  background-color: #f9f9f9;
}

p,
li,
a {
  font-size: 1.6rem;
  line-height: 1.6;
}
@media (min-width: 768px) {
  p,
li,
a {
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

section.section-about-page {
  padding-top: 0;
}

.row--flex {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.row--flex > div {
  display: inline-flex;
}

@media (min-width: 768px) {
  .section-describe {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
  }
  .section-describe--left {
    right: 0;
    text-align: right;
  }
  .section-describe--left p,
.section-describe--left .btn {
    float: right;
    clear: both;
  }
  .section-describe--right {
    left: 0;
  }
  .section-describe__wrapper {
    position: relative;
  }
  .section-describe__wrapper p {
    width: 400px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .section-wrapper {
    display: flex;
  }
}
.section-wrapper .thumb-wrapper {
  overflow: hidden;
}
@media (min-width: 768px) {
  .section-wrapper .thumb-wrapper--left .composition {
    float: right;
  }
}
.section-wrapper .thumb-wrapper .composition {
  margin: 2.5rem 0;
}

.site-content {
  background-color: var(--color-seal-2);
  overflow: hidden;
}
@media (min-width: 1024px) {
  .site-content--home {
    background-repeat: no-repeat;
    background-position: -196px 0;
    background-size: 592px auto;
  }
}
@media (min-width: 1280px) {
  .site-content--home {
    background-position: 0 0;
  }
}
.site-content--no-hero {
  padding-top: 90px;
}
.site-content--blue-bg {
  background-image: url(../images/bg-home.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}
.site-content--blue-bg.site-content--ckeditor-5-download {
  background: none;
}
.site-content--about-us {
  background-color: var(--color-seal-1);
}
.site-content--about-us, .site-content--careers {
  overflow: visible;
}
.site-content--konkurs, .site-content--konkurs-meetjs, .site-content--konkurs-devjs {
  background-color: var(--color-seal-1);
  border-top: 60px solid var(--color-shark-2);
}
@media (min-width: 768px) {
  .site-content--konkurs, .site-content--konkurs-meetjs, .site-content--konkurs-devjs {
    border-top-width: 120px;
  }
}
.site-content--konkurs > section, .site-content--konkurs-meetjs > section, .site-content--konkurs-devjs > section {
  margin-top: initial;
  margin-bottom: 7.5rem;
}
.site-content--konkurs img, .site-content--konkurs-meetjs img, .site-content--konkurs-devjs img {
  margin-left: 32px;
}
.site-content .odd-grid .row {
  margin-bottom: 2.5rem;
}
.site-content .odd-grid .row:last-of-type {
  margin-bottom: 0;
}
.site-content .odd-grid .col-xs-12 .composition {
  width: 100%;
}
.site-content .odd-grid .col-xs-12 .composition__shape {
  margin-left: 2.5rem;
}
.site-content .odd-grid .col-xs-12:first-of-type p {
  float: right;
  text-align: right;
}
.site-content .odd-grid .col-xs-12:first-of-type h2 {
  text-align: right;
}
.site-content .odd-grid .col-xs-12:first-of-type .composition__shape {
  float: right;
  margin-right: 2.5rem;
  margin-left: 0;
}
.site-content .odd-grid p {
  width: 400px;
  max-width: 100%;
}
.site-content .odd-grid h2 {
  margin-top: 5rem;
}

.composition {
  display: inline-block;
  position: relative;
  margin: 2.5rem 0;
  overflow: hidden;
}
.composition__image {
  position: absolute;
  background-size: cover;
}
.composition__shape {
  max-width: 100%;
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .composition__shape {
    max-width: none;
  }
}

.introduce {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 2.5rem 0 0;
}
.introduce p {
  width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.introduce--thin p {
  width: 400px;
}

.hidden {
  display: none !important;
}

.our-products {
  padding-top: 5rem;
}

.section-life {
  margin-top: 42px;
}

.section-products {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .section-products {
    margin-top: 48px;
  }
}

@media (min-width: 1280px) {
  .site-content--about-us .header picture img {
    width: 100%;
  }
}
.site-content--about-us .cta .module-trustedby__wrapper {
  margin-top: 0;
}
.site-content--about-us .cta__yellow-part h2 {
  color: var(--color-seal-1);
}
.site-content--about-us .cta__yellow-part::after {
  background-color: var(--color-tang-6);
}
.site-content--about-us .cta__yellow-part h2 {
  color: var(--color-seal-1);
}
.site-content--about-us .cta__yellow-part h2::after {
  background-color: var(--color-seal-1);
}
.site-content--about-us .cta__yellow-part-inner {
  background-color: var(--color-tang-6);
}
.site-content--about-us .cta__yellow-part .btn {
  color: var(--color-shark-1);
  background-color: var(--color-comet-5);
}
.site-content--about-us .cta__orange-part::before {
  background-color: var(--color-tang-5);
}
.site-content--about-us .cta__orange-part-inner {
  background-color: var(--color-tang-6);
}
.site-content--about-us .cta__orange-part .btn {
  color: var(--color-shark-1);
  background-color: var(--color-comet-5);
}
.site-content--about-us .cta__orange-part .btn:hover {
  color: var(--color-tang-5);
  background-color: var(--color-seal-1);
}

.careers .section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 0) and (max-width: 767px) {
  .careers .section-container {
    display: none;
  }
}
.careers .section-instagram-cta a {
  text-decoration: none;
}
.careers .section-instagram-cta a .svg-ico__instagram {
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.offers {
  padding-top: 5rem;
  transition: all 600ms ease-in-out;
}
@media (min-width: 0) and (max-width: 767px) {
  .offers .section-container {
    padding: 0;
  }
  .offers h2 {
    margin-left: 15px;
  }
  .offers a {
    font-weight: bold;
  }
}
.offers .no-offers {
  max-width: 100%;
  padding: 2.5rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  background-color: var(--color-seal-1);
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
@media (min-width: 768px) {
  .offers .no-offers {
    width: 90%;
  }
}
@media (min-width: 1280px) {
  .offers .no-offers {
    width: 1100px;
  }
}
.offers__list {
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
  list-style: none;
  margin-top: 2.5rem;
}
.offers__list-item {
  position: relative;
  background-color: var(--color-seal-1);
}
.offers__list-item.accordion__item--active .offers__list-item-header .offer__list-item-bottom {
  display: none;
}
.offers__list-item-content {
  display: none;
}
@media (min-width: 0) and (max-width: 767px) {
  .offers__list-item-content {
    flex-direction: column;
  }
  .offers__list-item-content,
.offers__list-item-content p {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .offers__list-item-content {
    font-size: inherit;
    overflow: visible !important;
  }
}
.accordion__item--active .offers__list-item-content {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 1280px) {
  .accordion__item--active .offers__list-item-content {
    flex-direction: row;
  }
}
.offers__list-item + .offers__list-item {
  margin-top: 1rem;
}
.offers-more {
  margin-top: 5rem;
  text-align: center;
}

.job__side {
  padding-bottom: 2.5rem;
}
@media (min-width: 1280px) {
  .job__side {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .job__side {
    flex: 0 0 340px;
  }
}
.job__apply-form {
  width: 500px;
  max-width: 90%;
  max-height: 0;
  opacity: 0;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  transition: opacity 300ms ease-in 200ms, margin 200ms ease, max-height 300ms ease;
}
.job__apply-form--active {
  opacity: 1;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.job__apply-form--hide {
  display: none;
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -ms-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -ms-animation-duration: 300ms;
  -o-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.job__apply-form legend {
  padding-bottom: 7px;
  font-size: 2.1rem;
  color: var(--color-shark-2);
}
.job__apply-form .form-group {
  margin-bottom: 2.5rem;
}
.job__apply-form .form__label {
  text-align: left;
  width: 100%;
}
.job__apply-btn {
  transition: all 200ms ease-in-out;
}
@media (min-width: 0) and (max-width: 767px) {
  .job__apply-btn {
    max-width: 100%;
  }
}
.job__apply-btn--open {
  display: none;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .contact-destination .section-container {
    width: 940px;
    max-width: 90%;
    margin: 0 auto;
  }
}
.contact-destination .section-header {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.contact-destination .dropdown--huge {
  margin-top: 2.5rem;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
@media (min-width: 768px) {
  .contact-destination .dropdown--huge {
    margin-top: 5rem;
  }
}

.sent-success {
  display: none;
  margin-top: 2.5rem;
  padding: 2.5rem;
  opacity: 0;
  background-color: var(--color-seal-1);
}
.sent-success--active {
  display: block;
  opacity: 1;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 600ms;
  -moz-animation-duration: 600ms;
  -ms-animation-duration: 600ms;
  -o-animation-duration: 600ms;
  animation-duration: 600ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .sent-success .section-container {
    width: 940px;
    max-width: 90%;
    margin: 0 auto;
  }
}

.form-wrapper {
  max-width: 700px;
  margin-inline: auto;
}

.big-form {
  margin-top: 0;
}
.big-form label {
  margin: 0;
}
.big-form .form--hide {
  display: none;
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -ms-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -ms-animation-duration: 300ms;
  -o-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .big-form .section-container {
    width: 940px;
    max-width: 90%;
    margin: 0 auto;
  }
}
.big-form h2 {
  padding-bottom: 2.5rem;
  text-align: center;
}
.big-form h2:after {
  display: none;
}
.big-form .col-xs-12 {
  padding-left: 5px;
  padding-right: 5px;
}
.big-form__required-fields {
  position: absolute;
  font-size: 1.6rem;
  padding-left: 1.25rem;
}
.big-form__required-fields--red {
  color: var(--color-tang-5);
}
.big-form__superscript {
  position: absolute;
  top: -3px;
  right: 0;
  font-size: 11px;
  color: #b08f8f;
}
.big-form .big-form__group {
  width: 100%;
  padding: 1.25rem;
  background-color: var(--color-seal-1);
}
@media (min-width: 768px) {
  .big-form .big-form__group {
    padding: 0.625rem;
    margin-bottom: 10px;
    box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
  }
}
.big-form .big-form__group--flex {
  height: auto;
}
.big-form .big-form__group label {
  padding-left: 1.25rem;
  font-size: 1.6rem;
}
@media (min-width: 0) and (max-width: 767px) {
  .big-form .big-form__group label {
    padding: 0;
  }
}
.big-form .big-form__group label.cv-label {
  position: relative;
  padding-right: 36px;
}
.big-form .big-form__group input[type=text],
.big-form .big-form__group input[type=file],
.big-form .big-form__group input[type=tel],
.big-form .big-form__group input[type=email],
.big-form .big-form__group textarea {
  width: 100%;
  border: 0;
  padding: 0.625rem 1.25rem;
  background-color: color-mix(in srgb, var(--color-tang-5) 5%, var(--color-mix-base, transparent));
  font-size: 1.9rem;
}
.big-form .big-form__group input[type=text]:focus,
.big-form .big-form__group input[type=file]:focus,
.big-form .big-form__group input[type=tel]:focus,
.big-form .big-form__group input[type=email]:focus,
.big-form .big-form__group textarea:focus {
  outline: 1px solid color-mix(in srgb, var(--color-tang-5) 0.5, var(--color-mix-base, transparent));
}
@media (min-width: 0) and (max-width: 767px) {
  .big-form .big-form__group input[type=text],
.big-form .big-form__group input[type=file],
.big-form .big-form__group input[type=tel],
.big-form .big-form__group input[type=email],
.big-form .big-form__group textarea {
    padding: 1rem;
    font-size: 1.9rem;
    border-bottom: 2px solid var(--color-tang-5);
  }
}
.big-form .big-form__group input[type=file] {
  font-size: 1.6rem;
}
@media (min-width: 0) and (max-width: 767px) {
  .big-form .big-form__group input[type=file] {
    padding: 10px;
    border: 2px solid var(--color-tang-5);
    margin-top: 4px;
  }
}
.big-form .big-form__group textarea {
  height: 150px;
  resize: vertical;
}
@media (min-width: 0) and (max-width: 767px) {
  .big-form .big-form__group textarea {
    height: 130px;
    border: 2px solid var(--color-tang-5);
  }
}
.big-form .big-form__group--disabled {
  background-color: var(--color-shark-5);
  cursor: not-allowed;
}
.big-form .big-form__group--disabled input[type=text],
.big-form .big-form__group--disabled input[type=file],
.big-form .big-form__group--disabled input[type=tel],
.big-form .big-form__group--disabled input[type=email],
.big-form .big-form__group--disabled textarea {
  background-color: transparent;
  cursor: not-allowed;
  border-color: color-mix(in srgb, var(--color-tang-5) 0.3, var(--color-mix-base, transparent));
}
.big-form .big-form__group .btn {
  display: block;
  margin: 2.5rem auto;
}
@media (min-width: 0) and (max-width: 767px) {
  .big-form .big-form__group .btn {
    width: 100%;
    margin-top: 5rem;
  }
}

.swal2-title:after {
  display: none;
}

@media (min-width: 1280px) {
  .customers .section-header h2 {
    margin-left: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .customers .section-container {
    width: 940px;
    max-width: 90%;
    margin: 0 auto;
  }
}
.customers__item {
  margin-bottom: 2.5rem;
  padding-top: 110px;
  background-color: var(--color-seal-1);
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
.customers__item:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 0) and (max-width: 359px) {
  .customers__item {
    padding-top: 90px;
  }
}
@media (min-width: 768px) {
  .customers__item {
    padding-top: 145px;
  }
  .customers__item, .customers__item:last-of-type {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1024px) {
  .customers__item {
    padding-top: 190px;
  }
}
@media (min-width: 1280px) {
  .customers__item {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0;
    height: 700px;
    background-size: contain;
  }
  .customers__item:before {
    content: "";
    position: absolute;
    top: 0;
    right: 450px;
    width: 0;
    height: 0;
    z-index: 1;
    border-top: 700px solid var(--color-seal-1);
    border-right: 200px solid transparent;
  }
  .customers__item:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 650px;
    height: 180px;
    background-color: color-mix(in srgb, var(--color-tang-5) 0.8, var(--color-mix-base, transparent));
  }
}
.customers__item-description {
  width: 100%;
  color: var(--color-shark-2);
  z-index: 99;
}
@media (min-width: 0) and (max-width: 767px) {
  .customers__item-description {
    padding-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .customers__item-description {
    font-size: 2.1rem;
  }
}
.customers__item-description-content {
  padding: 2.5rem;
}
@media (min-width: 0) and (max-width: 767px) {
  .customers__item-description-content {
    background-color: var(--color-seal-1);
  }
}
@media (min-width: 1280px) {
  .customers__item-description-content {
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .customers__item-description {
    position: absolute;
    background-color: transparent;
    padding: 2.5rem;
  }
}
.customers__item-description h3 {
  margin: 0;
  padding: 2.5rem;
  line-height: 1;
  color: var(--color-seal-1);
  background-color: color-mix(in srgb, var(--color-tang-5) 0.8, var(--color-mix-base, transparent));
}
@media (min-width: 1280px) {
  .customers__item-description h3 {
    padding: 0;
    margin-top: inherit;
    margin-bottom: 2.5rem;
    line-height: inherit;
    background-color: inherit;
    color: var(--color-shark-1);
    position: relative;
    padding-bottom: 0.5rem;
  }
  .customers__item-description h3:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 3px;
    background-color: var(--color-tang-5);
  }
}
.customers__item-author {
  text-align: right;
  line-height: 2.5rem;
  margin-top: 3.5rem;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .customers__item-author {
    margin-top: 2.5rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1280px) {
  .customers__item-author {
    padding-right: 0;
  }
}
.customers__item-author-name {
  display: block;
  width: 100%;
}
.customers__item-author-profession {
  display: block;
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .customers__item-author-profession {
    font-size: 1.6rem;
  }
}
.customers__item header {
  background-color: transparent;
}
.customers__item header h3,
.customers__item header p {
  margin-bottom: 0;
}
@media (min-width: 1280px) {
  .customers__item header h3 {
    margin-bottom: inherit;
    padding-bottom: 2.5rem;
  }
}
.customers__item header p {
  background-color: var(--color-seal-1);
  margin: 0;
  padding: 2.5rem;
}
@media (min-width: 1280px) {
  .customers__item header p {
    margin-top: 2.5rem;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
  }
}
.customers__item footer {
  padding: 2.5rem;
  background-color: var(--color-shark-5);
  z-index: 99;
}
@media (min-width: 0) and (max-width: 767px) {
  .customers__item footer {
    display: none;
  }
}
@media (min-width: 1280px) {
  .customers__item footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 178px;
  }
}
.customers__item footer h4,
.customers__item footer p {
  margin-bottom: 0;
  margin-top: 1rem;
  color: var(--color-shark-2);
}
.customers__item footer p {
  font-size: 1.4rem;
  margin-top: 0;
}
.customers__item blockquote {
  padding: 1rem 2.5rem;
  margin: 0;
  background-color: var(--color-seal-1);
  font-style: italic;
  color: var(--color-shark-1);
}
@media (min-width: 768px) {
  .customers__item blockquote {
    margin-bottom: 2.5rem;
    line-height: 3rem;
    padding: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .customers__item blockquote {
    background-color: transparent;
    margin-top: 2.5rem;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .customers__item--left {
    background-position: 100% 0;
  }
  .customers__item--left:after {
    right: 0;
    left: auto;
  }
  .customers__item--left .customers__item-description {
    padding-right: 620px;
  }
  .customers__item--left footer {
    padding-right: 650px;
  }
}
@media (min-width: 1280px) {
  .customers__item--right:before {
    left: 451px;
    border-bottom: 700px solid var(--color-seal-1);
    border-left: 200px solid transparent;
    border-top: 0;
    border-right: 0;
  }
  .customers__item--right:after {
    left: 0;
    right: auto;
  }
  .customers__item--right .customers__item-description {
    padding-left: 670px;
    padding-right: 2.5rem;
  }
  .customers__item--right .customers__item-description .customers__item-author {
    padding-right: 0;
  }
  .customers__item--right footer {
    padding-left: 550px;
  }
}
.customers__item--college {
  background-image: url(../images/bg/customers-college-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--college {
    background-image: url(../images/bg/customers-college-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--college {
    background-image: url(../images/bg/customers-college-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--college {
    background-image: url(../images/bg/customers-college.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--college {
    background-image: url(../images/bg/customers-college@2x.jpg);
  }
}
.customers__item--indiegogo {
  background-image: url(../images/bg/customers-indiegogo-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--indiegogo {
    background-image: url(../images/bg/customers-indiegogo-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--indiegogo {
    background-image: url(../images/bg/customers-indiegogo-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--indiegogo {
    background-image: url(../images/bg/customers-indiegogo.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--indiegogo {
    background-image: url(../images/bg/customers-indiegogo@2x.jpg);
  }
}
.customers__item--coremedia {
  background-image: url(../images/bg/customers-coremedia-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--coremedia {
    background-image: url(../images/bg/customers-coremedia-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--coremedia {
    background-image: url(../images/bg/customers-coremedia-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--coremedia {
    background-image: url(../images/bg/customers-coremedia.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--coremedia {
    background-image: url(../images/bg/customers-coremedia@2x.jpg);
  }
}
.customers__item--citi {
  background-image: url(../images/bg/customers-citi-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--citi {
    background-image: url(../images/bg/customers-citi-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--citi {
    background-image: url(../images/bg/customers-citi-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--citi {
    background-image: url(../images/bg/customers-citi.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--citi {
    background-image: url(../images/bg/customers-citi@2x.jpg);
  }
}
.customers__item--government {
  background-image: url(../images/bg/customers-goverment-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--government {
    background-image: url(../images/bg/customers-goverment-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--government {
    background-image: url(../images/bg/customers-goverment-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--government {
    background-image: url(../images/bg/customers-goverment.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--government {
    background-image: url(../images/bg/customers-goverment@2x.jpg);
  }
}
.customers__item--greenrope {
  background-image: url(../images/bg/customers-greenrope-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--greenrope {
    background-image: url(../images/bg/customers-greenrope-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--greenrope {
    background-image: url(../images/bg/customers-greenrope-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--greenrope {
    background-image: url(../images/bg/customers-greenrope.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--greenrope {
    background-image: url(../images/bg/customers-greenrope@2x.jpg);
  }
}
.customers__item--synergy {
  background-image: url(../images/bg/customers-synergy-mo.jpg);
}
@media (min-width: 768px) {
  .customers__item--synergy {
    background-image: url(../images/bg/customers-synergy-ta.jpg);
  }
}
@media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .customers__item--synergy {
    background-image: url(../images/bg/customers-synergy-ta@2x.jpg);
  }
}
@media (min-width: 1280px) {
  .customers__item--synergy {
    background-image: url(../images/bg/customers-synergy.jpg);
  }
}
@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
  .customers__item--synergy {
    background-image: url(../images/bg/customers-synergy@2x.jpg);
  }
}

.site-content--home img[alt="CKEditor logo"],
.site-content--home img[alt="CKBox logo"] {
  max-height: 38px;
}

.section-form {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #fafafa;
}
@media (min-width: 768px) {
  .section-form {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

.contest-box {
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
}
.contest-box::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--color-tang-5);
  opacity: 0.6;
}
.contest-box + .contest-box {
  margin-top: 32px;
}
.contest-box .contest-box__inner {
  position: relative;
  padding: 16px;
  background-color: #fafafa;
}
@media (min-width: 768px) {
  .contest-box .contest-box__inner {
    padding: 32px;
  }
}
.contest-box .contest__section:not(:first-of-type) {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .contest-box .contest__section:not(:first-of-type) {
    margin-top: 48px;
  }
}
.contest-box .contest__text--center {
  text-align: center;
}
.contest-box .contest__text--ma {
  margin: 0 auto;
  max-width: 500px;
}
.contest-box .code {
  display: block;
  padding: 16px 48px;
  position: relative;
  background: rgba(251, 219, 154, 0.1607843137);
  background: linear-gradient(-150deg, transparent 1.5em, rgba(251, 219, 154, 0.1607843137) 0);
  border-radius: 0.5em;
}
.contest-box .code::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(210deg, transparent 50%, rgba(251, 219, 154, 0.1607843137) 0, rgba(251, 219, 154, 0.1607843137) 100%) 100% 0 no-repeat;
  width: 56px;
  height: 32px;
  transform: rotateX(180deg) rotateZ(120deg);
  border-bottom-left-radius: inherit;
  box-shadow: -0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.0015);
}
.contest-box pre {
  margin: 0;
}
.contest-box .contest__separator {
  border-bottom: 1px solid #b4bcc2;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.contest-box .contest__separator--big {
  padding-bottom: 48px;
}
@media (min-width: 768px) {
  .contest-box .contest__separator--big {
    padding-bottom: 30px;
  }
}
.contest-box .contest__award-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.contest-box .contest__award {
  position: relative;
  max-width: 100%;
  max-height: 180px;
  margin: 0;
}
@media (min-width: 768px) {
  .contest-box .contest__award {
    max-height: 360px;
  }
}
.contest-box .contest__header {
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 12px;
  text-align: center;
}
@media (min-width: 768px) {
  .contest-box .contest__header {
    font-size: 3.2rem;
  }
}
.contest-box .contest__header::before, .contest-box .contest__header::after {
  display: none;
}
.contest-box .contest__header span {
  display: block;
  position: relative;
  z-index: 1;
  background-color: #fafafa;
}
@media (min-width: 768px) {
  .contest-box .contest__header span {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.contest-box .contest__header--big {
  font-size: 3.6rem;
}
@media (min-width: 768px) {
  .contest-box .contest__header--big {
    font-size: 4.8rem;
  }
}
.contest-box .contest__product {
  font-weight: bold;
}
.contest-box .contest__tables {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 32px;
  justify-content: center;
}
.contest-box .contest__table {
  max-width: 320px;
  height: 320px;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}
.contest-box .contest__table .highlight {
  background-color: rgba(185, 191, 198, 0.5411764706);
}
.contest-box .contest__table th {
  border: 1px solid #b9bfc6;
}
.contest-box p,
.contest-box a,
.contest-box li {
  font-size: 1.6rem;
}

.contest__list .contest__box + .contest__box {
  margin-top: 16px;
}
.contest__list .contest__title,
.contest__list .contest__person {
  margin: 0;
}
.contest__list .contest__title {
  font-weight: bold;
}
.contest__list .contest__person {
  display: inline-block;
}

.drawing__list {
  list-style-type: none;
  margin-top: 32px;
  padding-left: 0;
}
.drawing__list .drawing__item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .drawing__list .drawing__item {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
}
.drawing__list .drawing__item + .drawing__item {
  margin-top: 48px;
}
@media (min-width: 768px) {
  .drawing__list .drawing__item + .drawing__item {
    margin-top: 32px;
  }
}
.drawing__list .drawing__text {
  font-size: 1.9rem;
  margin: 0;
}
@media (min-width: 768px) {
  .drawing__list .drawing__text {
    font-size: 2.4rem;
    max-width: 300px;
    width: 100%;
  }
}
.drawing__list .drawing__image-box {
  position: relative;
  z-index: 1;
  margin-top: 16px;
  max-width: 300px;
  width: 100%;
}
@media (min-width: 768px) {
  .drawing__list .drawing__image-box {
    margin-top: initial;
  }
}
.drawing__list .drawing__image-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../images/decorators/hex-decorator-item.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 190px;
  height: 200px;
}
.drawing__list .drawing__image {
  margin: 0;
  object-fit: contain;
  max-width: 100%;
  max-height: 160px;
}
@media (min-width: 768px) {
  .drawing__list .drawing__image {
    max-height: 200px;
  }
}
.drawing__list .drawing__image--shirts {
  object-position: 8px;
  margin-left: 22px;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .drawing__list .drawing__image--shirts {
    margin: initial;
  }
}
.drawing__list .drawing__image--headphones {
  margin-top: 24px;
  margin-left: -8px;
}
@media (min-width: 768px) {
  .drawing__list .drawing__image--headphones {
    object-position: -40px;
    margin: initial;
  }
}

section.contest {
  padding-top: 0;
  padding-bottom: 2.5rem;
  border-top: 0;
  background-color: #ffffff;
}
section.contest .menu {
  left: 0;
  width: auto;
}
@media (min-width: 768px) {
  section.contest .menu {
    height: 65px;
  }
}
section.contest .cksource-logo {
  margin-left: 37px;
}
@media (min-width: 768px) {
  section.contest .cksource-logo {
    margin-left: 53px;
  }
}
section.contest .btn-quiz-box {
  padding-top: 24px;
  border-top: 2px solid rgba(0, 134, 204, 0.1098039216);
}

section.contest.contest-meetjs {
  background-color: #391c69;
  color: #ffffff;
}

.decorators {
  position: relative;
}

.decorator {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}
.decorator--left-top-curtain {
  top: 59px;
  left: 0;
  width: 256px;
  height: 384px;
  background-image: url(../images/contest/deco-left-top-curtain.svg);
}
@media (min-width: 768px) {
  .decorator--left-top-curtain {
    top: 127px;
  }
}
.decorator--right-bottom-curtain {
  right: 0;
  bottom: 0;
  width: 329px;
  height: 175px;
  background-image: url(../images/contest/deco-right-bottom-triangle.svg);
}

.contest-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
@media (min-width: 768px) {
  .contest-container {
    flex-direction: row;
  }
}

.quiz-container {
  isolation: isolate;
}

.quiz-content {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.quiz-content h2 {
  padding-bottom: 12px;
  margin-bottom: 0;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .quiz-content h2 {
    margin-bottom: 8px;
  }
}
.quiz-content h2::after {
  content: "";
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100px;
  background-color: rgba(0, 134, 204, 0.1098039216);
}
@media (min-width: 1280px) {
  .quiz-content h2::after {
    width: 172px;
    height: 8px;
  }
}

.quiz-content--meetjs {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 24px 32px;
  border-radius: 8px;
}
.quiz-content--meetjs h2 {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .quiz-content--meetjs h2 {
    font-size: 4.6rem;
  }
}
.quiz-content--meetjs h2::after {
  background-color: rgba(245, 247, 255, 0.2196078431);
}
.quiz-content--meetjs h3 {
  margin: 0;
}
.quiz-content--meetjs p {
  margin-top: 0;
}
.quiz-content--meetjs p + p {
  margin-top: 1.5rem;
}
.quiz-content--meetjs .highlight {
  color: #B3C94F;
}
.quiz-content--meetjs a {
  color: inherit;
  text-decoration: underline;
}

.quiz-end ol {
  margin: 1.5rem 2rem;
  padding: 0;
}
.quiz-end ol li {
  margin: 0;
  padding-left: 4px;
}
.quiz-end ol li:nth-of-type(odd) {
  background-color: rgba(245, 247, 255, 0.2196078431);
}
.quiz-end ol li p {
  line-height: 1.6;
  margin: 0;
}
.quiz-end ol li + li {
  margin-top: 4px;
}
.quiz-end .quiz-end__stand {
  border-radius: 8px;
  border: 12px solid white;
  margin-block: 32px;
}
.quiz-end .quiz-end__stand img {
  width: 100%;
  height: auto;
}
.quiz-end .quiz-end__stand figcaption {
  background-color: #634c8b;
  text-align: center;
  line-height: 1.6;
  padding-block: 4px;
}
.quiz-end .quiz-participants + .quiz-participants {
  margin-top: 32px;
}

.quiz-prize {
  text-align: center;
  margin-top: 48px;
}
.quiz-prize .contest__award {
  max-width: 520px;
  width: 100%;
}

.quiz-card {
  display: none;
  position: fixed;
  overflow: hidden;
  z-index: 1000001;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  max-width: 640px;
  width: 100%;
  background-color: #ffffff;
}

.quiz-card-inner {
  overflow: hidden auto;
}

.quiz-card--active {
  display: block;
}

p.quiz-necessary,
p.quiz-last-step,
p.quiz-score,
p.quiz-progress-title {
  text-align: center;
  font-size: 24px;
  margin: 0;
  line-height: 1.2;
  padding: 16px;
}
@media (min-width: 768px) {
  p.quiz-necessary,
p.quiz-last-step,
p.quiz-score,
p.quiz-progress-title {
    font-size: 32px;
  }
}
p.quiz-necessary--hide,
p.quiz-last-step--hide,
p.quiz-score--hide,
p.quiz-progress-title--hide {
  display: none;
}

.quiz-form {
  position: relative;
  transition: 0.25s ease-in-out height;
  min-height: initial;
  margin: 0 16px 8px;
  height: calc(var(--vh, 1vh) * 75);
}
.quiz-form input[type=file] {
  display: flex;
  align-items: center;
  font-size: 16px !important;
}
@media (min-width: 768px) {
  .quiz-form {
    height: 550px;
  }
}
.quiz-form .form__label--checkbox {
  font-size: 14px;
  line-height: 1.3;
}

.btn-start-quiz {
  margin-top: 8px;
  width: 100%;
}

@media (min-width: 768px) {
  .quiz-form--questions {
    height: 360px;
  }
}

.quiz-results,
.quiz-row-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(calc(-100% - 16px));
  transition: 0.25s ease-in-out transform;
}

.quiz-row-box {
  visibility: hidden;
}
.quiz-row-box .quiz-row {
  height: 100%;
}

.quiz-row {
  display: flex;
  flex-direction: column;
}

.quiz-row-box--active {
  transform: translateX(0);
  visibility: visible;
}
.quiz-row-box--active ~ .quiz-row-box {
  transform: translateX(calc(100% + 16px));
}

.quiz-question {
  display: inline-block;
  text-align: center;
  background-color: rgba(0, 134, 204, 0.1098039216);
  color: #000000;
  padding: 4px 16px;
  margin-top: 4px;
  margin-bottom: 8px;
  max-height: 240px;
  overflow: auto;
}
@media (min-width: 768px) {
  .quiz-question {
    padding: 4px;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
.quiz-question pre {
  margin: 0;
}
.quiz-question code {
  display: block;
  text-align: left;
  font-size: 13px;
  line-height: 1.4;
  background: #423e62;
  margin: 8px;
  padding: 8px 12px;
  color: #fff;
}

.quiz-answers {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
}
@media (min-width: 768px) {
  .quiz-answers {
    margin-top: auto;
    margin-left: -4px;
    margin-right: -4px;
    flex-direction: row;
    flex-grow: initial;
  }
}

.quiz-answer {
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .quiz-answer {
    flex-grow: initial;
    width: 50%;
  }
}

.quiz-answer-row {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #f382b6;
  width: 100%;
}
@media (min-width: 768px) {
  .quiz-answer-row {
    display: block;
  }
}
.quiz-answer-row input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.quiz-answer-row .quiz-label {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.2;
  position: relative;
  margin: 0;
  padding: 8px;
  width: 100%;
}
@media (min-width: 768px) {
  .quiz-answer-row .quiz-label {
    font-size: 17px;
    text-align: left;
    min-height: 80px;
    padding: 4px 12px 4px 32px;
  }
}
@media (min-width: 768px) {
  .quiz-answer-row .quiz-label::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 8px;
    border: 2px solid #f382b6;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transition: 0.25s ease-in-out background-color;
    background-color: transparent;
  }
}
.quiz-answer-row .quiz-label:hover {
  text-decoration: underline;
}
.quiz-answer-row .quiz-label:hover::before {
  background-color: #f382b6;
}
.quiz-answer-row input:checked + .quiz-label::before {
  background-color: #f382b6;
}

.quiz-result {
  display: none;
  position: relative;
  visibility: hidden;
  margin-top: 24px;
  height: 100%;
}
.quiz-result .quiz-result-content {
  max-width: 460px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}
.quiz-result .quiz-result-content .points-box {
  display: inline-block;
  margin-top: 8px;
  padding: 4px 8px;
  background-color: rgba(0, 134, 204, 0.1098039216);
  color: #000;
}
.quiz-result.quiz-row-box--active {
  margin-top: 0;
  display: block;
  visibility: visible;
}
.quiz-result .points-result {
  text-align: center;
  font-size: 32px;
  line-height: 1.2;
  margin: 0;
  padding: 8px 8px 16px;
  border-bottom: 2px solid rgba(0, 134, 204, 0.1098039216);
}
.quiz-result .points-result > span {
  display: block;
}
@media (min-width: 768px) {
  .quiz-result .points-result > span {
    display: inline;
  }
}
.quiz-result .btn-close-quiz {
  position: absolute;
  top: 16px;
  right: 8px;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
}
.quiz-result .btn-close-quiz::before, .quiz-result .btn-close-quiz::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  width: 90%;
  height: 2px;
  background-color: #000000;
}
.quiz-result .btn-close-quiz::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.quiz-take-action {
  display: none;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 32px;
}
.quiz-take-action.quiz-row-box--active {
  display: flex;
}
.quiz-take-action .text {
  font-size: 22px;
  line-height: 1.3;
  max-width: 380px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .quiz-take-action .text {
    margin-bottom: auto;
  }
}

.quiz-result-box,
.quiz-user-box {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transform: translateX(calc(100% + 16px));
  transition: 0.25s ease-in-out transform;
  padding-left: 8px;
  padding-right: 8px;
  display: none;
  visibility: hidden;
}
.quiz-result-box.quiz-row-box--active,
.quiz-user-box.quiz-row-box--active {
  display: flex;
  margin-top: 0;
  transform: translateX(0);
  visibility: visible;
}

.quiz-result-image {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.quiz-result-icon {
  margin-top: 1.5rem;
  max-width: 100%;
}

.user-box-active {
  transition: 0.25s ease-in-out height;
}
@media (min-width: 768px) {
  .user-box-active {
    height: 75vh;
  }
}

.quiz-action {
  padding-bottom: 24px;
}

.questions-answers {
  margin-top: 12px;
  max-height: 80%;
  padding: 0 16px 24px;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .questions-answers {
    max-height: 410px;
    padding: 0;
  }
}
.questions-answers .qst,
.questions-answers .ans {
  margin: 0;
}
.questions-answers .ans + .qst {
  margin-top: 16px;
}
.questions-answers .qst {
  line-height: 1.2;
  font-size: 15px;
  font-weight: bold;
}
.questions-answers .ansrs {
  list-style-position: inside;
  list-style-type: lower-alpha;
  margin: 2px 0 8px;
  padding-left: 8px;
}
.questions-answers .ans {
  font-size: 15px;
  line-height: 1.6;
  padding: 0 8px;
}
.questions-answers .ans--checked {
  background-color: rgba(204, 0, 0, 0.1098039216);
}
.questions-answers .ans--correct {
  background-color: rgba(0, 204, 62, 0.1098039216);
}

.team .section-container {
  width: 940px;
  margin: 0 auto 2.5rem;
  max-width: 100%;
  padding: 0 2.5rem;
}
@media (min-width: 1280px) {
  .team .section-container {
    width: 1250px;
    padding: 0 0.5rem;
    justify-content: flex-start;
  }
}
@media (min-width: 1680px) {
  .team .section-container {
    width: 1560px;
  }
}
@media (min-width: 768px) {
  .team h2 {
    margin-bottom: 5rem;
  }
}
.team-member {
  margin: 0.5rem;
  width: 151px;
  color: var(--color-seal-1);
  overflow: hidden;
  background-color: var(--color-seal-1);
  box-shadow: 0 8px 27px rgba(85, 124, 153, 0.15);
  transition: all 200ms ease-in-out;
}
@media (min-width: 1024px) {
  .team-member {
    width: 302px;
  }
}
@media (min-width: 1680px) {
  .team-member {
    width: 287px;
  }
}
.team-member--hidden {
  display: none;
}
.team-member p {
  font-size: inherit;
  margin: 0;
}
@media (min-width: 1280px) {
  .team-member h3 {
    font-size: 2.6rem;
  }
  .team-member h4 {
    font-size: 2rem;
  }
}
.team-member__outer {
  padding: 0.5rem;
}
.team-member__image {
  position: absolute;
  left: 0;
  top: 0;
}
.team-member__image img {
  max-width: 100%;
}
.team-member:hover:after, .team-member:focus:after, .team-member--selected:after {
  background-color: transparent;
}
.team-member:hover .team-member__inner, .team-member:focus .team-member__inner, .team-member--selected .team-member__inner {
  opacity: 1;
}
@media (min-width: 1024px) {
  .team-member:hover .team-member__description, .team-member:focus .team-member__description, .team-member--selected .team-member__description {
    opacity: 1;
  }
}
.team-member:hover .team-member__link, .team-member:focus .team-member__link, .team-member--selected .team-member__link {
  opacity: 1;
}
.team-member:hover .team-member__link:nth-of-type(1), .team-member:focus .team-member__link:nth-of-type(1), .team-member--selected .team-member__link:nth-of-type(1) {
  transition: opacity 300ms ease-in-out 250ms, transform 200ms ease;
}
.team-member:hover .team-member__link:nth-of-type(2), .team-member:focus .team-member__link:nth-of-type(2), .team-member--selected .team-member__link:nth-of-type(2) {
  transition: opacity 300ms ease-in-out 350ms, transform 200ms ease;
}
.team-member:hover .team-member__link:nth-of-type(3), .team-member:focus .team-member__link:nth-of-type(3), .team-member--selected .team-member__link:nth-of-type(3) {
  transition: opacity 300ms ease-in-out 450ms, transform 200ms ease;
}
.team-member:hover .team-member__link:nth-of-type(4), .team-member:focus .team-member__link:nth-of-type(4), .team-member--selected .team-member__link:nth-of-type(4) {
  transition: opacity 300ms ease-in-out 550ms, transform 200ms ease;
}
.team-member:hover .team-member__link:nth-of-type(5), .team-member:focus .team-member__link:nth-of-type(5), .team-member--selected .team-member__link:nth-of-type(5) {
  transition: opacity 300ms ease-in-out 650ms, transform 200ms ease;
}
.team-member:hover--selected .team-member__link, .team-member:focus--selected .team-member__link, .team-member--selected--selected .team-member__link {
  opacity: 1;
}
.team-member:hover--selected .team-member__link:nth-of-type(1), .team-member:hover--selected .team-member__link:nth-of-type(2), .team-member:hover--selected .team-member__link:nth-of-type(3), .team-member:hover--selected .team-member__link:nth-of-type(4), .team-member:hover--selected .team-member__link:nth-of-type(5), .team-member:focus--selected .team-member__link:nth-of-type(1), .team-member:focus--selected .team-member__link:nth-of-type(2), .team-member:focus--selected .team-member__link:nth-of-type(3), .team-member:focus--selected .team-member__link:nth-of-type(4), .team-member:focus--selected .team-member__link:nth-of-type(5), .team-member--selected--selected .team-member__link:nth-of-type(1), .team-member--selected--selected .team-member__link:nth-of-type(2), .team-member--selected--selected .team-member__link:nth-of-type(3), .team-member--selected--selected .team-member__link:nth-of-type(4), .team-member--selected--selected .team-member__link:nth-of-type(5) {
  transition: transform 200ms ease;
}
.team-member:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 151px;
  height: 151px;
  background-color: color-mix(in srgb, var(--color-tang-7) 5%, var(--color-mix-base, transparent));
  transition: background-color 200ms ease-in-out;
  z-index: 99;
}
@media (min-width: 1024px) {
  .team-member:after {
    width: 302px;
    height: 302px;
  }
}
.team-member__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 151px;
  height: 151px;
  bottom: 0;
  padding: 1em;
  background-color: color-mix(in srgb, var(--color-tang-6) 80%, var(--color-mix-base, transparent));
  text-align: center;
  color: var(--color-seal-1);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 999;
}
@media (min-width: 1024px) {
  .team-member__inner {
    width: 302px;
    height: 302px;
  }
}
.team-member__description {
  position: absolute;
  display: none;
  bottom: 5rem;
  left: 0;
  padding: 2.5rem;
  text-align: center;
  width: 100%;
  text-transform: none;
  line-height: 2rem;
  font-weight: 600;
  font-size: 1.4rem;
  opacity: 0;
  transition: opacity 200ms ease;
  transition-delay: 300ms;
}
@media (min-width: 1024px) {
  .team-member__description {
    display: block;
  }
}
.team-member__introduce {
  padding-bottom: 1rem;
  background-color: var(--color-seal-1);
  color: var(--color-shark-1);
  text-align: center;
  line-height: 1.2;
  padding: var(--space-2xs);
}
.team-member__introduce h3,
.team-member__introduce h4 {
  letter-spacing: 0;
}
.team-member__introduce h3 {
  display: inline-block;
  margin: 0;
  font-size: var(--step-1);
  font-weight: 600;
  line-height: 2.2;
}
.team-member__introduce h4 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1024px) {
  .team-member__introduce {
    padding: 1.25rem;
  }
  .team-member__introduce h3 {
    font-size: 2rem;
  }
  .team-member__introduce h4 {
    font-size: 1.7rem;
    line-height: inherit;
  }
}
.team-member__link {
  display: inline-block;
  opacity: 0;
  pointer-events: none;
}
.team-member__link--active {
  pointer-events: auto;
}
@media (min-width: 768px) {
  .team-member__link {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .team-member__link {
    margin: 0;
  }
}
@media (min-width: 0) and (max-width: 767px) {
  .team-member__link {
    width: 50%;
    margin: 5px 0;
  }
}
.team-member__link:last-of-type {
  margin-right: 0;
}
.team-member__link:hover, .team-member__link:focus {
  transform: scale(1.1);
  outline: none;
}
.team-member__link-wrapper {
  position: relative;
  display: block;
  top: 50%;
  left: 0;
  height: auto;
  transform: translateY(-50%);
  text-align: center;
  z-index: 999;
}
@media (min-width: 1024px) {
  .team-member__link-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    left: 50%;
    bottom: 10px;
    top: auto;
    transform: translateX(-50%);
  }
}
.team-member__link:nth-of-type(1), .team-member__link:nth-of-type(2), .team-member__link:nth-of-type(3), .team-member__link:nth-of-type(4), .team-member__link:nth-of-type(5) {
  transition: transform 200ms ease;
}
.header--404 {
  background-color: var(--color-tropical-7);
}
.header--404:after {
  display: none;
}
@media (min-width: 768px) {
  .header--404:after {
    content: "";
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0 0 20vw 100vw;
    border-color: transparent transparent var(--color-seal-1) transparent;
    left: 0;
    bottom: 0;
    display: block;
  }
}
@media (min-width: 1921px) {
  .header--404:after {
    border-width: 0 0 10vw 100vw;
  }
}
.header--404 .header-cover {
  display: none;
}
.header--404 .header-content {
  position: absolute;
  left: 2.5rem;
  bottom: 20px;
  width: 80%;
  z-index: 999;
}
@media (min-width: 768px) {
  .header--404 .header-content {
    left: 5rem;
    width: 760px;
    max-width: calc(100% - 100px - 25px);
    left: 100px;
    bottom: 150px;
  }
}
.header--404 .header-content h1 {
  font-size: 10rem;
  line-height: 1;
  padding-bottom: 10px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .header--404 .header-content h1 {
    font-size: 15rem;
  }
}

.footer--404 {
  display: none;
}

.site-content--404 {
  background-color: var(--color-seal-1);
  padding: 30px 20px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .site-content--404 {
    padding: 0;
  }
}
.site-content--404 section {
  position: relative;
  margin: 0;
}
@media (min-width: 768px) {
  .site-content--404 section {
    width: 800px;
    max-width: calc(100% - 100px - 25px);
    left: 100px;
  }
}
@media (min-width: 1280px) {
  .site-content--404 section {
    left: 50%;
    margin-left: -135px;
    transform: translateX(-50%);
  }
}
@media (min-width: 1680px) {
  .site-content--404 section {
    margin-left: -358px;
  }
}
.site-content--404 .page-404__heading {
  font-size: 3rem;
  margin-bottom: 2.5rem;
  font-weight: bold;
  color: var(--color-shark-1);
}
.site-content--404 .page-404__content p {
  font-weight: bold;
  color: var(--color-shark-1);
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 5px;
}
.site-content--404 .page-404__footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .site-content--404 .page-404__footer {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
.site-content--404 .page-404__footer .svg-ico {
  display: inline-block;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
}
.site-content--404 .page-404__footer .svg-ico:hover {
  opacity: 0.7;
}

:root {
  --font-family: Mulish, system-ui, -apple-system, sans-serif;
  --font-family-italic: MulishItalic, system-ui, -apple-system, sans-serif;
  --font-family-mono: Consolas, Monaco, "Andale Mono", "Ubuntu Mono",
  	ui-monospace, monospace;
  --body-line-height: 1.4;
  --dense-text-line-height: 1.55;
  --headline-line-height: 1.1;
  --color-seal-1: hsl(0, 0%, 100%);
  --color-seal-2: hsl(228, 100%, 98%);
  --color-seal-3: hsl(227, 66%, 95%);
  --color-tang-hs: 263, 59%;
  --color-tang-4: hsl(var(--color-tang-hs), 92%);
  --color-tang-5: hsl(var(--color-tang-hs), 52%);
  --color-tang-6: hsl(var(--color-tang-hs), 40%);
  --color-tang-7: hsl(var(--color-tang-hs), 26%);
  --color-tang-8: hsl(var(--color-tang-hs), 10%);
  --color-shark-0: hsl(0, 0%, 2%);
  --color-shark-1: hsl(219, 100%, 10%);
  --color-shark-2: hsl(214, 4%, 36%);
  --color-shark-3: hsl(213, 21%, 70%);
  --color-shark-4: hsl(210, 22%, 91%);
  --color-shark-5: hsl(210, 17%, 98%);
  --color-comet-hs: 77, 99%;
  --color-comet-3: hsl(var(--color-comet-hs), 78%);
  --color-comet-5: hsl(var(--color-comet-hs), 63%);
  --color-comet-6: hsl(var(--color-comet-hs), 45%);
  --color-comet-7: hsl(var(--color-comet-hs), 35%);
  --color-reef-1: hsl(189, 57%, 91%);
  --color-reef-2: hsl(16, 100%, 96%);
  --color-reef-3: hsl(201, 83%, 95%);
  --color-reef-4: hsl(234, 100%, 96%);
  --color-reef-5: hsl(78, 52%, 94%);
  --color-reef-6: hsl(345, 60%, 96%);
  --color-tropical-1: hsl(53, 100%, 43%);
  --color-tropical-2: hsl(36, 100%, 50%);
  --color-tropical-3: hsl(17, 100%, 57%);
  --color-tropical-4: hsl(346, 100%, 57%);
  --color-tropical-5: hsl(328, 100%, 49%);
  --color-tropical-6: hsl(307, 93%, 41%);
  --color-tropical-7: hsl(265, 90%, 51%);
  --color-arctic-1: hsl(208, 100%, 18%);
  --color-arctic-2: hsl(200, 100%, 27%);
  --color-arctic-3: hsl(188, 100%, 31%);
  --color-arctic-4: hsl(168, 100%, 36%);
  --color-arctic-5: hsl(140, 72%, 55%);
  --transition-time-1: 0.2s;
  --transition-time-2: 0.35s;
  --shadow-1: 0 0 var(--space-m) var(--space-4xs) rgba(0, 0, 0, 0.1);
  --shadow-2: 0 0 var(--space-2xs) var(--space-4xs) rgba(0, 0, 0, 0.07);
  --dropshadow-1: 0 0 var(--space-2xs) rgba(0, 0, 0, 0.085);
  --gradient-1: linear-gradient(
  	122.1deg,
  	var(--color-tang-8) 32.29%,
  	var(--color-tang-7) 70.55%
  );
  --gradient-2: linear-gradient(
  	122.1deg,
  	var(--color-comet-5) 32.29%,
  	var(--color-comet-6) 70.55%
  );
  --gradient-3: linear-gradient(
  	122.1deg,
  	transparent 32.29%,
  	color-mix(in srgb, var(--color-tang-7) 70%, var(--color-mix-base, transparent)) 70.55%
  );
  --gradient-promo: linear-gradient(
  	130deg,
  	var(--color-comet-6),
  	var(--color-tropical-1),
  	var(--color-tropical-2),
  	var(--color-tropical-3),
  	var(--color-tropical-4),
  	var(--color-tropical-5),
  	var(--color-tropical-6),
  	var(--color-tang-6)
  );
  --gradient-arctic: linear-gradient(
  	90deg,
  	var(--color-tang-8),
  	var(--color-arctic-1),
  	var(--color-arctic-2),
  	var(--color-arctic-3),
  	var(--color-arctic-4),
  	var(--color-arctic-5),
  	var(--color-comet-5)
  );
  /* made at https://learnui.design/tools/gradient-generator.html */
  --gradient-polar-dusk: linear-gradient(
  	180.21deg,
  	var(--color-shark-0) 49.74%,
  	var(--color-tropical-7) 115.87%
  );
  --color-neo-comet-5: hsl(77, 76%, 52%);
  --color-neon-5: hsl(162, 70%, 49%);
  --color-neo-tang-5: hsl(264, 100%, 55%);
  --gradient-neo: linear-gradient(
  	-90deg,
  	var(--color-neo-comet-5),
  	var(--color-neon-5),
  	var(--color-neo-tang-5)
  );
  --z-index-cookies: 1000;
  --z-index-popup: 100;
  --z-index-header: 2000;
  --z-index-chat: 2500;
  --z-index-overlay: 3000;
  --z-index-lightbox: 4000;
  --header-height: clamp(5rem, 4.4118rem + 2.9412vw, 7rem);
  --scroll-top: calc(var(--header-height) + var(--space-s));
}

/* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1408,24,1.25,5,2,&s=0.65|0.5|0.35|0.15,1.35|1.65|2.65|4|6,s-l */
:root {
  --space-4xs: clamp(0.13rem, calc(0.09rem + 0.18vw), 0.25rem);
  --space-3xs: clamp(0.38rem, calc(0.34rem + 0.18vw), 0.5rem);
  --space-2xs: clamp(0.5rem, calc(0.43rem + 0.37vw), 0.75rem);
  --space-xs: clamp(0.63rem, calc(0.51rem + 0.55vw), 1rem);
  --space-s: clamp(1rem, calc(0.85rem + 0.74vw), 1.5rem);
  --space-m: clamp(1.38rem, calc(1.19rem + 0.92vw), 2rem);
  --space-l: clamp(1.63rem, calc(1.37rem + 1.29vw), 2.5rem);
  --space-xl: clamp(2.63rem, calc(2.22rem + 2.02vw), 4rem);
  --space-2xl: clamp(4rem, calc(3.41rem + 2.94vw), 6rem);
  --space-3xl: clamp(6rem, calc(5.12rem + 4.41vw), 9rem);
  /* One-up pairs */
  /* Custom pairs */
}

/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,1408,16,1.333,4,1,&s=0.65|0.5|0.35|0.15,1.35|1.65|2|2.65|3.35|4|6.65,s-l */
:root {
  --step-4: clamp(1.6rem, calc(1.14rem + 2.29vw), 3.16rem);
  --step-3: clamp(1.42rem, calc(1.15rem + 1.39vw), 2.37rem);
  --step-2: clamp(1.27rem, calc(1.12rem + 0.75vw), 1.78rem);
  --step-1: clamp(1.13rem, calc(1.06rem + 0.31vw), 1.33rem);
  --step-0: clamp(1rem, calc(1rem + 0vw), 1rem);
  --step--1: clamp(0.75rem, calc(0.93rem + -0.2vw), 0.89rem);
}

.l-p-4xs {
  padding: var(--space-4xs);
}
.l-pl-4xs {
  padding-left: var(--space-4xs);
}
.l-pr-4xs {
  padding-right: var(--space-4xs);
}
.l-ph-4xs {
  padding-left: var(--space-4xs);
  padding-right: var(--space-4xs);
}
.l-pt-4xs {
  padding-top: var(--space-4xs);
}
.l-pb-4xs {
  padding-bottom: var(--space-4xs);
}
.l-pv-4xs {
  padding-top: var(--space-4xs);
  padding-bottom: var(--space-4xs);
}
.l-p-3xs {
  padding: var(--space-3xs);
}
.l-pl-3xs {
  padding-left: var(--space-3xs);
}
.l-pr-3xs {
  padding-right: var(--space-3xs);
}
.l-ph-3xs {
  padding-left: var(--space-3xs);
  padding-right: var(--space-3xs);
}
.l-pt-3xs {
  padding-top: var(--space-3xs);
}
.l-pb-3xs {
  padding-bottom: var(--space-3xs);
}
.l-pv-3xs {
  padding-top: var(--space-3xs);
  padding-bottom: var(--space-3xs);
}
.l-p-2xs {
  padding: var(--space-2xs);
}
.l-pl-2xs {
  padding-left: var(--space-2xs);
}
.l-pr-2xs {
  padding-right: var(--space-2xs);
}
.l-ph-2xs {
  padding-left: var(--space-2xs);
  padding-right: var(--space-2xs);
}
.l-pt-2xs {
  padding-top: var(--space-2xs);
}
.l-pb-2xs {
  padding-bottom: var(--space-2xs);
}
.l-pv-2xs {
  padding-top: var(--space-2xs);
  padding-bottom: var(--space-2xs);
}
.l-p-xs {
  padding: var(--space-xs);
}
.l-pl-xs {
  padding-left: var(--space-xs);
}
.l-pr-xs {
  padding-right: var(--space-xs);
}
.l-ph-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}
.l-pt-xs {
  padding-top: var(--space-xs);
}
.l-pb-xs {
  padding-bottom: var(--space-xs);
}
.l-pv-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}
.l-p-s {
  padding: var(--space-s);
}
.l-pl-s {
  padding-left: var(--space-s);
}
.l-pr-s {
  padding-right: var(--space-s);
}
.l-ph-s {
  padding-left: var(--space-s);
  padding-right: var(--space-s);
}
.l-pt-s {
  padding-top: var(--space-s);
}
.l-pb-s {
  padding-bottom: var(--space-s);
}
.l-pv-s {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
}
.l-p-m {
  padding: var(--space-m);
}
.l-pl-m {
  padding-left: var(--space-m);
}
.l-pr-m {
  padding-right: var(--space-m);
}
.l-ph-m {
  padding-left: var(--space-m);
  padding-right: var(--space-m);
}
.l-pt-m {
  padding-top: var(--space-m);
}
.l-pb-m {
  padding-bottom: var(--space-m);
}
.l-pv-m {
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);
}
.l-p-l {
  padding: var(--space-l);
}
.l-pl-l {
  padding-left: var(--space-l);
}
.l-pr-l {
  padding-right: var(--space-l);
}
.l-ph-l {
  padding-left: var(--space-l);
  padding-right: var(--space-l);
}
.l-pt-l {
  padding-top: var(--space-l);
}
.l-pb-l {
  padding-bottom: var(--space-l);
}
.l-pv-l {
  padding-top: var(--space-l);
  padding-bottom: var(--space-l);
}
.l-p-xl {
  padding: var(--space-xl);
}
.l-pl-xl {
  padding-left: var(--space-xl);
}
.l-pr-xl {
  padding-right: var(--space-xl);
}
.l-ph-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}
.l-pt-xl {
  padding-top: var(--space-xl);
}
.l-pb-xl {
  padding-bottom: var(--space-xl);
}
.l-pv-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}
.l-p-2xl {
  padding: var(--space-2xl);
}
.l-pl-2xl {
  padding-left: var(--space-2xl);
}
.l-pr-2xl {
  padding-right: var(--space-2xl);
}
.l-ph-2xl {
  padding-left: var(--space-2xl);
  padding-right: var(--space-2xl);
}
.l-pt-2xl {
  padding-top: var(--space-2xl);
}
.l-pb-2xl {
  padding-bottom: var(--space-2xl);
}
.l-pv-2xl {
  padding-top: var(--space-2xl);
  padding-bottom: var(--space-2xl);
}
.l-p-3xl {
  padding: var(--space-3xl);
}
.l-pl-3xl {
  padding-left: var(--space-3xl);
}
.l-pr-3xl {
  padding-right: var(--space-3xl);
}
.l-ph-3xl {
  padding-left: var(--space-3xl);
  padding-right: var(--space-3xl);
}
.l-pt-3xl {
  padding-top: var(--space-3xl);
}
.l-pb-3xl {
  padding-bottom: var(--space-3xl);
}
.l-pv-3xl {
  padding-top: var(--space-3xl);
  padding-bottom: var(--space-3xl);
}

.l-wrapper {
  width: min(100% - var(--space-xl), var(--l-wrapper-max, 88rem));
  margin-inline: auto;
}

.l-grid {
  display: grid;
  grid-template-columns: repeat(var(--l-grid-placement, auto-fill), minmax(var(--l-grid-min-item-size, 16rem), var(--l-grid-max-item-size, 1fr)));
  gap: var(--l-grid-gap, var(--space-s));
}

.l-grid--50-50 {
  --l-grid-placement: auto-fit;
  --l-grid-min-item-size: clamp(16rem, 50vw, 26rem);
}

.l-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--l-group-gap, var(--space-xs));
}

.l-group--xl {
  --l-group-gap: var(--space-xl);
}

.l-group--m {
  --l-group-gap: var(--space-m);
}

.l-group--2xs {
  --l-group-gap: var(--space-2xs);
}

.l-group--4xs {
  --l-group-gap: var(--space-4xs);
}

.l-group--center {
  align-items: center;
}

.l-group-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--l-group-gap, var(--space-xs));
}

.c-card {
  --c-card-background-color: var(--color-seal-2);
  display: flex;
  position: relative;
  gap: var(--space-xs);
  transition-duration: var(--transition-time-1);
  transition-property: box-shadow;
  border-radius: var(--space-xs);
  background-color: var(--c-card-background-color);
  padding: var(--c-card-padding, var(--space-m));
  color: var(--color-shark-1);
}

.c-card--highlight {
  --c-card-background-color: var(--color-seal-1);
  box-shadow: 0 0 0 var(--space-4xs) var(--color-tang-5);
}

.c-card--outline {
  --c-card-background-color: var(--color-seal-1);
  box-shadow: 0 0 0 1px var(--color-shark-4);
}

.c-card--review .c-card__main {
  gap: var(--space-xs);
}
.c-card--review .c-card__text {
  font-style: italic;
  font-family: var(--font-family-italic);
}
.c-card--review .c-sprite-icon--ui-quote-slim {
  position: absolute;
  top: var(--space-s);
  right: var(--space-2xs);
  opacity: 0.15;
}

.c-card--fill-trans {
  --c-card-background-color: transparent;
}

.c-card--reef-1 {
  --c-card-background-color: var(--color-reef-1);
}

.c-card--reef-2 {
  --c-card-background-color: var(--color-reef-2);
}

.c-card--reef-3 {
  --c-card-background-color: var(--color-reef-3);
}

.c-card--reef-4 {
  --c-card-background-color: var(--color-reef-4);
}

.c-card--reef-5 {
  --c-card-background-color: var(--color-reef-5);
}

.c-card--reef-6 {
  --c-card-background-color: var(--color-reef-6);
}

.c-card--seal-3 {
  --c-card-background-color: var(--color-shark-5);
}

.c-card--comet-3 {
  --c-card-background-color: var(--color-comet-3);
}

.c-card--interactive:hover {
  box-shadow: var(--shadow-2);
}

.c-card--center {
  align-items: center;
}

.c-card__main {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.c-card__aside,
.c-card__header,
.c-card__footer {
  flex-shrink: 0;
}

.c-card__text {
  flex-grow: 1;
}

.c-card__text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}

.c-card__text-clamp--2 {
  -webkit-line-clamp: 2;
}

.c-card__text-clamp--3 {
  -webkit-line-clamp: 3;
}

.c-card__link {
  --a-color: var(--color-shark-1);
  display: inline-block;
}

.c-card__badge {
  position: absolute;
  top: var(--space-4xs);
  left: var(--space-4xs);
}

.c-cta {
  /**
    * :focus-visible
    * https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
    */
  /**
    * Provide a fallback style for browsers that don't support :focus-visible
    */
  /**
    * Draw a very noticeable focus style for keyboard-focus on browsers that do support :focus-visible
    */
  /**
    * Remove the focus indicator on mouse-focus for browsers that do support :focus-visible
    */
  --c-cta-border-color: transparent;
  display: inline-block;
  transition-duration: var(--transition-time-1);
  transition-property: background-color, color;
  cursor: pointer;
  border: 1px solid var(--c-cta-border-color);
  border-radius: var(--space-3xs);
  background-color: var(--c-cta-background-color);
  color: var(--c-cta-color);
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border-radius: var(--space-3xl);
  padding: var(--space-s) var(--space-xl);
}
.c-cta:focus {
  transition-property: box-shadow;
  transition-duration: var(--transition-time-1);
  outline: 0;
  box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) var(--color-seal-1), 0 0 0 var(--space-4xs) var(--color-tang-5);
}
.c-cta:focus-visible {
  transition-property: box-shadow;
  transition-duration: var(--transition-time-1);
  outline: 0;
  box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) var(--color-seal-1), 0 0 0 var(--space-4xs) var(--color-tang-5);
}
.c-cta--focus-visible {
  transition-property: box-shadow;
  transition-duration: var(--transition-time-1);
  outline: 0;
  box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) var(--color-seal-1), 0 0 0 var(--space-4xs) var(--color-tang-5);
}
.c-cta:focus:not(:focus-visible) {
  box-shadow: none;
}
.c-cta[disabled], .c-cta--disabled {
  --c-cta-background-color: var(--color-shark-3);
  --c-cta-color: var(--color-seal-1);
  --c-cta-border-color: transparent;
  pointer-events: none;
}
.c-cta:hover {
  --c-cta-background-color: var(--color-shark-3);
  text-decoration: none;
  color: var(--c-cta-color);
}

.c-cta--fill {
  --c-cta-background-color: var(--color-tang-5);
  --c-cta-color: var(--color-seal-1);
}
.c-cta--fill:hover {
  --c-cta-color: var(--color-seal-1);
  --c-cta-background-color: var(--color-tang-6);
}

.c-cta--outline {
  --c-cta-border-color: var(--color-shark-3);
  --c-cta-background-color: var(--color-seal-1);
  --c-cta-color: var(--color-tang-5);
}
.c-cta--outline:hover {
  --c-cta-background-color: var(--color-tang-4);
}

.c-cta--danger {
  --c-cta-border-color: var(--color-tropical-4);
  --c-cta-color: var(--color-tropical-4);
  --c-cta-background-color: var(--color-seal-1);
}
.c-cta--danger:hover {
  --c-cta-background-color: var(--color-reef-2);
}

.c-cta--high {
  --c-cta-border-color: transparent;
  --c-cta-background-color: var(--color-comet-5);
  --c-cta-color: var(--color-shark-1);
}
.c-cta--high:hover {
  --c-cta-background-color: var(--color-comet-6);
  --c-cta-color: var(--color-seal-1);
}

.c-cta--large {
  padding: 1rem 2.5rem;
}

.c-cta--small {
  padding: 0.25rem 1rem;
  font-weight: 500;
  font-size: var(--step--0-5);
}

.c-cta--processing {
  position: relative;
  pointer-events: none;
}
.c-cta--processing .c-cta__label {
  opacity: 0;
}
.c-cta--processing .c-cta__processing {
  opacity: 1;
}

.c-cta__processing,
.c-cta__processing:before,
.c-cta__processing:after {
  display: block;
  animation-fill-mode: both;
  animation: dotAnim 1.6s infinite ease-in-out;
  border-radius: 100%;
  width: 2.4em;
  height: 2.4em;
  color: currentColor;
}

.c-cta__processing {
  position: absolute;
  top: calc(50% - 2.4em);
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-delay: -0.16s;
  pointer-events: none;
  font-size: 0.3rem;
}

.c-cta__processing:before,
.c-cta__processing:after {
  position: absolute;
  top: 0;
  transform: translateZ(0);
  overflow: hidden;
  content: "";
}

.c-cta__processing:before {
  left: calc(-1 * (1em + 2.4em));
  animation-delay: -0.32s;
}

.c-cta__processing:after {
  left: calc(1em + 2.4em);
}

@keyframes dotAnim {
  0%, 80%, 100% {
    box-shadow: 0 2.4em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.4em 0 0;
  }
}
.c-icon-list .c-sprite-icon {
  flex-shrink: 0;
}

.c-icon-list__header {
  --a-color: var(--color-shark-1);
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.c-icon-list__main {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
}

.c-icon-list__item {
  display: flex;
  gap: var(--space-4xs);
}

.c-icon-list__columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-3xs);
}
@media (width >= 48rem) {
  .c-icon-list__columns {
    grid-template-columns: repeat(var(--c-icon-list-columns, 1), 1fr);
  }
}

.c-icon-list__item--custom-icon {
  align-items: center;
  gap: 0.6rem;
}
.c-icon-list__item--custom-icon .c-sprite-icon {
  --c-sprite-icon-width: 2.1rem;
  --c-sprite-icon-height: 2.1rem;
}

.c-sprite-icon--em {
  --c-sprite-icon-unit: 1em;
}

.c-sprite-icon {
  filter: blur(var(--c-sprite-icon-blur, 0));
  width: var(--c-sprite-icon-width, var(--space-l));
  max-width: none;
  height: var(--c-sprite-icon-height, var(--space-l));
}

[class*=c-sprite-icon--ratio-] {
  width: 100%;
  height: auto;
}

.c-sprite-icon--ratio-4\/3 {
  aspect-ratio: 4/3;
}

.c-sprite-icon--ratio-16\/9 {
  aspect-ratio: 4/3;
}

.c-sprite-icon__use {
  --c-sprite-primary-4: var(--color-tang-4);
  --c-sprite-primary-5: var(--c-sprite-icon-color, var(--color-tang-5));
  --c-sprite-primary-6: var(--color-tang-6);
  --c-sprite-secondary-3: var(--color-comet-3);
  --c-sprite-secondary-5: var(--color-comet-5);
  --c-sprite-secondary-6: var(--color-comet-6);
  --c-sprite-contrast-1: var(--color-seal-1);
  --c-sprite-contrast-2: var(--color-shark-1);
  --c-sprite-contrast-3: var(--color-shark-3);
}

.c-sprite-icon--deco-aurora {
  --c-sprite-icon-blur: 13px;
}

.c-sprite-icon--lib-a11y {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-accept {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-ai {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-api {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-arrow-up {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-article {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-blog {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-book {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-brackets {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-builder {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-calendar {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-case {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-checklist {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-ckbox {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-clock {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-cloud {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-collaboration {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-comment {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-company {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-cookie {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-customize {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-demo {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-dice {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-doc-check {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-doc-outline {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-doc-toc {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-doc-word {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-doc {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-docs {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-download {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-drupal {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-easy-integration {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-editor {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-experience {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-export {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-flow {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-folder {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-font {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-get {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-global {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-glove {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-grammar {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-hands {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-headphones {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-healthcare {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-html {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-image {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-import-word {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-industry {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-link {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-list {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-maintenance {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-management {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-markdown {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-math {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-multilevel {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-office {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-pagination {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-painter {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-paragraph {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-pin {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-pricing {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-private {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-productivity-pack {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-productivity {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-proof {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-puzzle {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-quality {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-quote {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-result {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-revision-auto {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-revision-history {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-revision {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-rocket {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-search {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-server {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-shield {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-slash {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-solution {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-spell {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-success {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-support {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-table {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-talk {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-templates {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-theme {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-ticket {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-tour {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-typescript {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--lib-word {
  --c-sprite-icon-width: calc(4.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-arrow-curve-point-left {
  --c-sprite-icon-width: calc(2.6875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2.0625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-arrow-down-fill {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-arrow-down-outline {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-arrow-point-left {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-arrow-point-right {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-bulb {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-bullet {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-check-fill {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-check-outline {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-check {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-chevrons-left {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-chevrons-right {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-controllers {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-error {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-home {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-info {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-magnify {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-minus {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-n-a {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-new-tab {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-plus {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-quote-slim {
  --c-sprite-icon-width: calc(1.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-star {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-video-player {
  --c-sprite-icon-width: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--ui-x {
  --c-sprite-icon-width: calc(1 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--deco-large-hex {
  --c-sprite-icon-width: calc(58.375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(67.375 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--deco-multi-hex {
  --c-sprite-icon-width: calc(102 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(42.1875 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-chat {
  --c-sprite-icon-width: calc(4.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4.5 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-envelope {
  --c-sprite-icon-width: calc(16.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(16.4375 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-logo-symbol {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-logo {
  --c-sprite-icon-width: calc(8.1875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-soc {
  --c-sprite-icon-width: calc(5.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(5.625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--misc-youtube {
  --c-sprite-icon-width: calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2.1875 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--social-li {
  --c-sprite-icon-width: calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--social-mail {
  --c-sprite-icon-width: calc(4 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--social-x {
  --c-sprite-icon-width: calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(1.5625 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-align-left {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-certificate {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-code {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-count {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-embed {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-error {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-image {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-import-word {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-link {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-list {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-lock {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-page-break {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-page {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-phones {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-resize {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-save {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-share {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-shortcut {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-table {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-todo {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-transform {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-underline {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-uno {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-word {
  --c-sprite-icon-width: calc(2 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--cke-zip {
  --c-sprite-icon-width: calc(2.25 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(2.25 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-addiction-recovery-mono {
  --c-sprite-icon-width: calc(7.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-addiction-recovery {
  --c-sprite-icon-width: calc(7.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-agreemint-mono {
  --c-sprite-icon-width: calc(6.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-agreemint {
  --c-sprite-icon-width: calc(6.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-blooksy-mono {
  --c-sprite-icon-width: calc(12.1875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-blooksy {
  --c-sprite-icon-width: calc(12.1875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-dtu-mono {
  --c-sprite-icon-width: calc(2.75 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-dtu {
  --c-sprite-icon-width: calc(2.75 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-flexum-mono {
  --c-sprite-icon-width: calc(13.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-flexum {
  --c-sprite-icon-width: calc(13.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-foraus-mono {
  --c-sprite-icon-width: calc(13.6875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-foraus {
  --c-sprite-icon-width: calc(13.6875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-kanbanize-mono {
  --c-sprite-icon-width: calc(16 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-kanbanize {
  --c-sprite-icon-width: calc(16 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-leadoo-mono {
  --c-sprite-icon-width: calc(11.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-leadoo {
  --c-sprite-icon-width: calc(11.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-mailchain-mono {
  --c-sprite-icon-width: calc(10.875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-mailchain {
  --c-sprite-icon-width: calc(10.875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-neos-mono {
  --c-sprite-icon-width: calc(3.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-neos {
  --c-sprite-icon-width: calc(3.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-placement-mono {
  --c-sprite-icon-width: calc(14.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-placement {
  --c-sprite-icon-width: calc(14.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-plutio-mono {
  --c-sprite-icon-width: calc(9.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-plutio {
  --c-sprite-icon-width: calc(9.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-rocketlane-mono {
  --c-sprite-icon-width: calc(14.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-rocketlane {
  --c-sprite-icon-width: calc(14.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-spotlight-mono {
  --c-sprite-icon-width: calc(11.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-spotlight {
  --c-sprite-icon-width: calc(11.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-steeplechasers-mono {
  --c-sprite-icon-width: calc(3 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-steeplechasers {
  --c-sprite-icon-width: calc(3 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-sulu-mono {
  --c-sprite-icon-width: calc(16.5625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-sulu {
  --c-sprite-icon-width: calc(16.5625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-tablo-mono {
  --c-sprite-icon-width: calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-tablo {
  --c-sprite-icon-width: calc(10.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-testmo-mono {
  --c-sprite-icon-width: calc(12.375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--case-testmo {
  --c-sprite-icon-width: calc(12.375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-att {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-broadcom {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-citi {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-disney {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-drupal {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-elsevier {
  --c-sprite-icon-width: calc(5.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-fedex {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-ibm {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-microsoft {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-mit {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-mozilla {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-nbcuniversal {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-novartis {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-pfizer {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-rakuten {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-salesforce {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-sas {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-shortcut {
  --c-sprite-icon-width: calc(5.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-siemens {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-snapchat {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-square {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-tata {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-thomsonreuters {
  --c-sprite-icon-width: calc(9.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-volvo {
  --c-sprite-icon-width: calc(9.4375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--partner-zendesk {
  --c-sprite-icon-width: calc(9.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-angular {
  --c-sprite-icon-width: calc(10.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-asp {
  --c-sprite-icon-width: calc(9.3125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-bootstrap {
  --c-sprite-icon-width: calc(7.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-dotnet {
  --c-sprite-icon-width: calc(4.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-electron {
  --c-sprite-icon-width: calc(13.8125 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-express {
  --c-sprite-icon-width: calc(7.875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-java {
  --c-sprite-icon-width: calc(2.0625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-laravel {
  --c-sprite-icon-width: calc(9.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-maven {
  --c-sprite-icon-width: calc(10.625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-nextjs {
  --c-sprite-icon-width: calc(8.5625 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-php {
  --c-sprite-icon-width: calc(6.6875 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-react {
  --c-sprite-icon-width: calc(8.75 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-spring {
  --c-sprite-icon-width: calc(8.25 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-symfony {
  --c-sprite-icon-width: calc(11.375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-typescript {
  --c-sprite-icon-width: calc(8.9375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-vitejs {
  --c-sprite-icon-width: calc(5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-vue {
  --c-sprite-icon-width: calc(6.5 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.c-sprite-icon--tech-webpack {
  --c-sprite-icon-width: calc(9.375 * var(--c-sprite-icon-unit, 1rem));
  --c-sprite-icon-height: calc(4 * var(--c-sprite-icon-unit, 1rem));
}

.b-cookies {
  --l-wrapper-max: 90rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--transition-time-1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-seal-1);
  z-index: var(--z-index-cookies);
  border-top: 1px solid var(--color-tang-4);
}

.b-cookies--active {
  visibility: visible;
  opacity: 1;
  display: block;
}

.b-cookies__wrapper {
  --max-ch: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  align-items: center;
}
@media (min-width: 768px) {
  .b-cookies__wrapper {
    flex-wrap: nowrap;
  }
}
.b-cookies__wrapper .b-cookies__icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background-image: url(../images/icon/lib-cookie.svg);
  background-size: contain;
}
.b-cookies__wrapper p {
  font-size: 1.5rem;
  flex-grow: 1;
  margin: 0;
}
.b-cookies__wrapper .c-cta {
  font-size: 1.5rem;
  line-height: 1.3;
  box-shadow: 0 0 0 calc(var(--space-4xs) * 0.5) var(--color-shark-1), 0 0 0 var(--space-4xs) var(--color-seal-1);
}
.b-cookies__wrapper .c-cta--fill {
  --c-cta-background-color: var(--color-tang-6);
}
.b-cookies__wrapper .c-cta--fill:hover {
  --c-cta-background-color: var(--color-tang-7);
}
.b-cookies__wrapper .c-cta--outline {
  --c-cta-border-color: var(--color-tang-6);
  --c-cta-color: var(--color-tang-6);
}
.b-cookies__wrapper .c-cta--outline:hover {
  --c-cta-color: var(--color-seal-1);
  --c-cta-background-color: var(--color-comet-5);
}
.b-cookies__wrapper .l-group {
  display: flex;
  gap: 12px;
  flex-shrink: 0;
  width: auto;
  padding: 0;
}

.b-in-numbers {
  --l-grid-gap: 0;
  --l-grid-placement: auto-fit;
  --l-grid-min-item-size: 12rem;
  --l-grid-max-item-size: 14rem;
  background-image: var(--gradient-3);
  background-color: var(--color-tang-5);
  color: var(--color-seal-1);
  padding-top: 0;
  --l-wrapper-max: 88vw;
  --l-grid-min-item-size: 14rem;
  --l-grid-max-item-size: 20rem;
}
.b-in-numbers .l-grid {
  row-gap: var(--space-s);
  justify-content: center;
}
.b-in-numbers ol[role=list],
.b-in-numbers ul[role=list] {
  list-style: none;
}
.b-in-numbers li {
  margin-top: 0;
}
.b-in-numbers h3 {
  font-size: calc(var(--step-4) * 1.5);
}
.b-in-numbers p {
  font-size: calc(var(--step-1) * 1.5);
}

.b-info .l-wrapper {
  --l-wrapper-max: calc(88rem * 1.5);
}
.b-info .l-grid {
  --l-grid-min-item-size: calc(16rem * 2.5);
}
.b-info h3 {
  font-size: calc(var(--step-2) * 1.3);
}

.b-info {
  background-color: var(--color-seal-2);
}
.b-info .l-grid {
  justify-content: center;
  --l-grid-placement: auto-fit;
}

.b-info--light {
  background-color: var(--color-seal-1);
}

.b-info--premium {
  background: var(--gradient-1);
  color: var(--color-seal-1);
}

.b-info--thin .b-info__cards {
  --l-wrapper-max: 48rem;
}

.b-info--slim .b-info__cards {
  --l-wrapper-max: 68rem;
}

.b-info--narrow .b-info__cards {
  --l-wrapper-max: 78rem;
}

.b-info__icon {
  --c-sprite-icon-height: var(--space-l);
  --c-sprite-icon-width: var(--space-l);
  border-radius: 50%;
  background-color: var(--color-seal-1);
}

.b-info__cards .c-card {
  --c-sprite-icon-height: 2.65rem;
  --c-sprite-icon-width: 2.65rem;
}

.b-info-list {
  --l-wrapper-max: 88vw;
}
.b-info-list img[src$=".png"] {
  filter: drop-shadow(var(--dropshadow-1));
}
.b-info-list a:not([class*=ch2]),
.b-info-list li,
.b-info-list p {
  font-size: 2.1rem;
  line-height: 1.4;
  font-size: var(--step-2);
}
.b-info-list a {
  padding: 1.125rem 2.25rem;
}
.b-info-list h2 {
  font-size: calc(var(--step-4) * 1.5);
  line-height: var(--headline-line-height);
}
.b-info-list h3 {
  font-size: calc(var(--step-2) * 1.5);
}
.b-info-list img {
  max-width: 100%;
  height: auto;
}
.b-info-list--dark {
  background-color: var(--color-seal-2);
}
.b-info-list--horizontal {
  --l-wrapper-max: 68rem;
}
@media (width >= 48rem) {
  .b-info-list--horizontal .c-card__main {
    flex-direction: row;
    align-items: center;
  }
}
.b-info-list--horizontal .c-card__header {
  margin-inline: auto;
  max-width: 15rem;
}
.b-info-list--vertical .l-grid {
  justify-content: center;
  --l-grid-placement: auto-fit;
  --l-grid-min-item-size: clamp(16rem, 90%, 28rem);
  --l-grid-max-item-size: 0.3333333333fr;
}
.b-info-list--vertical .c-icon-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.b-info-list--vertical .c-icon-list__footer {
  flex-grow: 1;
  align-items: end;
}

.b-info-list__main {
  --max-ch: 70ch;
}

.b-intro {
  --max-ch: 92ch;
}
.b-intro h2 {
  font-size: calc(var(--step-4) * 1.5);
  line-height: var(--headline-line-height);
}
.b-intro .c-icon-link {
  display: inline-flex;
}

.b-intro__notifications {
  --l-wrapper-max: 58rem;
}

.b-partners {
  --l-wrapper-max: 80rem;
}
.b-partners h2 {
  font-size: calc(var(--step-4) * 1.5);
  line-height: var(--headline-line-height);
}
.b-partners h2::after {
  content: none;
  display: none;
}

.b-partners__track {
  --slide-duration: 30s;
  display: grid;
  grid-template-columns: repeat(14, 9rem);
  gap: 1.8rem;
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0), #000 5% 95%, rgba(0, 0, 0, 0));
  overflow: hidden;
}
.b-partners__track:hover .b-partners__slide {
  animation-play-state: paused;
}

.b-partners__slide {
  grid-area: 1/1;
  animation: slide-animation var(--slide-duration) linear infinite;
  width: 100%;
  width: 9vw;
  --c-sprite-icon-unit: 21px;
}

.b-partners__slide:nth-child(2) {
  animation-delay: calc(-0.0714285714 * var(--slide-duration));
}

.b-partners__slide:nth-child(3) {
  animation-delay: calc(-0.1428571429 * var(--slide-duration));
}

.b-partners__slide:nth-child(4) {
  animation-delay: calc(-0.2142857143 * var(--slide-duration));
}

.b-partners__slide:nth-child(5) {
  animation-delay: calc(-0.2857142857 * var(--slide-duration));
}

.b-partners__slide:nth-child(6) {
  animation-delay: calc(-0.3571428571 * var(--slide-duration));
}

.b-partners__slide:nth-child(7) {
  animation-delay: calc(-0.4285714286 * var(--slide-duration));
}

.b-partners__slide:nth-child(8) {
  animation-delay: calc(-0.5 * var(--slide-duration));
}

.b-partners__slide:nth-child(9) {
  animation-delay: calc(-0.5714285714 * var(--slide-duration));
}

.b-partners__slide:nth-child(10) {
  animation-delay: calc(-0.6428571429 * var(--slide-duration));
}

.b-partners__slide:nth-child(11) {
  animation-delay: calc(-0.7142857143 * var(--slide-duration));
}

.b-partners__slide:nth-child(12) {
  animation-delay: calc(-0.7857142857 * var(--slide-duration));
}

.b-partners__slide:nth-child(13) {
  animation-delay: calc(-0.8571428571 * var(--slide-duration));
}

.b-partners__slide:nth-child(14) {
  animation-delay: calc(-0.9285714286 * var(--slide-duration));
}

@keyframes slide-animation {
  6.0975609756% {
    transform: translate(-100%);
  }
  6.1075609756% {
    transform: translate(1540%);
  }
}
.u-fw-regular {
  font-weight: 400;
}

.u-fw-s-bold {
  font-weight: 600;
}

.u-fw-bold {
  font-weight: 700;
}

.u-fw-e-bold {
  font-weight: 800;
}

.u-u {
  text-decoration: underline;
}

.u-fs-1 {
  font-size: var(--step-4);
}

.u-fs-2 {
  font-size: var(--step-3);
}

.u-fs-3 {
  font-size: var(--step-2);
}

.u-fs-4 {
  font-size: var(--step-1);
}

.u-fs-1,
.u-fs-2,
.u-fs-3 {
  line-height: var(--headline-line-height);
}

.u-fs-0-5 {
  font-size: var(--step-0-5);
}

.u-fs-0 {
  font-size: var(--step-0);
}

.u-fs--0-5 {
  font-size: var(--step--0-5);
}

.u-fs--1 {
  font-size: var(--step--1);
}

.u-ta-center {
  text-align: center;
}

.u-ta-right {
  text-align: right;
}

.u-tw-balance {
  text-wrap: balance;
}

.u-color-tang-5 {
  color: var(--color-tang-5);
}

.u-color-comet-5 {
  color: var(--color-comet-5);
}

.u-color-shark-1 {
  color: var(--color-shark-1);
}

.u-color-shark-2 {
  color: var(--color-shark-2);
}

.u-color-shark-3 {
  color: var(--color-shark-3);
}

.u-color-seal-1 {
  color: var(--color-seal-1);
}

.u-color-tropical-4 {
  color: var(--color-tropical-4);
}

.u-bg-seal-1 {
  background-color: var(--color-seal-1);
}

.u-bg-seal-2 {
  background-color: var(--color-seal-2);
}

.u-bg-comet-5 {
  background-color: var(--color-comet-5);
}

.u-bg-reef-1 {
  background-color: var(--color-reef-1);
}

.u-bg-reef-2 {
  background-color: var(--color-reef-2);
}

.u-bg-reef-3 {
  background-color: var(--color-reef-3);
}

.u-bg-reef-4 {
  background-color: var(--color-reef-4);
}

.u-bg-reef-5 {
  background-color: var(--color-reef-5);
}

.u-bg-reef-6 {
  background-color: var(--color-reef-6);
}

.u-flow-xl > * + * {
  margin-top: var(--space-xl);
}

.u-flow-l > * + * {
  margin-top: var(--space-l);
}

.u-flow-m > * + * {
  margin-top: var(--space-m);
}

.u-flow-s > * + * {
  margin-top: var(--space-s);
}

.u-flow-xs > * + * {
  margin-top: var(--space-xs);
}

.u-flow > * + * {
  margin-top: var(--space-3xs);
}

.u-flow-4xs > * + * {
  margin-top: var(--space-4xs);
}

.u-justify-center {
  display: flex;
  justify-content: center;
}

.u-sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.u-button-reset,
.u-button-reset-inline {
  appearance: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  color: currentColor;
  text-align: left;
}

.u-button-reset {
  width: 100%;
}

.u-hidden {
  visibility: hidden;
}

.u-gone {
  display: none;
}

.u-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.u-full-height {
  height: 100%;
}

.u-full-width {
  width: 100%;
}

.u-full-max-width {
  max-width: 100%;
}

.u-sticky {
  position: sticky;
  top: var(--scroll-top);
}

[class*=u-br-] br {
  display: none;
}

@media (width >= 48rem) {
  .u-br-sm br {
    display: block;
  }
}
@media (width >= 64rem) {
  .u-br-md br {
    display: block;
  }
}
@media (width >= 88rem) {
  .u-br-lg br {
    display: block;
  }
}
.u-filter-shadow {
  filter: drop-shadow(var(--dropshadow-1));
}

.u-skip-link {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  top: 1.3em;
  left: 0.75rem;
}
.u-skip-link:focus-visible {
  z-index: var(--z-index-skip-link);
  width: auto;
  height: auto;
  clip: auto;
}