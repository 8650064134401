@use "../tools/media";
.cta {
	position: relative;
	@include _breakpoint--sm-plus {
		--b-cta--height: 295px;
		height: calc(var(--b-cta--height) + var(--space-3xl));
	}
	.site-content--careers & {
		background-color: var(--color-seal-1);
	}
	&,
	&:last-of-type {
		padding-bottom: 0;
	}

	&__yellow-part {
		h2 {
			text-align: center;
			font-weight: 400;
			line-height: var(--headline-line-height);
			color: var(--color-seal-1);

			&:after {
				display: none;
			}
		}

		@include _breakpoint--sm-only {
			h2 {
				font-size: 4rem;
				padding-bottom: 1rem;
			}
		}

		@include _breakpoint--sm-plus {
			position: absolute;
			height: var(--b-cta--height);
			width: calc(100% - 320px);
			left: 0;
			z-index: 2;

			// &:after {
			// 	content: "";
			// 	position: absolute;
			// 	display: block;
			// 	right: 0;
			// 	top: 0;
			// 	width: 61px;
			// 	height: 100%;
			// 	background-color: var(--color-comet-5);
			// 	clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
			// }

			h2 {
				position: absolute;
				top: 50%;
				left: _space();
				text-align: inherit;
				transform: translateY(-50%);
				font-size: 4rem;
				margin-right: 100px;

				&:after {
					display: block;
					background-color: var(--color-tang-5);
				}

				@include _breakpoint--sm-plus {
					left: _double-space();
				}

				@include _breakpoint--md {
					left: 150px;
				}

				@include _breakpoint--lg {
					left: 200px;
				}
			}
		}

		@include _breakpoint--md {
			width: calc(100% - 430px);

			h2 {
				font-size: 4.3rem;
			}
		}

		@include _breakpoint--lg {
			width: 66%;
		}

		&-wrapper {
			position: relative;
			display: inline-block;
			height: 100%;
			width: 100%;

			@include _breakpoint--sm-plus {
				padding-right: 60px;
			}
		}

		&-inner {
			display: inline-block;
			height: 100%;
			width: 100%;
			padding: _double-space() 1rem;
			background-color: var(--color-tang-5);

			.btn {
				display: block;
				margin: _space() auto 0;
				color: var(--color-shark-1);
				background-color: var(--color-comet-5);
				border: none;
				width: max-content;
				padding: {
					top: 11px;
					bottom: 14px;
				}
			}

			@include _breakpoint--sm-plus {
				.btn {
					display: none;
				}
			}
		}
	}

	&__orange-part {
		display: none;
		position: absolute;
		height: 295px;
		width: 420px;
		right: 0;
		// top: _space();
		@include _zindex(air);

		@include _breakpoint--sm-plus {
			display: block;
		}

		@include _breakpoint--md {
			width: 520px;
		}

		@include _breakpoint--lg {
			width: 40%;
		}

		// &:before {
		// 	content: "";
		// 	position: absolute;
		// 	display: block;
		// 	left: 0;
		// 	top: 0;
		// 	width: 123px;
		// 	height: 100%;
		// 	background-color: var(--color-comet-6);
		// 	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 25% 100%);
		// }

		&-wrapper {
			position: relative;
			display: inline-block;
			height: 100%;
			width: 100%;
			// padding-left: 120px;
		}

		&-inner {
			display: inline-block;
			height: 100%;
			width: 100%;
			background-color: var(--color-tang-5);
		}

		.btn {
			position: absolute;
			top: 50%;
			left: 150px;
			color: var(--color-shark-1);
			background-color: var(--color-comet-5);
			border: none;
			padding: {
				top: 20px;
				bottom: 21px;
			}
			font: {
				size: _font-scale(big);
				weight: 800;
			}
			transform: translateY(-50%);
			padding: var(--space-s) var(--space-m);

			@include _breakpoint--md {
				left: 50%;
				font-size: 2.3rem;
				transform: translate(-50%, -50%);
			}
			@include media.xlarge {
				padding: var(--space-m) var(--space-xl);
			}

			&:hover {
				background-color: var(--color-seal-1);
				color: var(--color-tang-5);
			}
		}
	}

	&__actions {
		margin-top: _space();
	}

	&--2 {
		.btn {
			margin-right: _space();

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	p {
		width: 640px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
