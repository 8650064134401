.contest-bar {
	display: flex;
    justify-content: flex-end;
	padding-right: 16px;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-bottom: 12px;

	@include _breakpoint--sm {
		margin-bottom: 32px;
		padding-top: 43px;
		padding-bottom: 43px;
	}

	.contest-bar-logo {
		max-width: 60px;

		@include _breakpoint--sm {
			max-width: 80px;
		}
	}
}

.contest-bar--devjs {
	background-color: #0086CD;
}

.contest-bar--meetjs {
	background-color: #001234;

	.contest-bar-logo {
		max-width: 80px;

		@include _breakpoint--sm {
			max-width: 120px;
		}
	}
}
