.offers {
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding-bottom: _space() * 2;

	@include _breakpoint--md {
		overflow: initial;
		padding-bottom: _double-space() * 2;
	}

	// &::before {
	// 	background-color: #f2f2f2;
	// 	z-index: -1;
	// 	transform: translate(-183px, -420px) rotate(43deg);
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 461px;
	// 	height: 676px;

	// 	@include _breakpoint--sm {
	// 		width: 448px;
	// 		height: 940px;
	// 	}

	// 	@include _breakpoint--md {
	// 		height: 1590px;
	// 	}
	// }
}

.offers__list-item-outer {
	.offers__list-item-header {
		display: flex;
		flex-direction: column;
		padding: 16px 56px 8px 16px;

		@include _breakpoint--sm {
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			padding: 16px 72px 16px 50px;
		}
	}

	.offers__list-item-title {
		font-size: _font-scale(normal);
		line-height: 1.2;
		margin: 0 0 4px;

		@include _breakpoint--sm {
			display: flex;
			justify-content: space-between;
			font-size: _font-scale(xbig);
		}
	}

	.offers__list-item-salary {
		display: block;
		color: var(--color-tang-5);
		letter-spacing: 0.2px;
		&--big {
			font-size: _font-scale(small);
			margin-top: 4px;

			@include _breakpoint--sm {
				font-size: _font-scale(xbig);
				margin-top: initial;
			}
		}
	}

	.offer__list-item-bottom {
		margin: 0;
		color: #525050;

		@include _breakpoint--sm {
			display: flex;
			justify-content: space-between;
		}

		p {
			margin: 0;
		}
	}
}

.no-job-position {
	display: none;
	font-size: _font-scale(normal);
	background-color: #ffffff;
	padding: 24px;
	border-left: 4px solid var(--color-tang-5);
	margin-left: 15px;
	margin-right: 15px;

	@include _breakpoint--sm {
		margin-left: initial;
		margin-right: initial;
	}

	@include _breakpoint--md {
		font-size: _font-scale(xbig);
	}
}
