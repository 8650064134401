@use "../tools/color";

:root {
	// typography
	--font-family: Mulish, system-ui, -apple-system, sans-serif;
	--font-family-italic: MulishItalic, system-ui, -apple-system, sans-serif;
	--font-family-mono: Consolas, Monaco, "Andale Mono", "Ubuntu Mono",
		ui-monospace, monospace;
	--body-line-height: 1.4;
	--dense-text-line-height: 1.55;
	--headline-line-height: 1.1;

	// color
	--color-seal-1: hsl(0, 0%, 100%);
	--color-seal-2: hsl(228, 100%, 98%);
	--color-seal-3: hsl(227, 66%, 95%);

	--color-tang-hs: 263, 59%;
	--color-tang-4: hsl(var(--color-tang-hs), 92%);
	--color-tang-5: hsl(var(--color-tang-hs), 52%);
	--color-tang-6: hsl(var(--color-tang-hs), 40%);
	--color-tang-7: hsl(var(--color-tang-hs), 26%);
	--color-tang-8: hsl(var(--color-tang-hs), 10%);

	--color-shark-0: hsl(0, 0%, 2%);
	--color-shark-1: hsl(219, 100%, 10%);
	--color-shark-2: hsl(214, 4%, 36%);
	--color-shark-3: hsl(213, 21%, 70%);
	--color-shark-4: hsl(210, 22%, 91%);
	--color-shark-5: hsl(210, 17%, 98%);

	--color-comet-hs: 77, 99%;
	--color-comet-3: hsl(var(--color-comet-hs), 78%);
	--color-comet-5: hsl(var(--color-comet-hs), 63%);
	--color-comet-6: hsl(var(--color-comet-hs), 45%);
	--color-comet-7: hsl(var(--color-comet-hs), 35%);

	--color-reef-1: hsl(189, 57%, 91%);
	--color-reef-2: hsl(16, 100%, 96%);
	--color-reef-3: hsl(201, 83%, 95%);
	--color-reef-4: hsl(234, 100%, 96%);
	--color-reef-5: hsl(78, 52%, 94%);
	--color-reef-6: hsl(345, 60%, 96%);

	// tropical colors
	--color-tropical-1: hsl(53, 100%, 43%);
	--color-tropical-2: hsl(36, 100%, 50%);
	--color-tropical-3: hsl(17, 100%, 57%);
	--color-tropical-4: hsl(346, 100%, 57%);
	--color-tropical-5: hsl(328, 100%, 49%);
	--color-tropical-6: hsl(307, 93%, 41%);
	--color-tropical-7: hsl(265, 90%, 51%);

	// arctic colors
	--color-arctic-1: hsl(208, 100%, 18%);
	--color-arctic-2: hsl(200, 100%, 27%);
	--color-arctic-3: hsl(188, 100%, 31%);
	--color-arctic-4: hsl(168, 100%, 36%);
	--color-arctic-5: hsl(140, 72%, 55%);

	// animation
	--transition-time-1: 0.2s;
	--transition-time-2: 0.35s;

	// shadow
	--shadow-1: 0 0 var(--space-m) var(--space-4xs) rgba(0, 0, 0, 0.1);
	--shadow-2: 0 0 var(--space-2xs) var(--space-4xs) rgba(0, 0, 0, 0.07);

	// filter drop shadow
	--dropshadow-1: 0 0 var(--space-2xs) rgba(0, 0, 0, 0.085);

	// background
	--gradient-1: linear-gradient(
		122.1deg,
		var(--color-tang-8) 32.29%,
		var(--color-tang-7) 70.55%
	);

	--gradient-2: linear-gradient(
		122.1deg,
		var(--color-comet-5) 32.29%,
		var(--color-comet-6) 70.55%
	);

	--gradient-3: linear-gradient(
		122.1deg,
		transparent 32.29%,
		#{color.opacity(var(--color-tang-7), 70%)} 70.55%
	);

	--gradient-promo: linear-gradient(
		130deg,
		var(--color-comet-6),
		var(--color-tropical-1),
		var(--color-tropical-2),
		var(--color-tropical-3),
		var(--color-tropical-4),
		var(--color-tropical-5),
		var(--color-tropical-6),
		var(--color-tang-6)
	);

	--gradient-arctic: linear-gradient(
		90deg,
		var(--color-tang-8),
		var(--color-arctic-1),
		var(--color-arctic-2),
		var(--color-arctic-3),
		var(--color-arctic-4),
		var(--color-arctic-5),
		var(--color-comet-5)
	); /* made at https://learnui.design/tools/gradient-generator.html */

	--gradient-polar-dusk: linear-gradient(
		180.21deg,
		var(--color-shark-0) 49.74%,
		var(--color-tropical-7) 115.87%
	);

	--color-neo-comet-5: hsl(77, 76%, 52%);
	--color-neon-5: hsl(162, 70%, 49%);
	--color-neo-tang-5: hsl(264, 100%, 55%);

	--gradient-neo: linear-gradient(
		-90deg,
		var(--color-neo-comet-5),
		var(--color-neon-5),
		var(--color-neo-tang-5)
	);

	// z-index
	--z-index-cookies: 1000;
	--z-index-popup: 100;
	// high values because of sticky demo toolbars
	--z-index-header: 2000;
	--z-index-chat: 2500;
	--z-index-overlay: 3000;
	--z-index-lightbox: 4000;

	// height
	// 80px @ 320px viewport
	// 112px @ 1408px viewport
	// https://clamp.font-size.app/?config=eyJyb290IjoiMTYiLCJtaW5XaWR0aCI6IjIwcmVtIiwibWF4V2lkdGgiOiI4OHJlbSIsIm1pbkZvbnRTaXplIjoiODBweCIsIm1heEZvbnRTaXplIjoiMTEycHgifQ%3D%3D
	--header-height: clamp(5rem, 4.4118rem + 2.9412vw, 7rem);
	--scroll-top: calc(var(--header-height) + var(--space-s));
}
