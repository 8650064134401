.hero {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: _double-space();

    @include _breakpoint--sm {
        padding-top: 64px;
        padding-bottom: 64px;
        margin-bottom: _double-space() * 2;
    }
}

.hero-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include _breakpoint--sm {
        flex-direction: row;
    }
}

.devjs-hero {
    background-color: #0086CD;
    color: #ffffff;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        pointer-events: none;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translateX(-50%) rotate(45deg);

        @include _breakpoint--sm {
            width: 72px;
            height: 72px;
        }
    }

    &::after {
        bottom: -12px;
        background: inherit;

        @include _breakpoint--sm {
            bottom: -30px;
        }
    }

    .meetjs-logo,
	.devjs-logo {
        max-width: 160px;
        margin-top: 12px;
        margin-left: initial;

        @include _breakpoint--sm {
            margin-top: initial;
            margin-left: 32px;
        }
    }
}
