.contact-person {
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding-left: 64px;

	@include _breakpoint--xsm {
		padding-left: 104px;
	}

	@include _breakpoint--md {
		padding-left: initial;
		padding-bottom: 16px;
		text-align: center;
	}

	&::before,
	&::after {
		display: none;
		content: "";
		position: absolute;
		height: 40px;
		width: 40px;
		border-bottom: 2px solid
			color-mix(
				in srgb,
				var(--color-tang-7) 10%,
				var(--color-mix-base, transparent)
			);

		@include _breakpoint--md {
			display: block;
		}
	}

	&::before {
		bottom: 0;
		left: 0;
		border-left: 2px solid
			color-mix(
				in srgb,
				var(--color-tang-7) 10%,
				var(--color-mix-base, transparent)
			);
	}

	&::after {
		bottom: 0;
		right: 0;
		border-right: 2px solid
			color-mix(
				in srgb,
				var(--color-tang-7) 10%,
				var(--color-mix-base, transparent)
			);
	}

	&:hover {
		text-decoration: none;

		.person__contact {
			text-decoration: underline;
		}
	}

	.person__title {
		margin: 0;
		color: var(--color-shark-1);
		font-size: _font-scale(big);
		line-height: 1.2;
		margin-bottom: 4px;

		@include _breakpoint--md {
			font-size: _font-scale(xbig);
			margin-bottom: initial;
		}
	}
	.person__image {
		max-width: 40px;
		width: 100%;
		margin: 0;
		border-radius: 20%;
		box-shadow: 5px 2px 2px #557c9926;
		position: absolute;
		top: 0;
		left: 12px;

		@include _breakpoint--xsm {
			max-width: 72px;
		}

		@include _breakpoint--md {
			max-width: 170px;
			margin: 12px auto 8px;
			display: block;
			position: static;
		}
	}

	.person__name,
	.person__contact {
		margin: 0;
		color: var(--color-shark-1);
		line-height: 1.2;
	}

	.person__name {
		@include _breakpoint--md {
			font-weight: bold;
		}
	}

	.person__contact {
		display: inline-flex;
		font-size: _font-scale(medium);
		text-decoration: underline;
		margin-right: auto;
		padding-left: 24px;
		// background-image: image_url("mail.svg", "icon");
		background-repeat: no-repeat;
		background-size: 18px;
		background-position: left center;

		.c-sprite-icon--social-mail {
			--c-sprite-icon-unit: 6px;
			color: var(--color-tang-6);
			margin-right: var(--space-3xs);
		}

		@include _breakpoint--md {
			margin-left: auto;
			font-size: _font-scale(normal);
			text-decoration: none;
		}
	}
}
