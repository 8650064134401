$zindexes: (
	'under': -1,
	'default': 1,
	'float': 99,
	'air': 999,
	'cover': 999999,
	'on-cover': 9999999
);

@mixin _zindex( $index: default ) {
	z-index: map-get( $zindexes, quote( $index ) );
}

@function _get-zindex( $index: default ) {
	@return map-get( $zindexes, quote( $index ) );
}
