@use "../../../src/sass/tools/focus";

$dotSize: 2.4em;

@mixin nova-cta {
	.c-cta {
		@include focus.style;
		--c-cta-border-color: transparent;
		display: inline-block;
		transition-duration: var(--transition-time-1);
		transition-property: background-color, color;
		cursor: pointer;
		border: 1px solid var(--c-cta-border-color);
		border-radius: var(--space-3xs);
		background-color: var(--c-cta-background-color);
		// using fixed rems instead of fluid vars, so the button is same size on mobile
		// padding: 0.75rem 1.5rem;
		color: var(--c-cta-color);
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		border-radius: var(--space-3xl);
		padding: var(--space-s) var(--space-xl);

		&[disabled],
		&--disabled {
			--c-cta-background-color: var(--color-shark-3);
			--c-cta-color: var(--color-seal-1);
			--c-cta-border-color: transparent;

			pointer-events: none;
		}

		&:hover {
			--c-cta-background-color: var(--color-shark-3);
			text-decoration: none;
			color: var(--c-cta-color);
		}
	}

	// fill
	.c-cta--fill {
		--c-cta-background-color: var(--color-tang-5);
		--c-cta-color: var(--color-seal-1);

		&:hover {
			--c-cta-color: var(--color-seal-1);
			--c-cta-background-color: var(--color-tang-6);
		}
	}

	// outline
	.c-cta--outline {
		--c-cta-border-color: var(--color-shark-3);
		--c-cta-background-color: var(--color-seal-1);
		--c-cta-color: var(--color-tang-5);

		&:hover {
			--c-cta-background-color: var(--color-tang-4);
		}
	}

	// danger
	.c-cta--danger {
		--c-cta-border-color: var(--color-tropical-4);
		--c-cta-color: var(--color-tropical-4);
		--c-cta-background-color: var(--color-seal-1);

		&:hover {
			--c-cta-background-color: var(--color-reef-2);
		}
	}

	// high
	.c-cta--high {
		--c-cta-border-color: transparent;
		--c-cta-background-color: var(--color-comet-5);
		--c-cta-color: var(--color-shark-1);

		&:hover {
			--c-cta-background-color: var(--color-comet-6);
			--c-cta-color: var(--color-seal-1);
		}
	}

	// large
	.c-cta--large {
		// using fixed rems instead of fluid vars, so the button is same size on mobile
		padding: 1rem 2.5rem;
	}

	// small
	.c-cta--small {
		// using fixed rems instead of fluid vars, so the button is same size on mobile
		padding: 0.25rem 1rem;
		font-weight: 500;
		font-size: var(--step--0-5);
	}

	// processing
	.c-cta--processing {
		position: relative;
		pointer-events: none;

		.c-cta__label {
			opacity: 0;
		}

		.c-cta__processing {
			opacity: 1;
		}
	}

	.c-cta__processing,
	.c-cta__processing:before,
	.c-cta__processing:after {
		display: block;
		animation-fill-mode: both;
		animation: dotAnim 1.6s infinite ease-in-out;
		border-radius: 100%;
		width: $dotSize;
		height: $dotSize;
		color: currentColor;
	}

	.c-cta__processing {
		position: absolute;
		top: calc(50% - #{$dotSize});
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		animation-delay: -0.16s;
		pointer-events: none;
		font-size: 0.3rem;
	}

	.c-cta__processing:before,
	.c-cta__processing:after {
		position: absolute;
		top: 0;
		transform: translateZ(0);
		overflow: hidden;
		content: "";
	}

	.c-cta__processing:before {
		left: calc(-1 * (1em + #{$dotSize}));
		animation-delay: -0.32s;
	}

	.c-cta__processing:after {
		left: calc(1em + #{$dotSize});
	}

	@keyframes dotAnim {
		0%,
		80%,
		100% {
			box-shadow: 0 $dotSize 0 -1.3em;
		}
		40% {
			box-shadow: 0 $dotSize 0 0;
		}
	}
}
@include nova-cta;
