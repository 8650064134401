.squares {
	&__container {
		display: flex;
		width: $tablet-middle-container-width--wide;
		max-width: 100%;
		margin: 0 auto;
		flex-wrap: wrap;
		justify-content: center;

		@include _breakpoint--md {
			justify-content: flex-start;
			width: $tablet-middle-container-width--wide-md;
		}

		@include _breakpoint--lg {
			width: $tablet-middle-container-width--wide-lg;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		max-width: 151px;
		color: white;
		flex: 1 0 auto;
		height: auto;
		overflow: hidden;

		@include _breakpoint--sm-plus {
			max-width: 302px;
		}

		&:before {
			content: '';
			display: table;
			padding-top: 100%;
		}
	}
}

