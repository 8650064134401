$base-font-size: 19px;
$base-font-size--rem: 1.9rem;
$base-line-height: 25px;
$base-line-height--rem: 3.4rem;

$pretty-font-size: 19px;
$pretty-font-size--rem: 1.9rem;
$pretty-line-height: 30px;
$pretty-line-height--rem: 3rem;

$fonts-scale: (
	"xbig": $base-font-size--rem + 0.5rem,
	"xlarge": $base-font-size--rem + 1.3rem,
	"large": $base-font-size--rem + 0.5rem,
	"big": $base-font-size--rem + 0.2rem,
	"normal": $base-font-size--rem,
	"medium": $base-font-size--rem - 0.3rem,
	"small": $base-font-size--rem - 0.5rem,
	"tiny": $base-font-size--rem - 0.7rem,
);

@function _font-scale($type: normal) {
	@return map-get($fonts-scale, quote($type));
}

@mixin _underline($color: var(--color-tang-5)) {
	position: relative;
	padding-bottom: 0.5rem;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 60px;
		height: 3px;
		background-color: $color;
	}
}

html {
	font-size: 62.5%;
	// font-size: 100%;

	@include _hdpi {
		font-size: 65%;
	}
}

body {
	font: {
		family: var(--font-family);
		// size: $base-font-size - 4px;
		// size: $base-font-size--rem - 0.4rem;
		size: var(--step-0);
		weight: 400;
	}
	line-height: var(--body-line-height);
	color: var(--color-shark-1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include _breakpoint--sm {
		font: {
			size: $base-font-size;
			size: $base-font-size--rem;
		}
		line-height: $base-line-height--rem;
	}
}

pre,
code,
kbd {
	font-family: var(--font-family-mono);
}

h1,
h2,
h3,
h4,
h5 {
	margin: {
		top: 0;
		bottom: 0;
	}
	letter-spacing: -0.5px;
}

h1,
h2 {
	font-family: var(--font-family);
}

h2 {
	font-weight: 300;
}

h1 {
	font: {
		size: 3.2rem;
		weight: 400;
	}
	line-height: $base-line-height--rem;

	@include _breakpoint--sm {
		font-size: 5.7rem;
		line-height: $base-line-height--rem * 3;
		letter-spacing: -2px;
	}

	@include _breakpoint--md {
		font-size: 6.3rem;
	}
}

h2 {
	font: {
		size: 2.5rem;
	}
	line-height: 1.1;

	@include _breakpoint--sm {
		font-size: 4.9rem;
		// line-height: $base-line-height--rem * 2;
	}

	@include _breakpoint--md {
		font-size: 5.3rem;
		// line-height: $base-line-height--rem * 3;
	}
}

h3 {
	font: {
		size: 1.9rem;
		weight: 600;
	}
	line-height: $base-line-height--rem;
	margin: {
		top: 0.9rem;
		bottom: 0.9rem;
	}

	@include _breakpoint--sm {
		font-size: 3.2rem;
		line-height: $base-line-height--rem * 2;
	}
}

h4 {
	font-size: 2rem;
	line-height: $base-line-height--rem;
}

h5 {
	font-size: 1.7rem;
	line-height: $base-line-height--rem;
}

h4,
h5 {
	font-weight: 600;
}

p,
ul,
ol,
table,
figure {
	margin: {
		top: $base-font-size--rem - 0.4rem;
		bottom: $base-font-size--rem - 0.4rem;
	}
}

a {
	// color: color-mix(
	// 	in srgb,
	// 	var(--color-tang-5) 0.9,
	// 	var(--color-mix-base, transparent)
	// );

	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
	&.link--long-url {
		@include _breakpoint--mo {
			font-size: 0;
			position: relative;

			&:before {
				position: absolute;
				content: "[link]";
				font-size: initial;
			}
		}
	}
}

li {
	margin-top: 0.7rem;

	&:first-child {
		margin-top: 0;
	}
}

ol {
	ol {
		list-style-type: lower-alpha;
	}
}
ol,
ul {
	@include _breakpoint--mo {
		padding-left: 2rem;
	}
}

.pretty-text {
	font-size: $pretty-font-size;
	font-size: $pretty-font-size--rem;
	line-height: $pretty-line-height--rem;

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 600;
		letter-spacing: inherit;
	}

	h1 {
		font: {
			size: 3.7rem;
			family: var(--font-family);
			weight: 200;
		}
		line-height: 1.04;
		margin-bottom: $pretty-line-height--rem;
	}

	h2 {
		font-size: 3rem;
		line-height: 1.04;
	}

	h3 {
		font-size: 2.4rem;
		line-height: $pretty-line-height--rem;
		margin: {
			top: 2rem;
			bottom: 1rem;
		}
	}

	h4 {
		font-size: $pretty-font-size--rem;
		line-height: $pretty-line-height--rem;
		margin: {
			top: 2rem;
			bottom: 1rem;
		}
	}

	h5 {
		font-size: 1.7rem;
		line-height: $pretty-line-height--rem;
		margin: {
			top: 2rem;
			bottom: 1rem;
		}
	}

	h4,
	h5 {
		font-weight: 700;
	}

	p {
		margin-top: 0;
		font-size: $pretty-font-size--rem;
		line-height: $pretty-line-height--rem;
		letter-spacing: -0.003rem;
	}

	img,
	figure,
	ol,
	ul {
		display: block;
		margin: {
			top: $pretty-line-height--rem;
			bottom: $pretty-line-height--rem;
		}
	}
}
