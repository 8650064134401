.c-card {
	--c-card-background-color: var(--color-seal-2);
	display: flex;
	position: relative; // to position absolutely positioned tag or quotes sign
	gap: var(--space-xs);
	transition-duration: var(--transition-time-1);
	transition-property: box-shadow;
	border-radius: var(--space-xs);
	background-color: var(--c-card-background-color);
	padding: var(--c-card-padding, var(--space-m));
	// in case it is inserted into other than body text color context
	color: var(--color-shark-1);
}

// styles
.c-card--highlight {
	--c-card-background-color: var(--color-seal-1);
	// using box-shadow instead of the border so the card's content is equally distributed just as the ones without the border
	// don't use outline, safari doesn't support rounded outlines
	box-shadow: 0 0 0 var(--space-4xs) var(--color-tang-5);
}

.c-card--outline {
	--c-card-background-color: var(--color-seal-1);
	// using box-shadow instead of the border so the card's content is equally distributed just as the ones without the border
	// don't use outline, safari doesn't support rounded outlines
	box-shadow: 0 0 0 1px var(--color-shark-4);
}

.c-card--review {
	.c-card__main {
		gap: var(--space-xs);
	}

	.c-card__text {
		font-style: italic;
		font-family: var(--font-family-italic);
	}

	.c-sprite-icon--ui-quote-slim {
		position: absolute;
		top: var(--space-s);
		right: var(--space-2xs);
		opacity: 0.15;
	}
}

.c-card--fill-trans {
	--c-card-background-color: transparent;
}

.c-card--reef-1 {
	--c-card-background-color: var(--color-reef-1);
}

.c-card--reef-2 {
	--c-card-background-color: var(--color-reef-2);
}

.c-card--reef-3 {
	--c-card-background-color: var(--color-reef-3);
}

.c-card--reef-4 {
	--c-card-background-color: var(--color-reef-4);
}

.c-card--reef-5 {
	--c-card-background-color: var(--color-reef-5);
}

.c-card--reef-6 {
	--c-card-background-color: var(--color-reef-6);
}

.c-card--seal-3 {
	--c-card-background-color: var(--color-shark-5);
}

.c-card--comet-3 {
	--c-card-background-color: var(--color-comet-3);
}

// interactive
.c-card--interactive:hover {
	box-shadow: var(--shadow-2);
}

// Center in Y axis
.c-card--center {
	align-items: center;
}

// main elements
.c-card__main {
	display: flex;
	flex-direction: column;
	gap: var(--space-s);
}

.c-card__aside,
.c-card__header,
.c-card__footer {
	flex-shrink: 0;
}

.c-card__text {
	flex-grow: 1;
}

// text sub-elements
.c-card__text-clamp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-wrap: break-word;
	word-break: break-word;
}

.c-card__text-clamp--2 {
	-webkit-line-clamp: 2;
}

.c-card__text-clamp--3 {
	-webkit-line-clamp: 3;
}

// assigned to <a>
.c-card__link {
	--a-color: var(--color-shark-1);
	// so the focus effect looks right
	display: inline-block;
}

// assigned to .c-tag
.c-card__badge {
	position: absolute;
	top: var(--space-4xs);
	left: var(--space-4xs);
}
