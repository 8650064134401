.decorators {
    position: relative;
}

.decorator {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 125px;
}

.decorator--big {
    width: 180px;
    height: 190px;
}

.decorator--hex-cke5 {
    top: 0;
    right: 0;
    background-image: image_url( 'hex-decorator-cke5.svg', 'decorators' );
    transform: translate(10px, 140px);
}

.decorator--hex-cke4 {
    top: 0;
    right: 0;
    background-image: image_url( 'hex-decorator-cke4.svg', 'decorators' );
    transform: translate(70px, 40px);
}

.decorator--hex-collaboration {
    top: 35%;
    left: 0;
    background-image: image_url( 'hex-decorator-collaboration.svg', 'decorators' );
    transform: translate(-120px, 120px);
}

.decorator--hex-export-pdf {
    top: 35%;
    left: 0;
    background-image: image_url( 'hex-decorator-export-pdf.svg', 'decorators' );
    transform: translate(0px, 20px);
}

.decorator--hex-revision-history {
    top: 70%;
    left: 0;
    background-image: image_url( 'hex-decorator-revision-history.svg', 'decorators' );
}

.decorator--hex-uploaders {
    top: 80%;
    left: 70%;
    background-image: image_url( 'hex-decorator-uploaders.svg', 'decorators' );
    transform: translate(190px,50px);
}
