.quiz-card {
	display: none;
	position: fixed;
	overflow: hidden;
    z-index: 1000001;
	top: 50%;
	transform: translate(-50%, -50%);
    left: 50%;
	max-width: 640px;
    width: 100%;
	background-color: #ffffff;
}

.quiz-card-inner {
	overflow: hidden auto;

}

.quiz-card--active {
	display: block;
}

p.quiz-necessary,
p.quiz-last-step,
p.quiz-score,
p.quiz-progress-title {
	text-align: center;
	font-size: 24px;
	margin: 0;
	line-height: 1.2;
    padding: 16px;

	@include _breakpoint--sm {
		font-size: 32px;
	}

	&--hide {
		display: none;
	}
}
