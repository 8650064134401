$font-path: "/assets/fonts/" !default;

/*
Mulish subsetted for English, Polish & Turkish using gfonts `text` variable
characters: !"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~¢£¥¨©«®´¸»ÂÇÎÓÖÛÜâçîóöûüĄąĆćĘęĞğİıŁłŃńŚśŞşŹźŻżˆ˚˜           ​‌‍‎‏‐‑‒–—―‖‗‘’‚“”„‚‛“”„‟†‡•‣․‥…‧  ‪‫‬‭‮ ‰‱′″‴‵‶‷‸‹››※‼‽‾‿⁀⁁⁂⁃⁄⁅⁆⁇⁈⁉⁊⁋⁌⁍⁎⁏⁐⁑⁒⁓⁔⁕⁖⁗⁘⁙⁚⁛⁜⁝⁞ ⁠⁡⁢⁣⁤⁥⁦⁧⁨⁩⁪⁫⁬⁭⁮⁯€™
Stylesheet link for current version from google: https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300..800;1,300..800&&text=%21%22%23%24%25%26%27%28%29%2A%2B%2C-.%2F0123456789%3A%3B%3C%3D%3E%3F%40ABCDEFGHIJKLMNOPQRSTUVWXYZ%5B%5C%5D%5E_%60abcdefghijklmnopqrstuvwxyz%7B%7C%7D~%C2%A2%C2%A3%C2%A5%C2%A8%C2%A9%C2%AB%C2%AE%C2%B4%C2%B8%C2%BB%C3%82%C3%87%C3%8E%C3%93%C3%96%C3%9B%C3%9C%C3%A2%C3%A7%C3%AE%C3%B3%C3%B6%C3%BB%C3%BC%C4%84%C4%85%C4%86%C4%87%C4%98%C4%99%C4%9E%C4%9F%C4%B0%C4%B1%C5%81%C5%82%C5%83%C5%84%C5%9A%C5%9B%C5%9E%C5%9F%C5%B9%C5%BA%C5%BB%C5%BC%CB%86%CB%9A%CB%9C%E2%80%80%E2%80%81%E2%80%82%E2%80%83%E2%80%84%E2%80%85%E2%80%86%E2%80%87%E2%80%88%E2%80%89%E2%80%8A%E2%80%8B%E2%80%8C%E2%80%8D%E2%80%8E%E2%80%8F%E2%80%90%E2%80%91%E2%80%92%E2%80%93%E2%80%94%E2%80%95%E2%80%96%E2%80%97%E2%80%98%E2%80%99%E2%80%9A%E2%80%9C%E2%80%9D%E2%80%9E%E2%80%9A%E2%80%9B%E2%80%9C%E2%80%9D%E2%80%9E%E2%80%9F%E2%80%A0%E2%80%A1%E2%80%A2%E2%80%A3%E2%80%A4%E2%80%A5%E2%80%A6%E2%80%A7%E2%80%A8%E2%80%A9%E2%80%AA%E2%80%AB%E2%80%AC%E2%80%AD%E2%80%AE%E2%80%AF%E2%80%B0%E2%80%B1%E2%80%B2%E2%80%B3%E2%80%B4%E2%80%B5%E2%80%B6%E2%80%B7%E2%80%B8%E2%80%B9%E2%80%BA%E2%80%BA%E2%80%BB%E2%80%BC%E2%80%BD%E2%80%BE%E2%80%BF%E2%81%80%E2%81%81%E2%81%82%E2%81%83%E2%81%84%E2%81%85%E2%81%86%E2%81%87%E2%81%88%E2%81%89%E2%81%8A%E2%81%8B%E2%81%8C%E2%81%8D%E2%81%8E%E2%81%8F%E2%81%90%E2%81%91%E2%81%92%E2%81%93%E2%81%94%E2%81%95%E2%81%96%E2%81%97%E2%81%98%E2%81%99%E2%81%9A%E2%81%9B%E2%81%9C%E2%81%9D%E2%81%9E%E2%81%9F%E2%81%A0%E2%81%A1%E2%81%A2%E2%81%A3%E2%81%A4%E2%81%A5%E2%81%A6%E2%81%A7%E2%81%A8%E2%81%A9%E2%81%AA%E2%81%AB%E2%81%AC%E2%81%AD%E2%81%AE%E2%81%AF%E2%82%AC%E2%84%A2
*/

// mulish-variable-regular - english,polish,turkish
@font-face {
	font-style: normal;
	font-weight: 300 800;
	//	src: url($font-path + 'mulish-var_en-pl-tr.woff2')
	src: url(https://fonts.gstatic.com/l/font?kit=1Ptvg83HX_SGhgqU3QAqQqFdk_S_53g2xIaoNp0VDhtw7-GVUBN0tDQ-67CvTk-woiU_UymhWc6uZamsR35zmHRfyzWjoiw7o7kw5Cy088v_cJioRmLZRm08KDflvUOWty8hPgdtcVu4hVhYfQmRqWPaBdO7adZNQsVhb8oToLK7UG8_MTWYxyrFShMhBKmnzy0XyKKLH_Q89uZrWu2rYhhnLhdKxg2CnmYwqGxijs-n0LoDdxSPkwRgDJeMpUxhk0hQbBsPKJ4JCP74b5nykcZ4rGDnPGwm_iO5USqol9uqm7_w7A7PhqgZow2jCK7oqkW1plEbhX5jrnvG_MPQMBhuARiHDaK_XQ3he9wD0aM_oth0NaNtLhFM45mYLBU49w_1yGsCUVeHq53m8C8MbLFK7HhqcRtbBEdWFQZ28CTF4JAuATIfDt8aGg6pa8CrZb4sNxWxr-80J1zQ6I6h86FlZh1Xg1G6O8ZcnnHI8IP0oAZ13cP-OADgm4HKZjYO27dCopWJxswwZD6gOJ2_7WOW0ybWci8ZYN9V27q-6WMuxz2nDekyW2m0aogrgmOlG7d05z0GdLiYzdUsRpEHDl3BosSq7QtHjd3bAC4a1wqeZmLSAxJYEoy0C8nnXOEftfYU9fkFttnbZc5iLXLxcGwMRFWOzMZRSa4M2xd9Mv1B59HwMgtdBeZwS5CKa4HcUVsdb4r4qQZYXe3jvqMFgNdSQ6Q3VqSO1KI&skey=9f5b077cc22e75c7&v=v13)
		format("woff2-variations");
	font-family: "Mulish";
	font-display: swap;
}

// mulish-variable-italic - english,polish,turkish
@font-face {
	font-style: italic;
	font-weight: 300 800;
	// src: url($font-path + 'mulish-var-italic_en-pl-tr.woff2')
	src: url(https://fonts.gstatic.com/l/font?kit=1Pttg83HX_SGhgqk2gotYKNnBcIIwXowwoSqOJMXDB127eOLThF2sjI86b6hTE22pCc9LVejW8ioZ6uiSXxxnnJdySu9oC49pbsy6iK28c35cpqWeGDbQGs-Kjnrv0GQsS0jIBlvc12-h1pWcwuTr2XYug-7aNZLQshhbsoRoLe7Sm8zMTaZ_irAShohGamizyAXz6K1H_E8_-Z2WuirbxhgKS9Kxw2Bnmcwt2xjjsin0bosdxWOowRjDJaMokx_k0lQaRsOKLgJC_75b578q8ZkrGbOIA5E_kDbUErKlbrImNmS6Gmtg8x7pWjBD8SKoi7Xrzl5jxcBpRWk8KyyPXQMD3XlAtDdTX6Daqxhw9Jdsa4WIdQPO2Uu9ez6O29a73SX0RNgSy7lsOOE7FBucc0o8gUIblk5JAQ0NEYU0mWnw9ZMJXV9K5t4PEvLTIrJTfVOHl3ThaZWDBKyxMHD3u0HSFA1rAPYC5U-ryGqwtKWk1AX6ZScDVSCrdSoUWxs4-wgm83r_JVSX2DCBMLd0D_07Xu0TE16Ybw229rd6gJNxVvECI5RXw3Xbe1IhAnGEtwX71Vlf9H7x7tPS_5kAjGiranJ43kknK64EF55xHv9dBSxFmU7BvjXHLyESpt8rI137YFmraC4f7ABMA2SbBBvWyjt0oQyaO1v-1ceEbwixZeTF0w-IaITbNXpTcu_eBB-R8Kbgk87d6OAk-xmrJsxbOlUe-ru_OIZlQ&skey=12f3c822011b7b1c&v=v13)
		format("woff2-variations");
	font-family: "MulishItalic";
	font-display: swap;
}
