.b-intro {
	--max-ch: 92ch;

	// TODO :: cleanup overrides
	h2 {
		font-size: calc(var(--step-4) * 1.5);
		line-height: var(--headline-line-height);
	}
	// TODO :: cleanup overrides

	.c-icon-link {
		display: inline-flex;
	}
}

.b-intro__notifications {
	--l-wrapper-max: 58rem;
}
