.quiz-container {
	isolation: isolate;
}

.quiz-content {
	max-width: 780px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;

	h2 {
		padding-bottom: 12px;
		margin-bottom: 0;
		line-height: 1.2;

		@include _breakpoint--sm {
			margin-bottom: 8px;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 9px;
			left: 0;
			width: 100px;
			background-color: #0086cc1c;

			@include _breakpoint--md {
				width: 172px;
				height: 8px;
			}
		}
	}
}

.quiz-content--meetjs {
	background-color: rgba(0,0,0, 0.2);
    padding: 24px 32px;
    border-radius: 8px;

	h2 {
		font-size: 2.5rem;

		@include _breakpoint--sm {
			font-size: 4.6rem;
		}

		&::after {
			background-color: #f5f7ff38;
		}
	}

	h3 {
		margin: 0;
	}

	p {
		margin-top: 0;

		& + p {
			margin-top: 1.5rem;
		}
	}

	.highlight {
		color: #B3C94F;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}

.quiz-end {
	ol {
		margin: 1.5rem 2rem;
		padding: 0;

		li {
			margin: 0;
			padding-left: 4px;

			&:nth-of-type(odd) {
				background-color: #f5f7ff38;
			}

			p {
				line-height: 1.6;
				margin: 0;
			}
		}

		li + li {
			margin-top: 4px;
		}
	}

	.quiz-end__stand {
		border-radius: 8px;
		border: 12px solid white;
		margin-block: 32px;

		img {
			width: 100%;
			height: auto;
		}

		figcaption {
			background-color: #634c8b;
			text-align: center;
			line-height: 1.6;
			padding-block: 4px;
		}
	}

	.quiz-participants + .quiz-participants {
		margin-top: 32px;
	}
}
