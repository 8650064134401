.badges-wrapper {
	padding-top: 24px;
	padding-bottom: 48px;
	max-width: 490px;
	margin-inline: auto;

	@include _breakpoint--sm {
		padding-top: 48px;
		margin-inline: auto;
		max-width: 760px;
	}
}

.badge-row {
	max-width: 400px;
	margin-inline: auto;

	@include _breakpoint--sm {
		max-width: initial;
		margin-inline: initial;
		display: flex;
		gap: 20px;
	}

	.badge {
		margin-inline: auto;
		@include _breakpoint--sm {
			margin-left: initial;
		}
	}

	.badge-content {
		text-align: center;

		@include _breakpoint--sm {
			text-align: initial;
		}
	}

	.badge-list {
		.badge-link {
			display: flex;
			gap: 8px;
			text-align: initial;
			margin-inline: initial;
		}
	}

	& + & {
		@include _breakpoint--sm {
			position: relative;
			padding-top: 24px;
			margin-top: 24px;
		}

		&::before {
			@include _breakpoint--sm {
				content: "";
				position: absolute;
				top: 0;
				left: 60%;
				height: 3px;
				width: 130px;
				transform: translateX(-50%);
				background-color: #efecee;
			}
		}
	}

	& > .badge {
		margin-bottom: -20px;
		@include _breakpoint--sm {
			margin: 0 auto -20px;
		}
	}
}

.badge-list {
	padding-left: 8px;

	.badge {
		width: 32px;
		height: 32px;
	}

	li {
		display: flex;
	}

	p {
		margin: 0;
	}
}

.badge {
	display: block;
	flex-shrink: 0;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.badge-cke5,
.badge-cke4,
.badge-ckbox,
.badge-premium-features {
	width: 128px;
	height: 128px;
}

.badge-cke5 {
	background-image: image_url("cke5.svg", "icon");
}

.badge-cke4 {
	background-image: image_url("cke4.svg", "icon");
}

.badge-premium-features {
	background-image: image_url("premium-features.svg", "icon");
}

.badge-revision-history {
	background-image: image_url("revision-history.png", "icon");
}

.badge-ckbox {
	background-image: image_url("ckbox.svg", "icon");
}

.badge-real-time-collaboration {
	background-image: image_url("real-time.svg", "icon");
}

.badge-track-changes {
	background-image: image_url("track-changes.svg", "icon");
}

.badge-export-pdf {
	background-image: image_url("export-pdf.png", "icon");
}

.badge-comments {
	background-image: image_url("comments.svg", "icon");
}
