.shirt-box {
	display: none;
	margin-top: 8px;
	.shirt-sizes {
		display: flex;
		align-items: center;
		flex-direction: column-reverse;

		@include _breakpoint--sm {
			flex-direction: row-reverse;
		}

		.shirt-sizes__image {
			margin: 0;

			img {
				max-width: 100px;
			}
		}

		.shirt-sizes__box {
			display: flex;
			flex-direction: column;
			width: 100%;
			text-align: center;

			@include _breakpoint--sm {
				margin-right: 32px;
			}
		}

		.table + .table {
			margin-top: 16px;
		}

		.table {
			font-size: 14px;
			margin: 0;

			@include _breakpoint--xsm {
				font-size: 16px;
			}

			thead {
				background-color: #333333;
				color: #ffffff;
			}

			td,
			th {
				padding: 4px;

				&:first-of-type {
					width: 130px;
				}
			}

			tbody {
				tr:nth-of-type(odd) {
					background-color: #ffc5f229;
				}
			}
		}
	}
}

.shirt-box--active {
	display: block;
}

.btn-shirts-box {
	width: 100%;
	margin-top: 4px;
	margin-bottom: 16px;
	@include _breakpoint--sm {
		width: auto;
		margin: 0;
		margin-left: auto;
	}
}

.btn-shirts {
	color: var(--color-tang-5);
	border: none;
	padding: 0;
	text-decoration: underline;
	font-size: 14px;
	line-height: 1.5;

	@include _breakpoint--sm {
	}
}
