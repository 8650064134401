.icon-star {
    display: block;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-image: image_url( 'stars.png', 'icon' );
        background-repeat: no-repeat;
        background-size: contain;
        display: none;

        @include _breakpoint--sm {
            display: block;
            width: 180px;
            height: 45px;
            top: -24px;
        }

        @include _breakpoint--md {
            top: -60px;
            width: 270px;
            height: 65px;
        }
    }
    &::before {
        left: 50%;
        transform: translateX(-50%);

        @include _breakpoint--sm {
            left: 0;
            transform: rotate(15deg);
        }
    }

    &::after {
        right: 50%;
        transform: translateX(-50%);

        @include _breakpoint--sm {
            right: 0;
            transform: rotate(-15deg);
        }
    }
}
