@import "./colors";

$border-radius: 4px;
$border-color: var(--color-shark-4);

/**
 * Display border with default color.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border.
 * @param {String} $width [ 1px ] - Width of the border.
 */
@mixin _border($side: all, $width: 1px) {
	@if ($side == all) {
		border: $width solid $border-color;
	}
	@if ($side == left) {
		border-left: $width solid $border-color;
	}
	@if ($side == right) {
		border-right: $width solid $border-color;
	}
	@if ($side == top) {
		border-top: $width solid $border-color;
	}
	@if ($side == bottom) {
		border-bottom: $width solid $border-color;
	}
}

/**
 * Set predefinied border radius.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border radius.
 */
@mixin _radius($side: all) {
	@if ($side == all) {
		border-radius: $border-radius;
	}
	@if ($side == right) {
		border-radius: $border-radius 0 0 $border-radius;
	}
	@if ($side == bottom) {
		border-radius: 0 0 $border-radius $border-radius;
	}
	@if ($side == left) {
		border-radius: 0 $border-radius $border-radius 0;
	}
	@if ($side == top) {
		border-radius: $border-radius $border-radius 0 0;
	}
}
