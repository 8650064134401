@import "./icon-star";
@import "./icon-box";
@import "./top-info";
@import "./gallery";
// @import './numbers';
@import "./employee";
// @import './why';
@import "./section-background";

p,
li,
a {
	font-size: _font-scale(medium);
	line-height: 1.6;

	@include _breakpoint--sm {
		font-size: _font-scale(big);
		line-height: 1.4;
	}
}

section.section-about-page {
	padding-top: 0;
}
