$filter-box-padding: 12px 24px;
$filter-box-background-color: #ffffff;
$filter-box-border: 2px solid transparent;
$filter-box-border-radius: 4px;

.offer-filter-box {
	width: 100%;

	@include _breakpoint--sm {
		padding-left: 8px;
		padding-right: 8px;
	}

	& + .offer-filter-box {
		margin-top: 8px;

		@include _breakpoint--sm {
			margin-top: initial;
		}
	}
}

.offer-filter-label {
	display: block;
}

.offer-select {
	display: grid;
	grid-template-areas: "select";
	align-items: center;
	position: relative;
	border: $filter-box-border;
	border-radius: $filter-box-border-radius;
	font-size: _font-scale(normal);
	cursor: pointer;
	line-height: 1.1;
	background-color: #fff;
	background-image: linear-gradient(to top, #f9f9f9, var(--color-seal-1) 33%);
	@include _shadow;

	select {
		grid-area: select;
		appearance: none;
		background-color: transparent;
		border: none;
		padding: $filter-box-padding;
		margin: 0;
		width: 100%;
		font-family: inherit;
		font-size: inherit;
		cursor: inherit;
		line-height: inherit;
		z-index: 1;
		outline: none;

		&:focus + .focus {
			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;
			border: 2px solid var(--color-tang-5);
			border-radius: inherit;
		}
	}

	&::after {
		content: "";
		grid-area: select;
		justify-self: end;
		width: 0.8em;
		height: 0.5em;
		background-color: var(--color-tang-5);
		margin-right: 12px;
		clip-path: polygon(100% 0%, 0 0%, 50% 100%);
	}
}
