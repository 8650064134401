@use "../../../src/sass/tools/media";

$side-menu__width: 280px;
$side-menu__width--mobile: 230px;
$side-menu__trigger-width: 40px;
$z-level: (
	"id": _get-zindex(cover) + 3,
	"panel": _get-zindex(cover) + 2,
	"cover": _get-zindex(cover) + 1,
);

.site-nav {
	position: absolute;
	top: 0;
	right: 0;
	height: 50px;
	z-index: map-get($z-level, "id");
	@include _transition(padding);

	@include media.small {
		display: none;
	}

	&--active {
		position: fixed;
		top: 0;
		@include _transition;
	}

	&__toggler {
		position: absolute;
		top: 0.5rem;
		right: 1rem;
		height: 39px;
		width: $side-menu__trigger-width;
		padding: 0;
		cursor: pointer;
		@include _button-reset;
		@include _transition;

		@include _breakpoint--md {
			left: _space();
		}

		&:focus,
		&:active {
			outline: none;
		}

		.bar {
			position: absolute;
			left: 5px;
			height: 3px;
			width: 30px;
			display: block;
			background-color: var(--color-seal-1);
			border-radius: 10px;
			@include _transition(transform, 300ms);
			@include _shadow(small);

			&:nth-of-type(1) {
				top: 10px;
				transition: top 200ms ease-in-out 100ms,
					transform 200ms ease-in-out 100ms;
				animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
			}

			&:nth-of-type(2) {
				top: 18px;
				transition: opacity 100ms ease-in-out 100ms;
				animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
			}

			&:nth-of-type(3) {
				top: 26px;
				transition: top 200ms ease-in-out 100ms,
					transform 200ms ease-in-out 100ms;
				animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
			}

			.p-home & {
				background-color: var(--color-tang-5);
			}
		}

		.p-home & {
			top: var(--space-l);
			right: var(--space-l);
		}

		&--active {
			.bar {
				&:nth-of-type(1) {
					top: 22px;
					// transform: rotate(35deg) scale(0.7, 1) translate(8px, 6px);
					transform: rotate(45deg) translate(0px, -5px);
					transition: top 200ms ease-in-out 100ms,
						transform 200ms ease-in-out 100ms;
				}

				&:nth-of-type(2) {
					opacity: 0;
				}

				&:nth-of-type(3) {
					top: 22px;
					// transform: rotate(-35deg) scale(0.7, 1) translate(5px, -2px);
					transform: rotate(-45deg) translate(5px, 0px);
					transition: top 200ms ease-in-out 100ms,
						transform 200ms ease-in-out 100ms;
				}
			}
		}
	}

	&__panel {
		position: fixed;
		top: 0;
		right: -$side-menu__width--mobile;
		height: 100vh;
		width: $side-menu__width--mobile;
		padding: {
			top: _double-space();
			left: _space() - 1rem;
			right: _space() - 1rem;
		}
		background-color: var(--color-tang-8);
		overflow-y: auto;
		z-index: map-get($z-level, "panel");
		@include _transition(transform, 300ms);

		&--active {
			transform: translateX(-$side-menu__width--mobile);
		}

		@include _breakpoint--sm {
			left: -$side-menu__width;
			width: $side-menu__width;

			&--active {
				transform: translateX($side-menu__width);
			}
		}

		.c-sprite-icon--ui-new-tab {
			--c-sprite-icon-unit: 16px;
			margin-left: var(--space-3xs);
		}
	}

	&__menu,
	&__submenu {
		@include _list-reset;

		&-item {
			position: relative;
			a {
				display: block;
				color: var(--color-seal-1);
				padding: 1.2rem 0.2rem;
				font-weight: 600;
				line-height: 1.2;
				border-bottom: 1px solid
					color-mix(
						in srgb,
						var(--color-seal-1) 0.2,
						var(--color-mix-base, transparent)
					);

				&:focus {
					outline: none;
					background-color: color-mix(
						in srgb,
						var(--color-seal-1) 0.2,
						var(--color-mix-base, transparent)
					);
				}
				&:focus,
				&:hover {
					text-decoration: none;
				}
			}

			&.menu-product__list-wrapper {
				padding-bottom: 1.2rem;
				border-bottom: 1px solid
					color-mix(
						in srgb,
						var(--color-seal-1) 0.2,
						var(--color-mix-base, transparent)
					);

				& > a {
					border-bottom: 0;
				}
			}

			&--current::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				background-color: var(--color-tang-5);
			}
		}
	}

	&__submenu {
		&-item {
			a {
				border-bottom: 0;
				padding-left: _space() - 1rem;
			}
		}
	}
}

.site-cover,
.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	background-color: color-mix(
		in srgb,
		var(--color-shark-1) 0.8,
		var(--color-mix-base, transparent)
	);
	@include _zindex(under);
	@include _transition(opacity);

	&--active {
		z-index: map-get($z-level, "cover");
		opacity: 1;
	}

	&--hide {
		opacity: 0;
	}
}
