$benefits-margin-x--mobile: 12px;
$benefits-margin-x: 32px;

.benefits {
	background-color: #ffffff;
	position: relative;
	overflow: hidden;
	z-index: 1;

	h2 {
		z-index: 1;
	}

	.c-sprite-icon {
		--c-sprite-icon-height: 90px;
		--c-sprite-icon-width: 90px;
		margin: 0 auto;
		display: block;
	}
}

.benefits-wrapper {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin-top: _double-space();
	margin-left: -$benefits-margin-x--mobile;
	margin-right: -$benefits-margin-x--mobile;
	display: grid;
	grid-template-columns: 1fr;

	@include _breakpoint--xsm {
		grid-template-columns: 1fr 1fr;
	}

	@include _breakpoint--sm {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		margin-left: -$benefits-margin-x;
		margin-right: -$benefits-margin-x;
	}
}

.benefit-item {
	padding: 0 $benefits-margin-x--mobile;
	width: 100%;
	// margin-bottom: _space();

	@include _breakpoint--xsm {
		// width: 50%;
	}

	@include _breakpoint--sm {
		// width: 33.333%;
		padding: 0 $benefits-margin-x;
		// margin-bottom: _double-space();
	}

	@include _breakpoint--md {
		// width: 25%;
	}
}

.benefit {
	.benefit__image {
		z-index: 1;
		display: block;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: _half-space();
		border-radius: 50%;
		width: 140px;
		height: 140px;
		border: 2px dashed var(--color-tang-5);
		background: {
			color: var(--color-seal-1);
			image: image_url("benefits-mo@2x.png", "bg");
			repeat: no-repeat;
			size: 100% 120px;
			position: center;
		}
		@include _shadow;

		@include _breakpoint--sm {
			width: 210px;
			height: 210px;
			background-size: auto 140px;
		}

		&--collaborative-env {
			background-image: image_url("collaborative-env.svg", "icon");
		}

		&--creative-people {
			background-image: image_url("open-minded.svg", "icon");
		}

		&--open-source {
			background-image: image_url("open-source.svg", "icon");
		}

		&--fussball {
			background-image: image_url("table-tennis.svg", "icon");
		}

		&--stability {
			background-image: image_url("stability.svg", "icon");
		}

		&--healthcare {
			background-image: image_url("healthcare.svg", "icon");
		}

		&--fitness {
			background-image: image_url("fitness.svg", "icon");
		}

		&--remote {
			background-image: image_url("remote.svg", "icon");
		}
	}
	.benefit__text {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		max-width: 290px;
		font-size: _font-scale(small);
		line-height: 1.4;
		margin-bottom: var(--space-xs);

		@include _breakpoint--xsm {
			font-size: _font-scale(medium);
		}

		@include _breakpoint--sm {
			font-size: _font-scale(normal);
		}

		@include _breakpoint--md {
			font-size: _font-scale(big);
		}
	}
}
