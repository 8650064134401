.quiz-result {
	display: none;
	position: relative;
	visibility: hidden;
	margin-top: 24px;
	height: 100%;

	.quiz-result-content {
		max-width: 460px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		overflow: auto;
		.points-box {
			display: inline-block;
			margin-top: 8px;
			padding: 4px 8px;
			background-color: #0086cc1c;
			color: #000;
		}
	}

	&.quiz-row-box--active {
		margin-top: 0;
		display: block;
		visibility: visible;
	}

	.points-result {
		text-align: center;
		font-size: 32px;
		line-height: 1.2;
		margin: 0;
		padding: 8px 8px 16px;
		border-bottom: 2px solid #0086cc1c;

		& > span {
			display: block;

			@include _breakpoint--sm {
				display: inline;
			}
		}
	}

	.btn-close-quiz {
		position: absolute;
		top: 16px;
		right: 8px;
		border: none;
		padding: 0;
		width: 24px;
		height: 24px;
		text-indent: -9999px;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			transform-origin: center;
			width: 90%;
			height: 2px;
			background-color: #000000;
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}

.quiz-take-action {
	display: none;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 32px;

	&.quiz-row-box--active {
		display: flex;
	}

	.text {
		font-size: 22px;
		line-height: 1.3;
		max-width: 380px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 32px;

		@include _breakpoint--sm {
			margin-bottom: auto;
		}
	}
}

.quiz-result-box,
.quiz-user-box {
	position: absolute;
	top: 0;
	left: 0;
    flex-direction: column;
	height: 100%;
	width: 100%;
	transform: translateX(calc(100% + 16px));
	transition: .25s ease-in-out transform;
	padding-left: 8px;
	padding-right: 8px;
	display: none;
	visibility: hidden;

	&.quiz-row-box--active {
		display: flex;
		margin-top: 0;
		transform: translateX(0);
		visibility: visible;
	}
}

.quiz-result-image {
	margin-top: 8px;
	margin-bottom: 8px;
	width: 100%;
}

.quiz-result-icon {
	margin-top: 1.5rem;
	max-width: 100%;
}

.user-box-active {
	transition: .25s ease-in-out height;

	@include _breakpoint--sm {
		height: 75vh;
	}
}

.quiz-action {
	padding-bottom: 24px;
}

.questions-answers {
	margin-top: 12px;
	max-height: calc(80%);
	padding: 0 16px 24px;
	overflow-y: auto;

	@include _breakpoint--sm {
		max-height: 410px;
		padding: 0;
	}

	.qst,
	.ans {
		margin: 0;
	}

	.ans + .qst {
		margin-top: 16px;
	}

	.qst {
		line-height: 1.2;
		font-size: 15px;
		font-weight: bold;
	}

	.ansrs {
		list-style-position: inside;
		list-style-type: lower-alpha;
		margin: 2px 0 8px;
		padding-left: 8px;
	}

	.ans {
		font-size: 15px;
		line-height: 1.6;
		padding: 0 8px;
	}

	.ans--checked {
		background-color: #cc00001c;
	}

	.ans--correct {
		background-color: #00cc3e1c;
	}
}
