.quiz-form {
	position: relative;
	transition: .25s ease-in-out height;
	min-height: initial;
	margin: 0 16px 8px;
	height: calc(var(--vh, 1vh) * 75);

	input[type="file"] {
		display: flex;
		align-items: center;
		font-size: 16px !important;
	}

	@include _breakpoint--sm {
		height: 550px;
	}

	.form__label--checkbox {
		font-size: 14px;
		line-height: 1.3;
	}
}

.btn-start-quiz {
	margin-top: 8px;
	width: 100%;
}


.quiz-form--questions {
	@include _breakpoint--sm {
		height: 360px;
	}
}

.quiz-results,
.quiz-row-box {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform: translateX(calc(-100% - 16px));
	transition: .25s ease-in-out transform;
}

.quiz-row-box {
	visibility: hidden;
	.quiz-row {
		height: 100%;
	}
}


.quiz-row {
	display: flex;
	flex-direction: column;
}

.quiz-row-box--active {
	transform: translateX(0);
	visibility: visible;

	& ~ .quiz-row-box {
	transform: translateX(calc(100% + 16px));

	}
}

.quiz-question {
    display: inline-block;
	text-align: center;
	background-color: #0086cc1c;
    color: #000000;
    padding: 4px 16px;
	margin-top: 4px;
	margin-bottom: 8px;
	max-height: 240px;
	overflow: auto;

	@include _breakpoint--sm {
		padding: 4px;
		margin-top: _half-space();
		margin-bottom: _half-space();
	}

	pre {
		margin: 0;
	}

	code {
		display: block;
		text-align: left;
		font-size: 13px;
		line-height: 1.4;
		background: #423e62;
		margin: 8px;
		padding: 8px 12px;
		color: #fff;
	}
}

.quiz-answers {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	margin-left: 4px;
	margin-right: 4px;

	@include _breakpoint--sm {
		margin-top: auto;
		margin-left: -4px;
		margin-right: -4px;
		flex-direction: row;
		flex-grow: initial;
	}
}

.quiz-answer {
	display: flex;
	padding-left: 4px;
	padding-right: 4px;
	width: 100%;
	flex-grow: 1;

	@include _breakpoint--sm {
		flex-grow: initial;
		width: 50%;
	}
}

.quiz-answer-row {
	display: flex;
    align-items: center;
	margin-top: 4px;
	margin-bottom: 4px;
	border: 1px solid #f382b6;
	width: 100%;

	@include _breakpoint--sm {
		display: block;
	}

	input {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	}

	.quiz-label {
		text-align: center;
		cursor: pointer;
		font-size: 16px;
		line-height: 1.2;
		position: relative;
		margin: 0;
		padding: 8px;
		width: 100%;

		@include _breakpoint--sm {
			font-size: 17px;
			text-align: left;
			min-height: 80px;
			padding: 4px 12px 4px 32px;
		}

		&::before {
			@include _breakpoint--sm {
				content: '';
				position: absolute;
				top: 6px;
				left: 8px;
				border: 2px solid #f382b6;
				width: 16px;
				height: 16px;
				border-radius: 4px;
				transition: .25s ease-in-out background-color;
				background-color: transparent;
			}
		}
		&:hover {
			text-decoration: underline;

			&::before {
				background-color: #f382b6;
			}
		}
	}

	input:checked + .quiz-label {
		&::before {
			background-color: #f382b6;
		}
	}

}
