.about-company-section {
	padding: 0;
}

.section-about {
	margin-top: 24px;
	margin-bottom: 24px;

	@include _breakpoint--sm {
		margin-top: 84px;
		margin-bottom: 42px;
	}

	.about-us-content {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include _breakpoint--sm {
			margin-top: 40px;
			flex-direction: row;
			gap: 57px;
		}

		&--reverse {
			@include _breakpoint--sm {
				flex-direction: row-reverse;
			}
		}

		& + .about-us-content {
			margin-top: 40px;

			@include _breakpoint--sm {
				margin-top: 64px;
			}
		}
	}

	.about-text {
		max-width: 478px;
		margin-top: 0;
		margin-bottom: 32px;

		@include _breakpoint--sm {
			font-size: 20px;
			line-height: 1.6;
			margin: 0;
			flex: 1 1.5 auto;
		}

		.highlight {
			font-weight: 400;
			background-color: #ec519a1a;
			padding-left: 4px;
			margin-right: 4px;

			&--no-lm {
				margin-right: 0;
			}

			&--secondary {
				background-color: #eb4d9742;
			}
		}
	}

	.about-image,
	.about-image--small {
		min-width: 0;
		width: 100%;
		height: auto;
		max-width: 524px;
		object-fit: contain;
	}

	.about-image--small {
		background: #f5f7ff;
		padding: 12px;
		border-radius: 24px;
		max-width: 524px;
	}
}
.about-us-content {
	flex-direction: column !important;
	width: 100% !important;

	max-width: 77ch;
	margin: 0 auto;
	text-wrap: pretty;
}

p.about-text {
	max-width: unset !important;
}
