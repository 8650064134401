$footer__offset: _space();
$footer__menu-width: 210px;

.footer {
	padding: {
		top: $footer__offset;
		bottom: $footer__offset;
	}
	color: var(--color-seal-2);
	background: {
		color: var(--color-shark-1);
		// image: image_url("footer-shadow-mo.svg", "bg");
		repeat: no-repeat;
		size: auto 100%;
	}

	@include _breakpoint--sm {
		font-size: _font-scale(medium);
		background: {
			// image: image_url("footer-shadow.svg", "bg");
			position-x: -223px;
			size: auto 463px;
		}
	}

	@include _breakpoint--md {
		background-position-x: -158px;
	}

	.footer-main {
		width: 100%;
	}

	.footer__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;

		@include _breakpoint--sm {
			align-items: flex-start;
			gap: 48px;
		}
	}

	&-content__wrapper {
		width: 100%;
		padding: 0 2.5rem;

		@include _breakpoint--sm {
			@include _section;
		}
	}

	&-main {
		display: flex;
		margin-bottom: _space();

		@include _breakpoint--sm {
			text-align: right;
		}

		@include _breakpoint--mo {
			margin-bottom: 1rem;
			display: flex;
			flex-direction: column-reverse;

			&.section-container {
				width: 100%;
				padding: 0 _space();
			}
		}

		h2 {
			text-align: left;
			color: var(--color-seal-1);
			font-weight: 800;
			line-height: _space();
			font-size: _font-scale(big);
			margin: {
				top: 1rem;
				bottom: 1rem;
			}

			@include _breakpoint--mo {
				display: none;
			}

			&:after {
				display: none;
			}
		}
	}

	&__column {
		vertical-align: top;

		@include _breakpoint--sm {
			display: inline-block;
			margin-right: _space();
			text-align: left;

			&:nth-of-type(1) {
				min-width: 19rem;
			}

			&:nth-of-type(1),
			&:nth-of-type(2) {
				float: left;
			}

			&:nth-of-type(3),
			&:nth-of-type(4) {
				margin-right: 0;
				padding-left: _space();
			}

			&:nth-of-type(2) {
				margin-left: auto;
			}

			&:nth-of-type(3) {
				margin-right: _space();
			}
		}

		@include _breakpoint--sm-plus {
			margin-right: _double-space() * 2;
		}

		h3 {
			color: var(--color-seal-1);
			font-weight: 800;
			line-height: _space();
			font-size: _font-scale(medium);

			& + p {
				margin-top: 0;
			}
		}
	}

	&__address {
		@include _breakpoint--mo {
			text-align: center;
			margin-top: _space();
		}

		a {
			color: var(--color-seal-2);

			&:hover {
				color: var(--color-seal-1);
			}
		}
	}

	&-menu,
	&-socials {
		&,
		ul {
			@include _list-reset;
		}

		a {
			color: var(--color-seal-2);

			&:hover {
				color: var(--color-seal-1);
			}
		}
	}

	&-socials {
		@include _breakpoint--mo {
			display: none;
		}
	}

	.footer-social-mo {
		text-align: center;
		margin: {
			top: _space();
			bottom: _space();
		}

		@include _breakpoint--sm {
			display: none;
			margin-bottom: 0;
		}

		@include _breakpoint--sm-plus {
			text-align: inherit;
			margin: {
				top: _space();
				bottom: _double-space();
			}
		}

		&__ico {
			@include _icon;
			margin-right: 1rem;

			&:last-of-type {
				margin-right: 0;
			}

			&--fb {
				@extend .svg-ico__facebook-circle;
			}

			&--g-plus {
				@extend .svg-ico__google-circle;
			}

			&--twitter {
				@extend .svg-ico__twitter-circle;
			}

			&--linkedin {
				@extend .svg-ico__linkedin-circle;
			}

			&--instagram {
				// Whait for the icon.
				@extend .svg-ico__instagram-circle;
			}

			&--small {
				@include _breakpoint--sm-plus {
					transform: scale(0.8);
				}
			}
		}
	}

	&-menu {
		&__item {
			a[target="_blank"] {
				position: relative;

				&:after {
					content: url("data:image/svg+xml;utf8,<svg fill='none' stroke='#dbdcdc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='16' height='16' xmlns='http://www.w3.org* 0.5000/svg'><path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'/><polyline points='15 3 21 3 21 9'/><line x1='10' x2='21' y1='14' y2='3'/></svg>");
					position: absolute;
					right: -23px;
					top: 0;
					display: block;
				}
			}
		}

		@include _breakpoint--mo {
			&__list {
				width: 100%;
			}

			&__item {
				padding: 1rem 0;
				border-bottom: 1px solid var(--color-shark-4);
			}
		}
	}

	&-policy {
		@include _section;
		font-size: _font-scale(tiny);
		text-align: center;

		@include _breakpoint--sm {
			font-size: _font-scale(small);
		}

		@include _breakpoint--md {
			text-align: inherit;
		}

		&__rights,
		&__menu {
			text-align: center;

			@include _breakpoint--sm-plus {
				text-align: inherit;
			}
		}

		&__rights {
			margin: 0;
			color: var(--color-seal-2);
		}

		&__menu {
			@include _list-reset;

			@include _breakpoint--sm {
				float: right;
			}

			li {
				display: inline-block;
				margin-right: _space() * 0.5;

				&:last-of-type {
					margin-right: 0;
				}

				a {
					color: var(--color-seal-2);
				}
			}
		}
	}
}
