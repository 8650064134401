.support {
	&__tiles {
		display: flex;
		flex-wrap: wrap;

		&-item {
			position: relative;
			width: $mobile-item-width;
			max-width: 90%;
			color: inherit;
			padding: _space();
			text-align: center;
			@include _shadow(tiny);

			@include _breakpoint--sm {
				width: 33.3333%;
			}

			@include _breakpoint--lg {
				width: 25%;
			}

			.support__ico-item {
				@include _transition;
			}

			&:hover,
			&:focus {
				background-color: var(--color-seal-2);
				text-decoration: none;
				outline: none;
				color: inherit;

				.support__ico-item {
					transform: scale(1.2);
				}
			}
		}
	}

	&__ico-item {
		display: block;
		margin: 0 auto;
	}
}
