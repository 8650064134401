.form-response {
	display: none;
	text-align: center;
	padding: _space();
	margin: _space();
	border: {
		width: 2px;
		style: solid;
		color: transparent;
	}
	border-radius: var(--space-3xs);
	background-color: color-mix(
		in srgb,
		var(--color-seal-1) 0.8,
		var(--color-mix-base, transparent)
	);

	&:before {
		content: "";
		width: 36px;
		height: 36px;
		display: inline-block;
		margin-right: _space();
		vertical-align: middle;
		background: {
			size: cover;
			repeat: no-repeat;
		}
	}

	&--error {
		color: var(--color-tropical-4);
		border-color: var(--color-tropical-4);

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org* 0.5000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%23D9534F' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm56.6 207.2c-6 6-15.7 6-21.7 0L150.7 173 115 208.7c-6 6-15.6 6-21.6 0-6-6-6-15.7 0-21.7l35.7-35.6L96 118c-6-6-6-15.6 0-21.5 6-6 15.6-6 21.6 0l33.2 33.2L182.4 98c6-6 15.7-6 21.7 0s6 15.7 0 21.7l-31.5 31.7 34 34c6 6 6 15.8 0 21.8z'/%3E%3C/svg%3E%0A");
		}
	}

	&--success {
		color: var(--color-comet-6);
		border-color: var(--color-comet-6);

		&:before {
			background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org* 0.5000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%235CB85C' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm73.4 128.8l-76.7 69.5c-2 1.7-4 2.8-6.3 3.4-2 1-4.2 1.4-6.3 1.4-3.7 0-7.5-1.3-10.5-4l-47.2-45c-6-5.8-6.4-15.5-.5-21.7 5.8-6 15.5-6.4 21.7-.5l37.5 35.6 67.8-61.3c6.3-5.6 16-5 21.7 1.2 5.7 6.2 5.2 16-1 21.6z'/%3E%3C/svg%3E%0A");
		}
	}

	&--active {
		display: block;
		@include fadeIn($duration: 300ms, $function: ease-in-out, $fill: both);
	}
}
