@use "../../../src/sass/tools/media";

$content-max-width: 1200px;
$height: 500px;
$height--mo: 240px;
$height--career-mo: 360px;
$height--big: 720px;
$white-underline-height: 5px;
$white-underline-height--mo: 2px;

#chevrony {
	height: 1720px;
	width: auto;
	transform: scale(1.2);
}

.header {
	position: relative;
	height: $height--mo;
	background: {
		attachment: fixed;
		size: auto $height--mo;
		color: var(--color-shark-1);
		repeat: no-repeat;
		position: center top;
	}
	color: var(--color-seal-1);
	overflow: hidden;

	.header-wrapper {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: _space();
		width: 100%;
		@include _zindex(air);

		@include _breakpoint--sm {
			bottom: 65px;
		}

		.header-wrapper-inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			gap: 15px;

			@include _breakpoint--sm {
				flex-direction: row;
			}
		}

		.header-gptw {
			display: block;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			width: 77px;
			height: 129px;
			transition: 0.25s ease-in-out filter;
			background-image: image_url("gptw.svg", "icon");

			@include _breakpoint--sm {
				width: 95px;
				height: 160px;
				align-self: flex-end;
			}

			&:hover {
				filter: brightness(1.1);
			}
		}

		.header-wrapper-text {
			max-width: 760px;
			@include _breakpoint--sm {
				margin-bottom: 70px;
			}
		}

		h1 {
			position: relative;
			padding-bottom: 1.5rem;

			@include _breakpoint--sm {
				padding-bottom: $main-space--px;
				line-height: 1.05;
			}

			@include _breakpoint--md {
				padding-bottom: $main-space--px + $white-underline-height;
				line-height: _space() * 3;
			}
		}
	}

	picture {
		img {
			min-width: 100%;
			height: $height--mo;
			object-position: center center;
			object-fit: cover;
			width: 100%;

			@include _breakpoint--sm {
				height: $height;
			}

			@include _breakpoint--md {
				height: auto;
				width: auto;
			}
		}
	}

	@include _breakpoint--sm {
		height: $height;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: color-mix(
			in srgb,
			var(--color-shark-1) 10%,
			var(--color-mix-base, transparent)
		);
	}

	&-cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 238px;
		height: $height;
		opacity: 1;
		@include _zindex(float);
		@include _transition;

		@include _breakpoint--sm {
			width: 100%;
			background: {
				color: rgba(0, 0, 0, 0.25);
			}
		}

		@include _breakpoint--md {
		}

		&::after {
			content: "";
			height: 200%;
			width: 200%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background: var(--color-tang-6);
			clip-path: url(#chevrony);
			opacity: 0.4;
			transform: translate(-649px, -300px);
		}

		&::before {
			content: "";
			height: 200%;
			width: 200%;
			display: block;
			position: relative;
			top: 0;
			left: 0;
			background: var(--color-tang-5);
			clip-path: url(#chevrony);
			opacity: 0.65;
			transform: translate(-557px, -351px);
		}
	}

	&--big {
		@include _breakpoint--sm {
			height: $height;
		}

		@include _breakpoint--md {
			height: $height--big;
			background: {
				size: 1920px $height--big;
			}

			.header-cover {
				height: $height--big;
				// background: {
				// 	image: image_url("big-header-shape.svg", "bg");
				// 	size: auto $height--big;
				// }
			}

			.header-content {
				width: 520px;
				bottom: 110px;
			}
		}
	}

	&--about-us {
		.header-content {
			@include _breakpoint--sm {
				padding-right: calc(10vw + 151px);
			}

			@include _breakpoint--lg {
				padding-right: initial;
			}
		}
	}

	&--about-us,
	&--careers {
		z-index: 2;
		height: $height--career-mo;

		@include _breakpoint--sm {
			height: $height;
		}

		.header-cover {
			background-image: none;
			@include _breakpoint--mo {
				width: 100%;
				height: $height--career-mo;
				background-color: rgba(0, 0, 0, 0.35);
				background-size: $height--career-mo;
				background-position-y: bottom;
			}
		}

		.header-content {
			@include _breakpoint--mo {
				bottom: 50%;
				transform: translateY(50%);
			}
		}

		picture {
			img {
				height: $height--career-mo;

				@include _breakpoint--sm {
					height: $height;
				}

				@include _breakpoint--md {
					height: auto;
				}
			}
		}
	}
	&--about-us {
		picture {
			img {
				@include _breakpoint--md {
					height: $height;
					width: 100%;
				}
			}
		}
	}

	.btn.btn-cta--long {
		background-color: var(--color-seal-1);
		color: var(--color-shark-1);

		&:hover {
			background-color: var(--color-seal-1);
			color: var(--color-tang-5);
		}
	}
}
.header--left {
	text-align: left;
}

.header--center {
	text-align: center;
}

.header-separator--big {
	margin-bottom: 16px;

	@include _breakpoint--sm {
		margin-bottom: 32px;
	}
}

.header-about-us {
	font-weight: 300;
	line-height: 1.6;
	padding-bottom: 0;
}

.header-neo.header--home {
	background: var(--color-seal-1);
	color: var(--color-shark-1);
	top: var(--menu-height);
	height: 75vw;
	margin-bottom: calc(calc(var(--menu-height) * 1.1) - 11px);

	@include media.small {
		height: 800px;
	}
	&::after {
		content: none;
		display: none;
	}

	h1 {
		font-size: calc(var(--step-4) * 1.3);
		padding-bottom: 0;
		line-height: 1.1;
		color: var(--color-shark-1);
		text-wrap-style: balance;
		text-align: center;
		font-weight: 800;

		&::after {
			display: none !important;
		}
		@include media.x-small {
			font-size: calc(var(--step-4) * 1.5);
		}
		@include media.small {
			font-size: calc(var(--step-4) * 2.1);
		}
	}

	.header-wrapper {
		bottom: unset;
		width: 100vw;
		height: 100%;
	}

	.header-wrapper-inner {
		height: 100%;
	}

	.header-wrapper-decorators {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		height: 100%;

		@include media.small {
			padding-bottom: var(--space-3xl);
		}
	}

	.header-wrapper-text {
		margin: 0 auto;
		padding: calc(var(--space-s) * 1.5) calc(var(--space-m) * 1.5) 0;
		height: 100%;

		@include media.x-small {
			padding: calc(var(--space-l) * 1.5) calc(var(--space-m) * 1.5) 0;
		}

		@include media.small {
			padding: calc(var(--space-2xl) * 1.3) calc(var(--space-m) * 1.5) 0;
		}
	}

	.header-wrapper-ctas {
		justify-content: center;
		position: relative;
		padding-top: var(--space-s);

		@include media.small {
			padding-top: var(--space-3xl);
		}
		.c-cta {
			// padding: var(--space-xs) var(--space-l);
		}
	}

	.c-sprite-icon.c-sprite-icon--deco-large-hex {
		--c-sprite-stroke-width: 1.5px;
		color: var(--color-seal-1);
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		height: 814px;
		filter: drop-shadow(0 14px 31px rgba(0, 0, 0, 0.05))
			drop-shadow(0 56px 56px rgba(0, 0, 0, 0.04))
			drop-shadow(0 126px 76px rgba(0, 0, 0, 0.02))
			drop-shadow(0 224px 90px rgba(0, 0, 0, 0.011));
		transform: translate(-50%, calc(-45% - clamp(144px, 43vw, 117px)));
		min-width: 360px;

		@include media.x-small {
			transform: translate(-50%, calc(-50% - clamp(131px, 42vw, 153px)));
			bottom: 98px;
		}

		@include media.small {
			height: 1020px;
			bottom: 0;
			transform: translateX(-50%) translateY(calc(-50%));
			top: calc(50% - 238px);
		}
	}

	svg.c-sprite-icon.c-sprite-icon--deco-multi-hex {
		--c-sprite-icon-height: var(, var(--space-l));
		--c-sprite-icon-width: 80vw;
		--c-sprite-icon-height: 50vh;
		transform: translateX(-50%);
		position: relative;
		left: calc(50% - 33px);
		width: calc(var(--menu-height) * 13.89);
		height: 100%;
		bottom: 0;
		opacity: 0.5;

		@include media.small {
			left: calc(50% - 37px);
			transform: translateX(-50%) translateY(83px);
		}
	}
}
body:has(.header-neo) {
	--menu-height: 111px;
	overflow-x: hidden;

	.menu {
		padding-top: calc(var(--space-m) * 1.5);
		top: 0;
		background-color: color-mix(
			in srgb,
			var(--color-seal-1) 80%,
			var(--color-mix-base, transparent)
		);
		height: var(--menu-height);

		&::after {
			position: absolute;
			width: 100vw;
			height: 2px;
			content: "";
			display: block;
			bottom: -2px;
			left: 0;
			background: var(--gradient-neo);
		}

		.cksource-logo {
			color: var(--color-tropical-7);
		}

		&-navigation__item a {
			color: var(--color-shark-1);

			&:after {
				// background-color: var(--color-tropical-7);
				transition: all 0.3s ease;
			}

			&:hover {
				color: var(--color-tang-6);
				&::after {
					// background-color: var(--color-comet-5);
				}
			}
		}
	}
	.menu-navigation__socials {
		transform: translateY(var(--space-xs));
	}
}

.header.header--careers,
.header.header--team {
	picture img {
		max-width: 100%;
	}
}

@include _breakpoint--md {
	.header.header--team {
		picture img {
			transform: translateY(-40%);
		}
	}
	.header.header--careers {
		picture img {
			transform: translateY(-24%);
		}
	}
}
