$tiny-info-line-width: 1px;
$tiny-info-line-height: 14px;
$tiny-info-line-background-color: #55606eb8;
$tiny-info-line-padding-left: 8px;
$tiny-info-line-padding-right: 6px;

.offer__list-item-tiny-info {
    font-size: _font-scale( normal );
    line-height: 1.2;
    
    .offer__list-item-bottom & {
        font-size: _font-scale( small );        
    }

    @include _breakpoint--md {
        font-size: _font-scale( medium );
    }
}

.offer__list-item-tiny-info + .offer__list-item-tiny-info {
    position: relative;
    padding-left: $tiny-info-line-padding-left;
    margin-left: $tiny-info-line-padding-right;
    
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: $tiny-info-line-width;
        height: $tiny-info-line-height;
        background-color: $tiny-info-line-background-color;
    }
}

.job__details {
    .btn {
        display: none;
        @include _breakpoint--md {
            display: block;
        }
    }

}
