$contest-box-inner-color: #fafafa;
$color-accent: var(--color-tang-5);
$background-accent: #fafafa;

.section-form {
	padding-top: _space();
	padding-bottom: _space();
	background-color: $background-accent;

	@include _breakpoint--sm {
		padding-top: _space() * 3;
		padding-bottom: _space() * 3;
	}
}

.contest-box {
	position: relative;
	background-color: #ffffff;
	padding: 10px;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
	@include _shadow();

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		background-color: $color-accent;
		opacity: 0.6;
	}

	& + .contest-box {
		margin-top: 32px;
	}

	.contest-box__inner {
		position: relative;
		padding: 16px;
		background-color: $contest-box-inner-color;

		@include _breakpoint--sm {
			padding: 32px;
		}
	}

	.contest__section {
		&:not(:first-of-type) {
			margin-top: 24px;

			@include _breakpoint--sm {
				margin-top: 48px;
			}
		}
	}

	.contest__text--center {
		text-align: center;
	}

	.contest__text--ma {
		margin: 0 auto;
		max-width: 500px;
	}

	.code {
		display: block;
		padding: 16px 48px;

		position: relative;
		background: #fbdb9a29;
		background: linear-gradient(-150deg, transparent 1.5em, #fbdb9a29 0);
		border-radius: 0.5em;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			background: linear-gradient(
					210deg,
					transparent 50%,
					#fbdb9a29 0,
					#fbdb9a29 100%
				)
				100% 0 no-repeat;
			width: 56px;
			height: 32px;
			transform: rotateX(180deg) rotateZ(120deg);
			border-bottom-left-radius: inherit;
			box-shadow: -0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.15%);
		}
	}

	pre {
		margin: 0;
	}

	.contest__separator {
		border-bottom: 1px solid #b4bcc2;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}

	.contest__separator--big {
		padding-bottom: 48px;

		@include _breakpoint--sm {
			padding-bottom: 30px;
		}
	}

	.contest__award-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.contest__award {
		position: relative;
		max-width: 100%;
		max-height: 180px;
		margin: 0;

		@include _breakpoint--sm {
			max-height: 360px;
		}
	}

	.contest__header {
		position: relative;
		font-size: _font-scale(large);
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 12px;
		text-align: center;

		@include _breakpoint--sm {
			font-size: _font-scale(xlarge);
		}

		&::before,
		&::after {
			display: none;
		}

		span {
			display: block;
			position: relative;
			z-index: 1;
			background-color: $contest-box-inner-color;

			@include _breakpoint--sm {
				padding-left: 16px;
				padding-right: 16px;
			}
		}
	}

	.contest__header--big {
		font-size: _font-scale(large) * 1.5;

		@include _breakpoint--sm {
			font-size: _font-scale(large) * 2;
		}
	}

	.contest__product {
		font-weight: bold;
	}

	.contest__tables {
		display: flex;
		flex-wrap: wrap;
		margin-left: -16px;
		margin-right: -16px;
		margin-top: 32px;
		justify-content: center;
	}

	.contest__table {
		max-width: 320px;
		height: 320px;
		width: 100%;
		margin-left: 16px;
		margin-right: 16px;

		.highlight {
			background-color: #b9bfc68a;
		}

		th {
			border: 1px solid #b9bfc6;
		}
	}

	p,
	a,
	li {
		font-size: _font-scale(medium);
	}
}

.contest__list {
	.contest__box {
		& + .contest__box {
			margin-top: 16px;
		}
	}

	.contest__title,
	.contest__person {
		margin: 0;
	}

	.contest__title {
		font-weight: bold;
	}

	.contest__person {
		display: inline-block;
	}
}

.drawing__list {
	list-style-type: none;
	margin-top: 32px;
	padding-left: 0;

	.drawing__item {
		display: flex;
		flex-direction: column;

		@include _breakpoint--sm {
			flex-direction: row-reverse;
			align-items: center;
			justify-content: center;
		}

		& + .drawing__item {
			margin-top: 48px;

			@include _breakpoint--sm {
				margin-top: 32px;
			}
		}
	}

	.drawing__text {
		font-size: _font-scale(normal);
		margin: 0;

		@include _breakpoint--sm {
			font-size: _font-scale(large);
			max-width: 300px;
			width: 100%;
		}
	}

	.drawing__image-box {
		position: relative;
		z-index: 1;
		margin-top: 16px;
		max-width: 300px;
		width: 100%;

		@include _breakpoint--sm {
			margin-top: initial;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background-image: image_url("hex-decorator-item.svg", "decorators");
			background-repeat: no-repeat;
			background-size: contain;
			width: 190px;
			height: 200px;
		}
	}

	.drawing__image {
		margin: 0;
		object-fit: contain;
		max-width: 100%;
		max-height: 160px;

		@include _breakpoint--sm {
			max-height: 200px;
		}

		&--shirts {
			object-position: 8px;
			margin-left: 22px;
			margin-top: 12px;

			@include _breakpoint--sm {
				margin: initial;
			}
		}
		&--headphones {
			margin-top: 24px;
			margin-left: -8px;

			@include _breakpoint--sm {
				object-position: -40px;
				margin: initial;
			}
		}
	}
}
