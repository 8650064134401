$job-disclaimer-height: 215px;
$job-meta-padding-mobile: 12px 12px 24px;
$job-meta-padding: 26px;
$job-meta-background-color: var(--color-seal-2);
$job-meta-border-radius: 4px;

.job__meta {
	position: sticky;
	top: 40px;

	.job__details {
		background-color: $job-meta-background-color;
		padding: $job-meta-padding;
		border-radius: $job-meta-border-radius;
		padding: $job-meta-padding-mobile;
		@include _breakpoint--sm {
			padding: $job-meta-padding;
		}
	}

	.job__meta-title {
		color: var(--color-shark-1);
		font-size: _font-scale(big);
		line-height: 1.2;
		margin: 0;

		@include _breakpoint--md {
			font-size: _font-scale(big);
		}
	}

	.job__meta-salary-wrapper {
		@include _breakpoint--sm {
			display: flex;
		}

		@include _breakpoint--md {
			display: block;
		}
	}

	.job__meta-salary-box {
		margin-top: 4px;

		@include _breakpoint--sm {
			margin-top: initial;
		}

		@include _breakpoint--md {
			margin-top: 4px;
		}

		& + .job__meta-salary-box {
			margin-top: 8px;

			@include _breakpoint--sm {
				margin-top: initial;
				margin-left: _double-space();
			}

			@include _breakpoint--md {
				margin-top: 8px;
				margin-left: initial;
			}
		}
	}

	.job__salary {
		margin-bottom: 1.5rem;
	}

	.job__meta-salary-type {
		font-size: _font-scale(medium);
		line-height: 1.6;
		margin-bottom: 0;

		@include _breakpoint--md {
			font-size: _font-scale(small);
		}
	}

	.job__meta-salary {
		color: var(--color-tang-5);
		font-size: _font-scale(normal);
		line-height: 1.2;
		font-weight: 700;
		margin-bottom: 0;
	}

	.job__meta-salary-form {
		color: var(--color-shark-2);
		font-size: 14px;
		line-height: 1.2;
		padding-left: 4px;
	}

	.job__skills {
		h4 {
			margin-bottom: 0.7rem;
		}

		h5 {
			margin: 0;
			font: {
				size: _font-scale(medium);
				weight: 400;
			}
		}

		&-bar {
			margin-bottom: _space() * 0.5;
			width: 100%;
			height: 10px;
			border-radius: 5px;
			background-color: var(--color-shark-1);
		}

		&-level {
			float: left;
			height: 100%;
			background-color: var(--color-tang-5);
			border-radius: 5px;
		}
	}
	.job__disclaimer {
		max-height: $job-disclaimer-height;
		font-size: _font-scale(tiny);
		overflow-y: auto;
		line-height: 1.6rem;
		font-style: italic;
		margin-top: 16px;

		@include _breakpoint--sm {
			margin-top: 24px;
		}
	}
}
