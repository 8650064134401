/**
 * Set transition with default values.
 *
 * @access public
 * @param {String} $property [ all ] - Property name.
 * @param {String} $duration [ 200ms ] - Transition duration time.
 * @param {String} $timing-function [ 200ms ] - Name of timing function.
 * @param {String} $delay [ null ] - Delay time.
 */
@mixin _transition( $property: all, $duration: 200ms, $timing-function: ease-in-out, $delay: null) {
	transition: unquote( $property ) $duration unquote( $timing-function );

	@if ( $delay != null ) {
		transition-delay: $delay;
	}
}