// Please keep it in sync with the media query breakpoint values map in JS.
// Source file: `themes/ckecosystem/src/js/common/media/index.js`

$media-xs: 28rem; // 448px
$media-sm: 48rem; // 768px
$media-md: 64rem; // 1024px
$media-xmd: 79rem; // 1264px
$media-lg: 88rem; // 1408px
$media-xlg: 108rem; // 1728px

@mixin breakpoint($mq: 0) {
	@media (width >= $mq) {
		@content;
	}
}

@mixin x-small {
	@include breakpoint($media-xs) {
		@content;
	}
}

@mixin small {
	@include breakpoint($media-sm) {
		@content;
	}
}

@mixin medium {
	@include breakpoint($media-md) {
		@content;
	}
}

@mixin main-nav {
	@include breakpoint($media-main-nav) {
		@content;
	}
}

@mixin extra-medium {
	@include breakpoint($media-xmd) {
		@content;
	}
}

@mixin large {
	@include breakpoint($media-lg) {
		@content;
	}
}

@mixin xlarge {
	@include breakpoint($media-xlg) {
		@content;
	}
}
